import React from 'react';

export const IconCalendar = ({ rootClassName }) => {
  return (
    <svg
      className={rootClassName}
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      fill="#FFFFFF"
    >
      <rect x="3" y="6.63086" width="18" height="14.9543" rx="2" stroke="#4871F7" fill="#FFFFFF" />
      <path d="M3 11.6162L21 11.6162" stroke="#4871F7" stroke-linecap="round" fill="#FFFFFF" />
      <path d="M9 16.6006H15" stroke="#4871F7" stroke-linecap="round" fill="#FFFFFF" />
      <path d="M8 3.64062L8 7.62843" stroke="#4871F7" stroke-linecap="round" fill="#FFFFFF" />
      <path d="M16 3.64062L16 7.62843" stroke="#4871F7" stroke-linecap="round" fill="#FFFFFF" />
    </svg>
  );
};
