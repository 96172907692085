import React from 'react';

const IconTransportationAvailable = ({width, height}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 48 39" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill='none' d="M28.4 8.5625H33.542C34.0801 8.5625 34.3491 8.5625 34.6023 8.62294C34.8268 8.67653 35.0414 8.76491 35.2382 8.88485C35.4602 9.02013 35.6505 9.20929 36.031 9.58763L44.969 18.4749C45.3495 18.8532 45.5398 19.0424 45.6758 19.2631C45.7964 19.4588 45.8853 19.6722 45.9392 19.8954C46 20.1472 46 20.4147 46 20.9497V27.1562C46 28.1755 46 28.6851 45.8325 29.0871C45.6092 29.6231 45.181 30.049 44.6419 30.271C44.2376 30.4375 43.7251 30.4375 42.7 30.4375M31.7 30.4375H28.4M28.4 30.4375V9C28.4 6.54977 28.4 5.32466 27.9204 4.38879C27.4986 3.56558 26.8255 2.89629 25.9976 2.47685C25.0563 2 23.8242 2 21.36 2H9.04C6.57577 2 5.34365 2 4.40244 2.47685C3.57453 2.89629 2.90141 3.56558 2.47957 4.38879C2 5.32466 2 6.54977 2 9V26.0625C2 28.4787 3.96995 30.4375 6.4 30.4375M28.4 30.4375H19.6M19.6 30.4375C19.6 34.0619 16.6451 37 13 37C9.35492 37 6.4 34.0619 6.4 30.4375M19.6 30.4375C19.6 26.8131 16.6451 23.875 13 23.875C9.35492 23.875 6.4 26.8131 6.4 30.4375M42.7 31.5312C42.7 34.5516 40.2376 37 37.2 37C34.1624 37 31.7 34.5516 31.7 31.5312C31.7 28.5109 34.1624 26.0625 37.2 26.0625C40.2376 26.0625 42.7 28.5109 42.7 31.5312Z" stroke="#4871F7" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default IconTransportationAvailable;
