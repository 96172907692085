import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { storableError } from '../../util/errors';
import { getSubscriptionPlan } from '../../util/api';
import { types as sdkTypes } from '../../util/sdkLoader';

const { UUID } = sdkTypes;

export const SET_INITIAL_STATE = 'app/PartnerProfileContactUsPage/SET_INITIAL_STATE';

export const SHOW_USER_REQUEST = 'app/PartnerProfileContactUsPage/SHOW_USER_REQUEST';
export const SHOW_USER_SUCCESS = 'app/PartnerProfileContactUsPage/SHOW_USER_SUCCESS';
export const SHOW_USER_ERROR = 'app/PartnerProfileContactUsPage/SHOW_USER_ERROR';

export const QUERY_SUBSCRIPTION_PLAN_REQUEST = 'app/PartnerProfileContactUsPage/QUERY_SUBSCRIPTION_PLAN_REQUEST';
export const QUERY_SUBSCRIPTION_PLAN_SUCCESS = 'app/PartnerProfileContactUsPage/QUERY_SUBSCRIPTION_PLAN_SUCCESS';
export const QUERY_SUBSCRIPTION_PLAN_ERROR = 'app/PartnerProfileContactUsPage/QUERY_SUBSCRIPTION_PLAN_ERROR';


const initialState = {
  userId: null,
  userShowError: null,
  queryListingsError: null,
  subscriptionPlan: null,
  subscriptionPlanError: null,
};

export default function profileContactUsPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_STATE:
      return { ...initialState };
    case SHOW_USER_REQUEST:
      return { ...state, userShowError: null, userId: payload.userId };
    case SHOW_USER_SUCCESS:
      return state;
    case SHOW_USER_ERROR:
      return { ...state, userShowError: payload };

    case QUERY_SUBSCRIPTION_PLAN_REQUEST:
      return { ...state, subscriptionPlanError: null };
    case QUERY_SUBSCRIPTION_PLAN_SUCCESS:
      return { ...state, subscriptionPlan: payload };
    case QUERY_SUBSCRIPTION_PLAN_ERROR:
      return { ...state, subscriptionPlanError: payload };

    default:
      return state;
  }
}

export const setInitialState = () => ({
  type: SET_INITIAL_STATE,
});

export const showUserRequest = userId => ({
  type: SHOW_USER_REQUEST,
  payload: { userId },
});

export const showUserSuccess = () => ({
  type: SHOW_USER_SUCCESS,
});

export const showUserError = e => ({
  type: SHOW_USER_ERROR,
  error: true,
  payload: e,
});

const querySubscriptionPlanRequest = () => ({
  type: QUERY_SUBSCRIPTION_PLAN_REQUEST,
});
const querySubscriptionPlanSuccess = payload => ({
  type: QUERY_SUBSCRIPTION_PLAN_SUCCESS,
  payload,
});
const querySubscriptionPlanError = e => ({
  type: QUERY_SUBSCRIPTION_PLAN_ERROR,
  error: true,
  payload: e,
});


export const showUser = userId => (dispatch, getState, sdk) => {
  dispatch(showUserRequest(userId));
  return sdk.users
    .show({
      id: userId,
      include: ['profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(showUserSuccess());
      return response;
    })
    .catch(e => dispatch(showUserError(storableError(e))));
};

export const getUserSubscriptionPlan = (userId) => async (dispatch) => {
  dispatch(querySubscriptionPlanRequest());

  try {
    const response = await getSubscriptionPlan({ userId: userId.uuid });

    const SubscriptionPlan =
      response?.data?.data?.attributes?.profile?.privateData?.Subscription_plan ?? "None";

    dispatch(querySubscriptionPlanSuccess(SubscriptionPlan));
  } catch( e) {
    dispatch(querySubscriptionPlanError(storableError(e)))
  }
};

export const loadData = (params) => (dispatch) => {
  const userId = new UUID(params.id);

  // Clear state so that previously loaded data is not visible
  // in case this page load fails.
  dispatch(setInitialState());

  return Promise.all([
    dispatch(fetchCurrentUser()),
    dispatch(showUser(userId)),
    dispatch(getUserSubscriptionPlan(userId)),
  ]);
};
