import React from 'react';

export const IconRequirementsList = ({ rootClassName }) => {
  return (
    <svg
      className={rootClassName}
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M9 7.49707L13 7.49707" stroke="#4871F7" stroke-linecap="round" />
      <path d="M9 15.4727L12 15.4727" stroke="#4871F7" stroke-linecap="round" />
      <path d="M9 11.4854L15 11.4854" stroke="#4871F7" stroke-linecap="round" />
      <path
        d="M19 11.4846V9.50898C19 6.68055 19 5.26634 18.1213 4.38766C17.2426 3.50898 15.8284 3.50898 13 3.50898H11C8.17157 3.50898 6.75736 3.50898 5.87868 4.38766C5 5.26634 5 6.68055 5 9.50898V15.4541C5 18.2825 5 19.6967 5.87868 20.5754C6.75736 21.4541 8.17157 21.4541 11 21.4541H12"
        stroke="#4871F7"
        stroke-linecap="round"
        fill="none"
      />
      <ellipse
        cx="17.5"
        cy="17.965"
        rx="2.5"
        ry="2.49238"
        stroke="#4871F7"
        stroke-linecap="round"
        fill="#FFFFFF"
      />
      <path d="M21 21.4544L19.5 19.959" stroke="#4871F7" stroke-linecap="round" />
    </svg>
  );
};
