import React from 'react';

import premier from '../../assets/PremierPartner.png';
import business from '../../assets/BusinessPartner.png';
import platinum from '../../assets/PlatinumPartner.png';

import {
  SUBSCRIPTION_PLAN_PREMIUM,
  SUBSCRIPTION_PLAN_PREMIER,
  SUBSCRIPTION_PLAN_BUSINESS,
  SUBSCRIPTION_PLAN_PLATINUM,
} from '../../constants';

import css from './SubscriptionBudges.module.css';

const SubscriptionBudges = ({subscription}) => {

  if (
      !subscription ||
      ![
        SUBSCRIPTION_PLAN_PREMIUM,
        SUBSCRIPTION_PLAN_PREMIER,
        SUBSCRIPTION_PLAN_BUSINESS,
        SUBSCRIPTION_PLAN_PLATINUM
      ].includes(subscription)
    ) {
    return null;
  }

  let img = null;

  switch(subscription){
    case SUBSCRIPTION_PLAN_PREMIER:
      img = premier
      break;

    case SUBSCRIPTION_PLAN_BUSINESS:
      img = business
      break;

    case SUBSCRIPTION_PLAN_PLATINUM:
    case SUBSCRIPTION_PLAN_PREMIUM:
      img = platinum
      break;
  }

  return (
    <div className={css.root}>
      <img src={img} alt="subscription badge" className={css.img} />
    </div>
  )
}

export default SubscriptionBudges;
