import React from 'react';
import loadable from '@loadable/component';

import getPageDataLoadingAPI from '../containers/pageDataLoadingAPI';
import NotFoundPage from '../containers/NotFoundPage/NotFoundPage';
import PreviewResolverPage from '../containers/PreviewResolverPage/PreviewResolverPage';

// routeConfiguration needs to initialize containers first
// Otherwise, components will import form container eventually and
// at that point css bundling / imports will happen in wrong order.
import { NamedRedirect } from '../components';

const pageDataLoadingAPI = getPageDataLoadingAPI();

const AboutUs = loadable(() => import(/* webpackChunkName: "SocialMediaPage" */ '../containers/AboutUs/AboutUs'));
const AboutPage = loadable(() => import(/* webpackChunkName: "SocialMediaPage" */ '../containers/AboutPage/AboutPage'));
const PartnerPortal = loadable(() => import(/* webpackChunkName: "SocialMediaPage" */ '../containers/PartnerPortal/PartnerPortal'));
const AuthenticationPage = loadable(() => import(/* webpackChunkName: "AuthenticationPage" */ '../containers/AuthenticationPage/AuthenticationPage'));
const CheckoutPage = loadable(() => import(/* webpackChunkName: "CheckoutPage" */ '../containers/CheckoutPage/CheckoutPage'));
const CMSPage = loadable(() => import(/* webpackChunkName: "CMSPage" */ '../containers/CMSPage/CMSPage'));
const ContactDetailsPage = loadable(() => import(/* webpackChunkName: "ContactDetailsPage" */ '../containers/ContactDetailsPage/ContactDetailsPage'));
const PartnerProfileAboutUsPage = loadable(() => import(/* webpackChunkName: "PartnerProfileAboutUsPage" */ '../containers/PartnerProfileAboutUsPage/PartnerProfileAboutUsPage'));
// const PartnerProfileOurListingsPage = loadable(() => import(/* webpackChunkName: "PartnerProfileOurListingsPage" */ '../containers/PartnerProfileOurListingsPage/PartnerProfileOurListingsPage'));
const PartnerProfileContactUsPage = loadable(() => import(/* webpackChunkName: "PartnerProfileContactUsPage" */ '../containers/PartnerProfileContactUsPage/PartnerProfileContactUsPage'));
const TermsServicePage = loadable(() => import(/* webpackChunkName: "TermsServicePage" */ '../containers/PrivacyPolicies/TermsServicePage/TermsServicePage'));
const HireTermsPage = loadable(() => import(/* webpackChunkName: "HireTermsPage" */ '../containers/PrivacyPolicies/HireTerms/HireTermsPage'));
const SubscriptionsTermsPage = loadable(() => import(/* webpackChunkName: "SubscriptionsTermsPage" */ '../containers/PrivacyPolicies/SubscriptionsTermsPage/SubscriptionsTermsPage'));
const PoliciesPage = loadable(() => import(/* webpackChunkName: "PoliciesPage" */ '../containers/PrivacyPolicies/PoliciesPage/PoliciesPage'));
const PrivacyPoliciesPage = loadable(() => import(/* webpackChunkName: "PrivacyPoliciesPage" */ '../containers/PrivacyPolicies/PrivacyPoliciesPage/PrivacyPoliciesPage'));
const EquipmentCriteriaPage = loadable(() => import(/* webpackChunkName: "EquipmentCriteriaPage" */ '../containers/PrivacyPolicies/EquipmentCriteriaPage/EquipmentCriteriaPage'));
const InsuranceFinancePage = loadable(() => import(/* webpackChunkName: "InsuranceFinancePage" */ '../containers/PrivacyPolicies/InsuranceFinancePage/InsuranceFinancePage'));
const PremiumPartnersPage = loadable(() => import(/* webpackChunkName: "PremiumPartnersPage" */ '../containers/PrivacyPolicies/PremiumPartnersPage/PremiumPartnersPage'));
const EditListingPage = loadable(() => import(/* webpackChunkName: "EditListingPage" */ '../containers/EditListingPage/EditListingPage'));
const EmailVerificationPage = loadable(() => import(/* webpackChunkName: "EmailVerificationPage" */ '../containers/EmailVerificationPage/EmailVerificationPage'));
const InboxPage = loadable(() => import(/* webpackChunkName: "InboxPage" */ '../containers/InboxPage/InboxPage'));
const LandingPage = loadable(() => import(/* webpackChunkName: "LandingPage" */ '../containers/LandingPage/LandingPage'));
const ListingPageCoverPhoto = loadable(() => import(/* webpackChunkName: "ListingPageCoverPhoto" */ /* webpackPrefetch: true */ '../containers/ListingPage/ListingPageCoverPhoto'));
const ListingPageCarousel = loadable(() => import(/* webpackChunkName: "ListingPageCarousel" */ /* webpackPrefetch: true */ '../containers/ListingPage/ListingPageCarousel'));
const ManageListingsPage = loadable(() => import(/* webpackChunkName: "ManageListingsPage" */ '../containers/ManageListingsPage/ManageListingsPage'));
const PasswordChangePage = loadable(() => import(/* webpackChunkName: "PasswordChangePage" */ '../containers/PasswordChangePage/PasswordChangePage'));
const PasswordRecoveryPage = loadable(() => import(/* webpackChunkName: "PasswordRecoveryPage" */ '../containers/PasswordRecoveryPage/PasswordRecoveryPage'));
const PasswordResetPage = loadable(() => import(/* webpackChunkName: "PasswordResetPage" */ '../containers/PasswordResetPage/PasswordResetPage'));
const PaymentMethodsPage = loadable(() => import(/* webpackChunkName: "PaymentMethodsPage" */ '../containers/PaymentMethodsPage/PaymentMethodsPage'));
const PrivacyPolicyPage = loadable(() => import(/* webpackChunkName: "PrivacyPolicyPage" */ '../containers/PrivacyPolicyPage/PrivacyPolicyPage'));
const ProfilePage = loadable(() => import(/* webpackChunkName: "ProfilePage" */ '../containers/ProfilePage/ProfilePage'));
const ProfileSettingsPage = loadable(() => import(/* webpackChunkName: "ProfileSettingsPage" */ '../containers/ProfileSettingsPage/ProfileSettingsPage'));
const SearchPageWithMap = loadable(() => import(/* webpackChunkName: "SearchPageWithMap" */ /* webpackPrefetch: true */  '../containers/SearchPage/SearchPageWithMap'));
const SearchPageWithGrid = loadable(() => import(/* webpackChunkName: "SearchPageWithGrid" */ /* webpackPrefetch: true */  '../containers/SearchPage/SearchPageWithGrid'));
const StripePayoutPage = loadable(() => import(/* webpackChunkName: "StripePayoutPage" */ '../containers/StripePayoutPage/StripePayoutPage'));
const TermsOfServicePage = loadable(() => import(/* webpackChunkName: "TermsOfServicePage" */ '../containers/TermsOfServicePage/TermsOfServicePage'));
const TransactionPage = loadable(() => import(/* webpackChunkName: "TransactionPage" */ '../containers/TransactionPage/TransactionPage'));
const IDVerificationPage = loadable(() => import(/* webpackChunkName: "IDVerificationPage" */ '../containers/IDVerificationPage/IDVerificationPage'));
const BrandDetailsPage = loadable(() => import(/* webpackChunkName: "BrandDetailsPage" */ '../containers/BrandDetailsPage/BrandDetailsPage'));
const DepotsPage = loadable(() => import(/* webpackChunkName: "DepotsPage" */ '../containers/DepotsPage/DepotsPage'));
const CoveragePage = loadable(() => import(/* webpackChunkName: "CoveragePage" */ '../containers/CoveragePage/CoveragePage'));
const InvoicingPage = loadable(() => import(/* webpackChunkName: "InvoicingPage" */ '../containers/InvoicingPage/InvoicingPage'));
const SalesOverviewPage = loadable(() => import(/* webpackChunkName: "SalesOverviewPage" */ '../containers/SalesOverviewPage/SalesOverviewPage'));
const DocumentsPage = loadable(() => import(/* webpackChunkName: "DocumentsPage" */ '../containers/DocumentsPage/DocumentsPage'));
const SubscriptionPage = loadable(() => import(/* webpackChunkName: "SubscriptionPage" */ '../containers/SubscriptionPage/SubscriptionPage'));
const CompanyOverviewPage = loadable(() => import(/* webpackChunkName: "CompanyOverviewPage" */ '../containers/CompanyOverviewPage/CompanyOverviewPage'));
const ProfileOverviewPage = loadable(() => import(/* webpackChunkName: "ProfileOverviewPage" */ '../containers/ProfileOverviewPage/ProfileOverviewPage'));
// const ListingsPage = loadable(() => import(/* webpackChunkName: "ListingsPage" */ '../containers/ListingsPage/ListingsPage'));
const BusinessDetailsPage = loadable(() => import(/* webpackChunkName: "BusinessDetailsPage" */ '../containers/BusinessDetailsPage/BusinessDetailsPage'));
const TendersPage = loadable(() => import(/* webpackChunkName: "TendersPage" */ '../containers/TendersPage/TendersPage'));
const TenderPage = loadable(() => import(/* webpackChunkName: "TenderPage" */ '../containers/TenderPage/TenderPage'));
const TenderDetailsPage = loadable(() => import(/* webpackChunkName: "TenderDetailsPage" */ '../containers/TenderDetailsPage/TenderDetailsPage'));
const DashboardAnalyticsPage = loadable(() => import(/* webpackChunkName: "DashboardAnalyticsPage" */ '../containers/DashboardAnalyticsPage/DashboardAnalyticsPage'));
const DashboardPerformanceMetricsPage = loadable(() => import(/* webpackChunkName: "DashboardPerformanceMetricsPage" */ '../containers/DashboardPerformanceMetricsPage/DashboardPerformanceMetricsPage'));
const DashboardMapventoryPage = loadable(() => import(/* webpackChunkName: "DashboardMapventoryPage" */ '../containers/DashboardMapventoryPage/DashboardMapventoryPage'));
const ListingsEditPage = loadable(() => import(/* webpackChunkName: "ListingsEditPage" */ '../containers/ListingsEditPage/ListingsEditPage'));

// Styleguide helps you to review current components and develop new ones
const StyleguidePage = loadable(() => import(/* webpackChunkName: "StyleguidePage" */ '../containers/StyleguidePage/StyleguidePage'));

export const ACCOUNT_SETTINGS_PAGES = [
  'ContactDetailsPage',
  'PasswordChangePage',
  'StripePayoutPage',
  'PaymentMethodsPage',
  'IDVerificationPage',
  'ProfileOverviewPage',
  // 'ListingsPage',
  'BusinessDetailsPage',
  'CompanyOverviewPage',
  'SubscriptionPage',
  'DocumentsPage',
  'SalesOverviewPage',
  'InvoicingPage',
  'CoveragePage',
  'DepotsPage',
  'BrandDetailsPage',
];

export const PARTNER_PROFILE_PAGES = [
  'PartnerProfileAboutUsPage',
  'ProfilePage',
  'PartnerProfileContactUsPage',
];

export const DASHBOARD_PAGES = [
  'DashboardAnalyticsPage',
  'DashboardPerformanceMetricsPage',
  'DashboardMapventoryPage',
];

export const PRIVACY_POLICY_PAGES = [
  'TermsServicePage',
  'HireTermsPage',
  'SubscriptionsTermsPage',
  'PoliciesPage',
  'PrivacyPoliciesPage',
  'EquipmentCriteriaPage',
  'PremiumPartnersPage',
  'InsuranceFinancePage',
];

// https://en.wikipedia.org/wiki/Universally_unique_identifier#Nil_UUID
const draftId = '00000000-0000-0000-0000-000000000000';
const draftSlug = 'draft';

const RedirectToLandingPage = () => <NamedRedirect name="LandingPage" />;

// NOTE: Most server-side endpoints are prefixed with /api. Requests to those
// endpoints are indended to be handled in the server instead of the browser and
// they will not render the application. So remember to avoid routes starting
// with /api and if you encounter clashing routes see server/index.js if there's
// a conflicting route defined there.

// Our routes are exact by default.
// See behaviour from Routes.js where Route is created.
const routeConfiguration = (layoutConfig) => {
  const SearchPage = layoutConfig?.searchPage?.variantType === 'map'
    ? SearchPageWithMap
    : SearchPageWithGrid;
  const ListingPage = layoutConfig?.listingPage?.variantType === 'carousel'
    ? ListingPageCarousel
    : ListingPageCoverPhoto;

  return [
    {
      path: '/about-us',
      name: 'AboutUs',
      component: AboutPage,
    },
    {
      path: '/partner-portal',
      name: 'PartnerPortal',
      component: PartnerPortal,
      loadData: pageDataLoadingAPI.PartnerPortal.loadData,
    },
    {
      path: '/',
      name: 'LandingPage',
      component: LandingPage,
      // loadData: pageDataLoadingAPI.LandingPage.loadData,
    },
    {
      path: '/p/:pageId',
      name: 'CMSPage',
      component: CMSPage,
      loadData: pageDataLoadingAPI.CMSPage.loadData,
    },
    {
      path: '/s',
      name: 'SearchPage',
      component: SearchPage,
      loadData: pageDataLoadingAPI.SearchPage.loadData,
    },
    {
      path: '/l',
      name: 'ListingBasePage',
      component: RedirectToLandingPage,
    },
    {
      path: '/l/:slug/:id',
      name: 'ListingPage',
      component: ListingPage,
      loadData: pageDataLoadingAPI.ListingPage.loadData,
    },
    {
      path: '/l/:slug/:id/checkout',
      name: 'CheckoutPage',
      auth: true,
      component: CheckoutPage,
      setInitialValues: pageDataLoadingAPI.CheckoutPage.setInitialValues,
    },
    {
      path: '/l/:slug/:id/:variant',
      name: 'ListingPageVariant',
      auth: true,
      authPage: 'LoginPage',
      component: ListingPage,
      loadData: pageDataLoadingAPI.ListingPage.loadData,
    },
    {
      path: '/l/new',
      name: 'NewListingPage',
      auth: true,
      component: () => (
        <NamedRedirect
          name="EditListingPage"
          params={{ slug: draftSlug, id: draftId, type: 'new', tab: 'details' }}
        />
      ),
    },
    {
      path: '/l/:slug/:id/:type/:tab',
      name: 'EditListingPage',
      auth: true,
      component: EditListingPage,
      loadData: pageDataLoadingAPI.EditListingPage.loadData,
    },
    {
      path: '/l/:slug/:id/:type/:tab/:returnURLType',
      name: 'EditListingStripeOnboardingPage',
      auth: true,
      component: EditListingPage,
      loadData: pageDataLoadingAPI.EditListingPage.loadData,
    },

    // Canonical path should be after the `/l/new` path since they
    // conflict and `new` is not a valid listing UUID.
    {
      path: '/l/:id',
      name: 'ListingPageCanonical',
      component: ListingPage,
      loadData: pageDataLoadingAPI.ListingPage.loadData,
    },
    {
      path: '/u',
      name: 'ProfileBasePage',
      component: RedirectToLandingPage,
    },
    {
      path: '/u/:id',
      name: 'ProfilePage',
      component: ProfilePage,
      loadData: pageDataLoadingAPI.ProfilePage.loadData,
    },
    {
      path: '/profile-settings',
      name: 'ProfileSettingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: ProfileSettingsPage,
    },

    // Note: authenticating with IdP (e.g. Facebook) expects that /login path exists
    // so that in the error case users can be redirected back to the LoginPage
    // In case you change this, remember to update the route in server/api/auth/loginWithIdp.js
    {
      path: '/login',
      name: 'LoginPage',
      component: AuthenticationPage,
      extraProps: { tab: 'login' },
    },
    {
      path: '/signup',
      name: 'SignupPage',
      component: AuthenticationPage,
      extraProps: { tab: 'signup' },
      loadData: pageDataLoadingAPI.AuthenticationPage.loadData,
    },
    {
      path: '/confirm',
      name: 'ConfirmPage',
      component: AuthenticationPage,
      extraProps: { tab: 'confirm' },
    },
    {
      path: '/recover-password',
      name: 'PasswordRecoveryPage',
      component: PasswordRecoveryPage,
    },
    {
      path: '/inbox',
      name: 'InboxBasePage',
      auth: true,
      authPage: 'LoginPage',
      component: () => <NamedRedirect name="InboxPage" params={{ tab: 'sales' }} />,
    },
    {
      path: '/inbox/:tab',
      name: 'InboxPage',
      auth: true,
      authPage: 'LoginPage',
      component: InboxPage,
      loadData: pageDataLoadingAPI.InboxPage.loadData,
    },
    {
      path: '/inbox/tender/:tab',
      name: 'InboxTenderPage',
      auth: true,
      authPage: 'LoginPage',
      component: InboxPage,
      loadData: pageDataLoadingAPI.InboxTenderPage.loadData,
    },
    {
      path: '/order/:id',
      name: 'OrderDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: TransactionPage,
      extraProps: { transactionRole: 'customer' },
      loadData: (params, ...rest) =>
        pageDataLoadingAPI.TransactionPage.loadData({ ...params, transactionRole: 'customer' }, ...rest),
      setInitialValues: pageDataLoadingAPI.TransactionPage.setInitialValues,
    },
    {
      path: '/order/:id/details',
      name: 'OrderDetailsPageRedirect',
      auth: true,
      authPage: 'LoginPage',
      component: props => <NamedRedirect name="OrderDetailsPage" params={{ id: props.params?.id }} />,
    },
    {
      path: '/sale/:id',
      name: 'SaleDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: TransactionPage,
      extraProps: { transactionRole: 'provider' },
      loadData: pageDataLoadingAPI.TransactionPage.loadData,
    },
    {
      path: '/sale/:id/details',
      name: 'SaleDetailsPageRedirect',
      auth: true,
      authPage: 'LoginPage',
      component: props => <NamedRedirect name="SaleDetailsPage" params={{ id: props.params?.id }} />,
    },
    {
      path: '/listings',
      name: 'ManageListingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: ManageListingsPage,
      loadData: pageDataLoadingAPI.ManageListingsPage.loadData,
    },
    {
      path: '/listings/edit',
      name: 'ListingsEditPage',
      auth: true,
      authPage: 'LoginPage',
      component: ListingsEditPage,
      loadData: pageDataLoadingAPI.ListingsEditPage.loadData,
    },
    {
      path: '/privacy-policies',
      name: 'PrivacyPoliciesPage',
      component: PrivacyPoliciesPage,
    },
    {
      path: '/privacy-policies/terms-of-service',
      name: 'TermsServicePage',
      component: TermsServicePage,
    },
    {
      path: '/privacy-policies/hire-terms',
      name: 'HireTermsPage',
      component: HireTermsPage,
    },
    {
      path: '/privacy-policies/subscriptions-terms',
      name: 'SubscriptionsTermsPage',
      component: SubscriptionsTermsPage,
    },
    {
      path: '/privacy-policies/policies',
      name: 'PoliciesPage',
      component: PoliciesPage,
    },
    {
      path: '/privacy-policies/equipment-criteria',
      name: 'EquipmentCriteriaPage',
      component: EquipmentCriteriaPage,
    },
    {
      path: '/privacy-policies/premium-partners',
      name: 'PremiumPartnersPage',
      component: PremiumPartnersPage,
    },
    {
      path: '/privacy-policies/insurance-finance',
      name: 'InsuranceFinancePage',
      component: InsuranceFinancePage,
    },
    {
      path: '/u/:id/partner/about-us',
      name: 'PartnerProfileAboutUsPage',
      component: PartnerProfileAboutUsPage,
      loadData: pageDataLoadingAPI.PartnerProfileAboutUsPage.loadData,
    },
    {
      path: '/u/:id/partner/contact-us',
      name: 'PartnerProfileContactUsPage',
      component: PartnerProfileContactUsPage,
      loadData: pageDataLoadingAPI.PartnerProfileContactUsPage.loadData,
    },
    {
      path: '/account',
      name: 'AccountSettingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: () => <NamedRedirect name="ContactDetailsPage" />,
    },
    {
      path: '/account/contact-details',
      name: 'ContactDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: ContactDetailsPage,
      loadData: pageDataLoadingAPI.ContactDetailsPage.loadData,
    },
    {
      path: '/account/change-password',
      name: 'PasswordChangePage',
      auth: true,
      authPage: 'LoginPage',
      component: PasswordChangePage,
    },
    {
      path: '/account/brand-details',
      name: 'BrandDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: BrandDetailsPage,
    },
    {
      path: '/account/depots',
      name: 'DepotsPage',
      auth: true,
      authPage: 'LoginPage',
      component: DepotsPage,
    },
    {
      path: '/account/coverage',
      name: 'CoveragePage',
      auth: true,
      authPage: 'LoginPage',
      component: CoveragePage,
    },
    {
      path: '/account/invoicing',
      name: 'InvoicingPage',
      auth: true,
      authPage: 'LoginPage',
      component: InvoicingPage,
    },
    {
      path: '/account/sales-overview',
      name: 'SalesOverviewPage',
      auth: true,
      authPage: 'LoginPage',
      component: SalesOverviewPage,
    },
    {
      path: '/account/documents',
      name: 'DocumentsPage',
      auth: true,
      authPage: 'LoginPage',
      component: DocumentsPage,
    },
    {
      path: '/account/subscription',
      name: 'SubscriptionPage',
      auth: true,
      authPage: 'LoginPage',
      component: SubscriptionPage,
    },
    {
      path: '/account/company-overview',
      name: 'CompanyOverviewPage',
      auth: true,
      authPage: 'LoginPage',
      component: CompanyOverviewPage,
    },
    {
      path: '/account/profile-overview',
      name: 'ProfileOverviewPage',
      auth: true,
      authPage: 'LoginPage',
      component: ProfileOverviewPage,
    },
    // {
    //   path: '/account/listings',
    //   name: 'ListingsPage',
    //   auth: true,
    //   authPage: 'LoginPage',
    //   component: ListingsPage,
    // },
    {
      path: '/account/business-details',
      name: 'BusinessDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: BusinessDetailsPage,
    },
    {
      path: '/account/payments',
      name: 'StripePayoutPage',
      auth: true,
      authPage: 'LoginPage',
      component: StripePayoutPage,
      loadData: pageDataLoadingAPI.StripePayoutPage.loadData,
    },
    {
      path: '/account/payments/:returnURLType',
      name: 'StripePayoutOnboardingPage',
      auth: true,
      authPage: 'LoginPage',
      component: StripePayoutPage,
      loadData: pageDataLoadingAPI.StripePayoutPage.loadData,
    },
    {
      path: '/account/payment-methods',
      name: 'PaymentMethodsPage',
      auth: true,
      authPage: 'LoginPage',
      component: PaymentMethodsPage,
      loadData: pageDataLoadingAPI.PaymentMethodsPage.loadData,
    },
    {
      path: '/account/id-verification',
      name: 'IDVerificationPage',
      auth: true,
      authPage: 'LoginPage',
      component: IDVerificationPage,
    },
    {
      path: '/dashboard',
      name: 'DashboardPage',
      auth: true,
      authPage: 'LoginPage',
      component: () => <NamedRedirect name="DashboardAnalyticsPage" />,
    },
    {
      path: '/dashboard/analytics',
      name: 'DashboardAnalyticsPage',
      auth: true,
      authPage: 'LoginPage',
      component: DashboardAnalyticsPage,
    },
    {
      path: '/dashboard/performance-metrics',
      name: 'DashboardPerformanceMetricsPage',
      auth: true,
      authPage: 'LoginPage',
      component: DashboardPerformanceMetricsPage,
    },
    {
      path: '/dashboard/mapventory',
      name: 'DashboardMapventoryPage',
      auth: true,
      authPage: 'LoginPage',
      component: DashboardMapventoryPage,
    },
    {
      path: '/tenders',
      name: 'TendersPage',
      auth: false,
      component: TendersPage,
      loadData: (params, ...rest) =>
        pageDataLoadingAPI.TendersPage.loadData({ ...params }, ...rest),
    },
    {
      path: '/tender/:id',
      name: 'TenderPage',
      auth: false,
      component: TenderPage,
      loadData: (params, ...rest) =>
        pageDataLoadingAPI.TenderPage.loadData({ ...params }, ...rest),
    },
    {
      path: '/tender/:id/sale',
      name: 'TenderSaleDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: TenderDetailsPage,
      extraProps: { transactionRole: 'provider' },
      loadData: (params, ...rest) =>
        pageDataLoadingAPI.TenderDetailsPage.loadData({ ...params }, ...rest),
    },
    {
      path: '/tender/:id/order',
      name: 'TenderOrderDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: TenderDetailsPage,
      extraProps: { transactionRole: 'customer' },
      loadData: (params, ...rest) =>
        pageDataLoadingAPI.TenderDetailsPage.loadData({ ...params }, ...rest),
    },
    {
      path: '/terms-of-service',
      name: 'TermsOfServicePage',
      component: TermsOfServicePage,
      loadData: pageDataLoadingAPI.TermsOfServicePage.loadData,
    },
    {
      path: '/privacy-policy',
      name: 'PrivacyPolicyPage',
      component: PrivacyPolicyPage,
      loadData: pageDataLoadingAPI.PrivacyPolicyPage.loadData,
    },
    {
      path: '/styleguide',
      name: 'Styleguide',
      component: StyleguidePage,
    },
    {
      path: '/styleguide/g/:group',
      name: 'StyleguideGroup',
      component: StyleguidePage,
    },
    {
      path: '/styleguide/c/:component',
      name: 'StyleguideComponent',
      component: StyleguidePage,
    },
    {
      path: '/styleguide/c/:component/:example',
      name: 'StyleguideComponentExample',
      component: StyleguidePage,
    },
    {
      path: '/styleguide/c/:component/:example/raw',
      name: 'StyleguideComponentExampleRaw',
      component: StyleguidePage,
      extraProps: { raw: true },
    },
    {
      path: '/notfound',
      name: 'NotFoundPage',
      component: props => <NotFoundPage {...props} />,
    },

    // Do not change this path!
    //
    // The API expects that the application implements /reset-password endpoint
    {
      path: '/reset-password',
      name: 'PasswordResetPage',
      component: PasswordResetPage ,
    },

    // Do not change this path!
    //
    // The API expects that the application implements /verify-email endpoint
    {
      path: '/verify-email',
      name: 'EmailVerificationPage',
      auth: true,
      authPage: 'LoginPage',
      component: EmailVerificationPage,
      loadData: pageDataLoadingAPI.EmailVerificationPage.loadData,
    },
    // Do not change this path!
    //
    // The API expects that the application implements /preview endpoint
    {
      path: '/preview',
      name: 'PreviewResolverPage',
      component: PreviewResolverPage ,
    },
  ];
};

export default routeConfiguration;
