import React from 'react';

const IconBreakdownAssist = ({width, height}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 48 43" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill="none" d="M8.6 2L2 8.5M46 8.5L39.4 2M10.8 36.6667L6.4 41M37.2 36.6667L41.6 41M17.4 24.75L21.8 29.0833L31.7 19.3333M24 41C28.6678 41 33.1444 39.1738 36.4451 35.9232C39.7457 32.6726 41.6 28.2638 41.6 23.6667C41.6 19.0696 39.7457 14.6608 36.4451 11.4101C33.1444 8.15952 28.6678 6.33333 24 6.33333C19.3322 6.33333 14.8556 8.15952 11.5549 11.4101C8.25428 14.6608 6.4 19.0696 6.4 23.6667C6.4 28.2638 8.25428 32.6726 11.5549 35.9232C14.8556 39.1738 19.3322 41 24 41Z" stroke="#4871F7" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default IconBreakdownAssist;
