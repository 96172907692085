import React from 'react';

export const IconOtherService = ({width, height}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill="none" d="M2.1665 25.8552L26.0217 2L49.8332 25.8115L26.0862 49.5585L2.1665 25.8552Z" stroke="#4871F7" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
      <path fill="none" d="M3.37207 40.5286L29.5017 14.3989" stroke="#4871F7" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
      <path fill="none" d="M11.425 48.5837L37.5547 22.4541" stroke="#4871F7" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
      <path fill="none" d="M38.188 5.71253L40.8077 3.09277" stroke="#4871F7" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
      <path fill="none" d="M46.2419 13.7677L48.8617 11.1479" stroke="#4871F7" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default IconOtherService;
