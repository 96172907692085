import React from "react";
import PhoneInput, { parsePhoneNumber } from "react-phone-number-input";

import css from "./PhoneInput.module.css";

const PhoneInputComponent = ({
  value,
  intl,
  id,
  label,
  validate,
  name,
  onChange,
  placeholder,
  config,
  phoneFieldTouched,
  onBlur,
}) => {
  const phoneNumber = parsePhoneNumber(value ?? "");

  let defaultCountry = 'IE';
  let numberWithoutCountryCode = value;
  if (phoneNumber) {
    numberWithoutCountryCode = phoneNumber.nationalNumber;
    defaultCountry = phoneNumber.country
  }

  const phoneNumberRequired = intl.formatMessage({
    id: "PhoneInput.phoneNumberRequired"
  });
  const invalidPhoneNumber = intl.formatMessage({
    id: "PhoneInput.invalidPhoneNumber",
  });

  const inputProps = {
    international: true,
    countryCallingCodeEditable: false,
    countries: config.phoneInputCountries,
    value,
    placeholder,
    name,
    onChange,
    error: numberWithoutCountryCode
      ? (validate(numberWithoutCountryCode) ? undefined : invalidPhoneNumber)
      : phoneNumberRequired,
    defaultCountry,
    onBlur,
  };

  return (
    <div className={css.phoneInputNumberContainer}>
      <div className={css.labelWrapper}>
        {label ? <label htmlFor={id} className={css.label}>{label}</label> : null}
      </div>
      <PhoneInput {...inputProps} />
      {phoneFieldTouched && inputProps.error ? <span className={css.error}>{inputProps.error}</span> : null}
    </div>
  )
};

export default PhoneInputComponent;
