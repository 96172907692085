/**
 * Renders non-reversal line items that are not listed in the
 * `LINE_ITEMS` array in util/types.js
 *
 * The line items are rendered so that the line item code is formatted to human
 * readable form and the line total is printed as price.
 *
 * If you require another kind of presentation for your line items, add them to
 * the `LINE_ITEMS` array in util/types.js and create a specific line item
 * component for them that can be used in the `OrderBreakdown` component.
 */
import React from 'react';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { humanizeLineItemCode } from '../../util/data';
import { LINE_ITEMS, propTypes } from '../../util/types';
import { RFQ } from '../../constants';
const { types } = require('sharetribe-flex-sdk');
const { Money } = types;

import css from './OrderBreakdown.module.css';

const LineItemUnknownItemsRefundMaybe = props => {
  const { lineItems, isProvider, intl } = props;

  // resolve unknown non-reversal line items
  const allItems = lineItems.filter(
    item =>
      LINE_ITEMS.indexOf(item.code) === -1 &&
      !item.code.includes('vat') &&
      !item.code.includes('day') &&
      !item.code.includes('sale') &&
      item.reversal
  );

  const items = isProvider
    ? allItems.filter(item => item.includeFor.includes('provider'))
    : allItems.filter(item => item.includeFor.includes('customer'));

  return items.length > 0 ? (
    <React.Fragment>
      {items.map((item, i) => {
        const VATItem = lineItems.find(el => el.code === `${item.code}-vat` && el.reversal);

        const isRFQ = item?.type === RFQ;

        const label = <FormattedMessage id="OrderBreakdown.refund" />;

        const total = VATItem
          ? new Money(
              VATItem.lineTotal?.amount + item?.lineTotal?.amount,
              item?.lineTotal?.currency
            )
          : item?.lineTotal;

        return (
          <div key={`${i}-item.code`} className={css.lineItem}>
            <span className={css.itemLabel}>{label}</span>
            <span className={css.itemValue}>{isRFQ ? RFQ : formatMoney(intl, total)}</span>
          </div>
        );
      })}
    </React.Fragment>
  ) : null;
};

LineItemUnknownItemsRefundMaybe.propTypes = {
  lineItems: propTypes.lineItems.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemUnknownItemsRefundMaybe;
