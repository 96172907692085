import React from 'react';

const IconTrainingCertification = ({width, height}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 48 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill='none' d="M35 25.9755V19.3892C35 18.9959 35 18.7993 34.9399 18.6257C34.8867 18.4721 34.7999 18.3323 34.6859 18.2163C34.5568 18.0851 34.3803 17.9971 34.0271 17.8212L24 12.8274M6.4 15.0187V29.9344C6.4 30.7494 6.4 31.1569 6.52764 31.5136C6.64048 31.8291 6.82438 32.1145 7.06523 32.3481C7.33767 32.6123 7.7096 32.7821 8.45338 33.1217L22.5334 39.5496C23.0729 39.796 23.3426 39.9191 23.6237 39.9677C23.8727 40.0108 24.1273 40.0108 24.3763 39.9677C24.6574 39.9191 24.9271 39.796 25.4666 39.5496L39.5466 33.1217C40.2904 32.7821 40.6623 32.6123 40.9348 32.3481C41.1756 32.1145 41.3595 31.8291 41.4724 31.5136C41.6 31.1569 41.6 30.7494 41.6 29.9344V15.0187M2 12.8274L23.2129 2.26267C23.5015 2.11894 23.6458 2.04707 23.7972 2.01879C23.9312 1.99374 24.0688 1.99374 24.2028 2.01879C24.3542 2.04707 24.4985 2.11894 24.7871 2.26267L46 12.8274L24.7871 23.3921C24.4985 23.5359 24.3542 23.6077 24.2028 23.636C24.0688 23.6611 23.9312 23.6611 23.7972 23.636C23.6458 23.6077 23.5015 23.5359 23.2129 23.3921L2 12.8274Z" stroke="#4871F7" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default IconTrainingCertification
