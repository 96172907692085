import React from 'react';

export const IconCup = ({ rootClassName }) => {
  return (
    <svg
      className={rootClassName}
      width="294"
      height="282"
      viewBox="0 0 294 282"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="293.432" height="282" rx="17.7838" fill="url(#paint0_linear_2330_1819)" />
      <g filter="url(#filter0_d_2330_1819)" fill="none">
        <path
          d="M146.294 169.282C115.379 169.282 90.3177 144.221 90.3177 113.306V61.4758C90.3177 57.6149 90.3177 55.6844 90.8803 54.1386C91.8235 51.5472 93.8649 49.5058 96.4563 48.5626C98.0021 48 99.9325 48 103.794 48H188.795C192.656 48 194.586 48 196.132 48.5626C198.724 49.5058 200.765 51.5472 201.708 54.1386C202.271 55.6844 202.271 57.6149 202.271 61.4758V113.306C202.271 144.221 177.209 169.282 146.294 169.282ZM146.294 169.282V197.271M202.271 66.6588H225.594C229.941 66.6588 232.115 66.6588 233.829 67.369C236.115 68.3159 237.931 70.1321 238.878 72.418C239.588 74.1325 239.588 76.306 239.588 80.653V85.3177C239.588 93.9938 239.588 98.3318 238.635 101.891C236.047 111.55 228.503 119.094 218.844 121.682C215.285 122.635 210.947 122.635 202.271 122.635M90.3177 66.6588H66.9941C62.6472 66.6588 60.4737 66.6588 58.7592 67.369C56.4732 68.3159 54.657 70.1321 53.7102 72.418C53 74.1325 53 76.306 53 80.653V85.3177C53 93.9938 53 98.3318 53.9537 101.891C56.5417 111.55 64.0858 119.094 73.7444 121.682C77.3035 122.635 81.6416 122.635 90.3177 122.635M103.794 234.588H188.795C191.085 234.588 192.941 232.732 192.941 230.442C192.941 212.122 178.09 197.271 159.77 197.271H132.818C114.498 197.271 99.6471 212.122 99.6471 230.442C99.6471 232.732 101.504 234.588 103.794 234.588Z"
          stroke="#FCFCFC"
          stroke-width="7.62"
          stroke-linecap="round"
          stroke-linejoin="round"
          shape-rendering="crispEdges"
        />
      </g>
      <g filter="url(#filter1_d_2330_1819)" fill="none">
        <path
          d="M160.832 99.1844V93.5494C160.832 85.7691 154.525 79.4619 146.745 79.4619C138.965 79.4619 132.658 85.7691 132.658 93.5494V99.1844M146.745 111.863V117.498M137.729 130.177H155.761C160.495 130.177 162.862 130.177 164.67 129.256C166.26 128.445 167.553 127.152 168.364 125.562C169.285 123.754 169.285 121.387 169.285 116.653V112.708C169.285 107.975 169.285 105.608 168.364 103.799C167.553 102.209 166.26 100.916 164.67 100.106C162.862 99.1844 160.495 99.1844 155.761 99.1844H137.729C132.995 99.1844 130.628 99.1844 128.82 100.106C127.23 100.916 125.937 102.209 125.126 103.799C124.205 105.608 124.205 107.975 124.205 112.708V116.653C124.205 121.387 124.205 123.754 125.126 125.562C125.937 127.152 127.23 128.445 128.82 129.256C130.628 130.177 132.995 130.177 137.729 130.177Z"
          stroke="white"
          stroke-width="5.63499"
          stroke-linecap="round"
          stroke-linejoin="round"
          shape-rendering="crispEdges"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_2330_1819"
          x="41.3813"
          y="44.1895"
          width="209.826"
          height="209.826"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="7.80868" />
          <feGaussianBlur stdDeviation="3.90434" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.18 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2330_1819" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2330_1819"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_2330_1819"
          x="113.578"
          y="76.6445"
          width="66.3348"
          height="71.9696"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="7.81" />
          <feGaussianBlur stdDeviation="3.905" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.18 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2330_1819" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2330_1819"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_2330_1819"
          x1="146.716"
          y1="0"
          x2="146.716"
          y2="282"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#48C3F7" />
          <stop offset="1" stop-color="#4871F7" />
        </linearGradient>
      </defs>
    </svg>
  );
};
