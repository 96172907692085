/**
 * Transaction process graph for plain inquiries:
 *   - default-inquiry
 */

/**
 * Transitions
 *
 * These strings must sync with values defined in Marketplace API,
 * since transaction objects given by API contain info about last transitions.
 * All the actions in API side happen in transitions,
 * so we need to understand what those strings mean.
 */

export const RFQProcessName = 'RFQ-booking/release-1';

export const transitions = {
  // A customer can initiate a transaction with an inquiry, and
  // then transition that with a request.
  INQUIRE_RFQ: 'transition/inquire-RFQ',
  SEND_QUOTE_AFTER_INQUIRE: 'transition/send-quote-after-inquire',
  EXPIRE_QUOTE: 'transition/expire-quote',
  DECLINE_QUOTE_BY_PROVIDER: 'transition/decline-quote-by-provider',
  DECLINE_QUOTE_BY_CUSTOMER: 'transition/decline-quote-by-customer',
  EDIT_QUOTE: 'transition/edit-quote',
  RETURN_BACK_AFTER_EDIT: 'transition/return-back-after-edit',
  REQUEST_PAYMENT_QUOTE: 'transition/request-payment-quote',
  REQUEST_PAYMENT_QUOTE_SALE: 'transition/request-payment-quote-sale',
  CONFIRM_PAYMENT_QUOTE: 'transition/confirm-payment-quote',
  CONFIRM_PAYMENT_QUOTE_SALE: 'transition/confirm-payment-quote-sale',
  ACCEPT_QUOTE: 'transition/accept-quote',
  ACCEPT_QUOTE_SALE: 'transition/accept-quote-sale',
  CANCEL_BY_OPERATOR: 'transition/cancel-by-operator',
  CANCEL_SALE_BY_OPERATOR: 'transition/cancel-sale-by-operator',
  COMPLETE: 'transition/complete',
  LONG_TERM_PAYOUT: 'transition/long-term-payout',
  PAYOUT_SALE: 'transition/payout-sale',
  PAYOUT: 'transition/payout',
  LONG_TERM_COMPLETE: 'transition/long-term-complete',
  SALE_COMPLETE: 'transition/sale-complete',

  REVIEW_1_BY_PROVIDER: 'transition/review-1-by-provider',
  REVIEW_1_BY_CUSTOMER: 'transition/review-1-by-customer',
  EXPIRE_REVIEW_PERIOD: 'transition/expire-review-period',
  REVIEW_2_BY_PROVIDER: 'transition/review-2-by-provider',
  REVIEW_2_BY_CUSTOMER: 'transition/review-2-by-customer',
  EXPIRE_PROVIDER_REVIEW_PERIOD: 'transition/expire-provider-review-period',
  EXPIRE_CUSTOMER_REVIEW_PERIOD: 'transition/expire-customer-review-period',

  BOOKING_CHILD_TX_FREE: 'transition/booking-child-tx-free',
  BOOKING_CHILD_TX_FREE_ACCEPT: 'transition/booking-child-tx-free-accept',
  BOOKING_CHILD_TX_FREE_CANCEL: 'transition/booking-child-tx-free-cancel',
  BOOKING_CHILD_TX_FREE_COMPLETED: 'transition/booking-child-tx-free-completed',

  CHILD_TX_FREE_SALE: 'transition/child-tx-free-sale',
  CHILD_TX_FREE_ACCEPT_SALE: 'transition/child-tx-free-accept-sale',
  CHILD_TX_FREE_CANCEL_SALE: 'transition/child-tx-free-cancel-sale',
  CHILD_TX_FREE_COMPLETED_SALE: 'transition/child-tx-free-completed-sale',
};

/**
 * States
 *
 * These constants are only for making it clear how transitions work together.
 * You should not use these constants outside of this file.
 *
 * Note: these states are not in sync with states used transaction process definitions
 *       in Marketplace API. Only last transitions are passed along transaction object.
 */
export const states = {
  INITIAL: 'initial',
  INQUIRY_RFQ: 'inquiry-RFQ',
  QUOTE_SENT: 'quote-sent',
  QUOTE_EXPIRED: 'quote-expired',
  QUOTE_DECLINED: 'quote-declined',
  EDITED: 'edited',
  PENDING_PAYMENT_QUOTE: 'pending-payment-quote',
  PENDING_PAYMENT_QUOTE_SALE: 'pending-payment-quote-sale',
  PREAUTHORIZED: 'preauthorized',
  PREAUTHORIZED_SALE: 'preauthorized-sale',
  QUOTE_ACCEPTED: 'quote-accepted',
  SALE_QUOTE_ACCEPTED: 'sale-quote-accepted',
  CANCELED: 'canceled',
  COMPLETED: 'completed',
  PAYOUT_INITIATED: 'payout-initiated',
  SALE_PAYOUT_INITIATED: 'sale-payout-initiated',
  DELIVERED: 'delivered',

  REVIEWED_BY_PROVIDER: 'reviewed-by-provider',
  REVIEWED_BY_CUSTOMER: 'reviewed-by-customer',
  REVIEWED: 'reviewed',

  PENDING_PAYMENT_FREE: 'pending-payment-free',
  BOOKING_CHILD_TX_FREE_ACCEPTED: 'booking-child-tx-free-accepted',
  BOOKING_CHILD_TX_FREE_CANCELED: 'booking-child-tx-free-canceled',
  BOOKING_CHILD_TX_FREE_COMPLETED: 'booking-child-tx-free-completed',

  PENDING_PAYMENT_FREE_SALE: 'pending-payment-free-sale',
  CHILD_TX_FREE_ACCEPTED_SALE: 'child-tx-free-accepted-sale',
  CHILD_TX_FREE_CANCELED_SALE: 'child-tx-free-canceled-sale',
  CHILD_TX_FREE_COMPLETED_SALE: 'child-tx-free-completed-sale',
};

/**
 * Description of transaction process graph
 *
 * You should keep this in sync with transaction process defined in Marketplace API
 *
 * Note: we don't use yet any state machine library,
 *       but this description format is following Xstate (FSM library)
 *       https://xstate.js.org/docs/
 */
export const graph = {
  // id is defined only to support Xstate format.
  // However if you have multiple transaction processes defined,
  // it is best to keep them in sync with transaction process aliases.
  id: RFQProcessName,

  // This 'initial' state is a starting point for new transaction
  initial: states.INITIAL,

  // States
  states: {
    [states.INITIAL]: {
      on: {
        [transitions.INQUIRE_RFQ]: states.INQUIRY_RFQ,
        [transitions.BOOKING_CHILD_TX_FREE]: states.PENDING_PAYMENT_FREE,
        [transitions.CHILD_TX_FREE_SALE]: states.PENDING_PAYMENT_FREE_SALE,
      },
    },
    [states.INQUIRY_RFQ]: {
      on: {
        [transitions.SEND_QUOTE_AFTER_INQUIRE]: states.QUOTE_SENT,
      },
    },
    [states.PENDING_PAYMENT_FREE]: {
      on: {
        [transitions.BOOKING_CHILD_TX_FREE_ACCEPT]: states.BOOKING_CHILD_TX_FREE_ACCEPTED,
      },
    },
    [states.BOOKING_CHILD_TX_FREE_ACCEPTED]: {
      on: {
        [transitions.BOOKING_CHILD_TX_FREE_CANCEL]: states.BOOKING_CHILD_TX_FREE_CANCELED,
        [transitions.BOOKING_CHILD_TX_FREE_COMPLETED]: states.BOOKING_CHILD_TX_FREE_COMPLETED,
      },
    },
    [states.PENDING_PAYMENT_FREE_SALE]: {
      on: {
        [transitions.CHILD_TX_FREE_ACCEPT_SALE]: states.CHILD_TX_FREE_ACCEPTED_SALE,
      },
    },
    [states.CHILD_TX_FREE_ACCEPTED_SALE]: {
      on: {
        [transitions.CHILD_TX_FREE_CANCEL_SALE]: states.CHILD_TX_FREE_CANCELED_SALE,
        [transitions.CHILD_TX_FREE_COMPLETED_SALE]: states.CHILD_TX_FREE_COMPLETED_SALE,
      },
    },
    [states.QUOTE_SENT]: {
      on: {
        [transitions.EXPIRE_QUOTE]: states.QUOTE_EXPIRED,
        [transitions.DECLINE_QUOTE_BY_CUSTOMER]: states.QUOTE_DECLINED,
        [transitions.DECLINE_QUOTE_BY_PROVIDER]: states.QUOTE_DECLINED,
        [transitions.REQUEST_PAYMENT_QUOTE]: states.PENDING_PAYMENT_QUOTE,
        [transitions.EDIT_QUOTE]: states.EDITED
      },
    },
    [states.EDITED]: {
      on: {
        [transitions.RETURN_BACK_AFTER_EDIT]: states.QUOTE_SENT
      }
    },
    [states.PENDING_PAYMENT_QUOTE]: {
      on: {
        [transitions.CONFIRM_PAYMENT_QUOTE]: states.PREAUTHORIZED,
        [transitions.CONFIRM_PAYMENT_QUOTE_SALE]: states.PREAUTHORIZED_SALE,
      },
    },
    [states.PREAUTHORIZED_SALE]: {
      on: {
        [transitions.ACCEPT_QUOTE_SALE]: states.SALE_QUOTE_ACCEPTED,
      },
    },
    [states.SALE_QUOTE_ACCEPTED]: {
      on: {
        [transitions.CANCEL_SALE_BY_OPERATOR]: states.CANCELED,
        [transitions.PAYOUT_SALE]: states.SALE_PAYOUT_INITIATED,
      },
    },
    [states.SALE_PAYOUT_INITIATED]: {
      on: {
        [transitions.SALE_COMPLETE]: states.DELIVERED,
      },
    },
    [states.PREAUTHORIZED]: {
      on: {
        [transitions.ACCEPT_QUOTE]: states.QUOTE_ACCEPTED,
      },
    },
    [states.QUOTE_ACCEPTED]: {
      on: {
        [transitions.CANCEL_BY_OPERATOR]: states.CANCELED,
        [transitions.COMPLETE]: states.COMPLETED,
        [transitions.LONG_TERM_PAYOUT]: states.PAYOUT_INITIATED,
      },
    },
    [states.COMPLETED]: {
      on: {
        [transitions.PAYOUT]: states.DELIVERED,
      },
    },
    [states.PAYOUT_INITIATED]: {
      on: {
        [transitions.LONG_TERM_COMPLETE]: states.DELIVERED,
      },
    },
    [states.DELIVERED]: {
      on: {
        [transitions.REVIEW_1_BY_PROVIDER]: states.REVIEWED_BY_PROVIDER,
        [transitions.REVIEW_1_BY_CUSTOMER]: states.REVIEWED_BY_CUSTOMER,
        [transitions.EXPIRE_REVIEW_PERIOD]: states.REVIEWED,
      },
    },
    [states.REVIEWED_BY_PROVIDER]: {
      on: {
        [transitions.REVIEW_2_BY_CUSTOMER]: states.REVIEWED,
        [transitions.EXPIRE_CUSTOMER_REVIEW_PERIOD]: states.REVIEWED,
      },
    },
    [states.REVIEWED_BY_CUSTOMER]: {
      on: {
        [transitions.REVIEW_2_BY_PROVIDER]: states.REVIEWED,
        [transitions.EXPIRE_PROVIDER_REVIEW_PERIOD]: states.REVIEWED,
      },
    },
    [states.REVIEWED]: { type: 'final' },
  },
};

export const statesNeedingProviderAttention = [states.INQUIRY_RFQ];

export const isRelevantPastTransition = transition => {
  return [
    transitions.INQUIRE_RFQ,
    transitions.SEND_QUOTE_AFTER_INQUIRE,
    transitions.EXPIRE_QUOTE,
    transitions.DECLINE_QUOTE_BY_PROVIDER,
    transitions.DECLINE_QUOTE_BY_CUSTOMER,
    transitions.REQUEST_PAYMENT_QUOTE,
    transitions.EDIT_QUOTE,
    transitions.RETURN_BACK_AFTER_EDIT,
    transitions.CONFIRM_PAYMENT_QUOTE,
    transitions.CONFIRM_PAYMENT_QUOTE_SALE,
    transitions.ACCEPT_QUOTE,
    transitions.ACCEPT_QUOTE_SALE,
    transitions.CANCEL_BY_OPERATOR,
    transitions.CANCEL_SALE_BY_OPERATOR,
    transitions.COMPLETE,
    transitions.LONG_TERM_PAYOUT,
    transitions.PAYOUT_SALE,
    transitions.PAYOUT,
    transitions.LONG_TERM_COMPLETE,
    transitions.SALE_COMPLETE,
    transitions.REVIEW_1_BY_PROVIDER,
    transitions.REVIEW_1_BY_CUSTOMER,
    transitions.EXPIRE_REVIEW_PERIOD,
    transitions.REVIEW_2_BY_PROVIDER,
    transitions.REVIEW_2_BY_CUSTOMER,
    transitions.EXPIRE_PROVIDER_REVIEW_PERIOD,
    transitions.EXPIRE_CUSTOMER_REVIEW_PERIOD,
  ].includes(transition);
};

export const isCustomerReview = transition => {
  return [transitions.REVIEW_1_BY_CUSTOMER, transitions.REVIEW_2_BY_CUSTOMER].includes(transition);
};

// Processes might be different on how reviews are handled.
// Default processes use two-sided diamond shape, where either party can make the review first
export const isProviderReview = transition => {
  return [transitions.REVIEW_1_BY_PROVIDER, transitions.REVIEW_2_BY_PROVIDER].includes(transition);
};

export const isCompleted = transition => {
  return false;
};

// Check when transaction is refunded (booking did not happen)
// In these transitions action/stripe-refund-payment is called
// NOTE: this functions is added just for the sake of consistency
export const isRefunded = transition => {
  return false;
};
export const isPrivileged = transition => true;

export const hiddenTransitions = [
  transitions.REQUEST_PAYMENT_QUOTE,
  transitions.ACCEPT_QUOTE,
  transitions.ACCEPT_QUOTE_SALE,
  transitions.RETURN_BACK_AFTER_EDIT
];

export const unnecessaryTransitionsRFQProcess = [
  transitions.BOOKING_CHILD_TX_FREE,
  transitions.BOOKING_CHILD_TX_FREE_ACCEPT,
  transitions.BOOKING_CHILD_TX_FREE_CANCEL,
  transitions.BOOKING_CHILD_TX_FREE_COMPLETED,
  transitions.CHILD_TX_FREE_SALE,
  transitions.CHILD_TX_FREE_ACCEPT_SALE,
  transitions.CHILD_TX_FREE_CANCEL_SALE,
  transitions.CHILD_TX_FREE_COMPLETED_SALE,
];
