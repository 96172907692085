import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { ACCOUNT_SETTINGS_PAGES, DASHBOARD_PAGES, PARTNER_PROFILE_PAGES } from '../../routing/routeConfiguration';
import { LinkTabNavHorizontal } from '../../components';

import css from './UserNav.module.css';

import {
  INDIVIDUAL,
  BUSINESS,
  EQUIPMENT_PROCUREMENT,
  EQUIPMENT_PROVIDER,
  SUBSCRIPTION_PLAN_BUSINESS,
  SUBSCRIPTION_PLAN_PREMIER,
  SUBSCRIPTION_PLAN_PREMIUM,
 } from "../../constants/index";

const UserNav = props => {
  const { className, rootClassName, currentPage, currentUser } = props;
  const classes = classNames(rootClassName || css.root, className);

  const { accountBusinessType, accountType } = currentUser?.attributes?.profile?.publicData ?? {};
  const isBuyer =
    accountType === INDIVIDUAL ||
    (accountType === BUSINESS && accountBusinessType === EQUIPMENT_PROCUREMENT);

  const subscriptionPlan = currentUser?.attributes?.profile?.privateData?.Subscription_plan;

  const tabs = [
    ...(isBuyer ? [] : [{
      text: <FormattedMessage id="UserNav.yourListings" />,
      selected: currentPage === 'ManageListingsPage',
      linkProps: {
        name: 'ManageListingsPage',
      },
    }]),
    ...(accountBusinessType === EQUIPMENT_PROVIDER &&
      subscriptionPlan &&
      [SUBSCRIPTION_PLAN_BUSINESS, SUBSCRIPTION_PLAN_PREMIER, SUBSCRIPTION_PLAN_PREMIUM]
        .find(p => p.toLowerCase() === subscriptionPlan.toLowerCase()) ? [{
      text: <FormattedMessage id="UserNav.profile" />,
      selected: PARTNER_PROFILE_PAGES.includes(currentPage),
      disabled: false,
      linkProps: {
        name: 'ProfilePage',
        params: {
          id: currentUser.id.uuid,
        }
      },
    }] : []),
    ...(accountBusinessType === EQUIPMENT_PROVIDER ? [{
      text: <FormattedMessage id="UserNav.dashboard" />,
      selected: DASHBOARD_PAGES.includes(currentPage),
      disabled: false,
      linkProps: {
        name: 'DashboardAnalyticsPage',
      },
    }] : []),
    {
      text: <FormattedMessage id="UserNav.tenders" />,
      selected: currentPage === 'TendersPage',
      disabled: false,
      linkProps: {
        name: 'TendersPage',
      },
    },
    {
      text: <FormattedMessage id="UserNav.profileSettings" />,
      selected: currentPage === 'ProfileSettingsPage',
      disabled: false,
      linkProps: {
        name: 'ProfileSettingsPage',
      },
    },
    {
      text: <FormattedMessage id="UserNav.accountSettings" />,
      selected: ACCOUNT_SETTINGS_PAGES.includes(currentPage),
      disabled: false,
      linkProps: {
        name: 'StripePayoutPage',
      },
    },
  ];

  return (
    <LinkTabNavHorizontal className={classes} tabRootClassName={css.tab} tabs={tabs} skin="dark" />
  );
};

UserNav.defaultProps = {
  className: null,
  rootClassName: null,
};

const { string } = PropTypes;

UserNav.propTypes = {
  className: string,
  rootClassName: string,
  currentPage: string.isRequired,
};

export default UserNav;
