import React from 'react';

export const IconSort = ({ rootClassName, type, direction }) => {
  return direction === 'up' ? (
    <svg
      className={rootClassName}
      width="18"
      height="18"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 6V18M12 6L7 11M12 6L17 11"
        stroke={type === 'primary' ? '#4a4a4a' : '#ffffff'}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  ) : (
    <svg
      className={rootClassName}
      width="18"
      height="18"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 6V18M12 18L7 13M12 18L17 13"
        stroke={type === 'secondary' ? '#ffffff' : '#4a4a4a'}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
