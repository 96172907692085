import React from 'react';

const IconNationwideCoverage = ({width, height}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 48 47" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill='none' d="M24 45C36.1503 45 46 35.3741 46 23.5C46 11.6259 36.1503 2 24 2C11.8497 2 2 11.6259 2 23.5C2 35.3741 11.8497 45 24 45Z" stroke="#4871F7" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
      <path fill='none' d="M29.9886 15.4718C31.0634 15.1217 31.6008 14.9466 31.9582 15.0712C32.2692 15.1796 32.5139 15.4187 32.6248 15.7227C32.7523 16.0719 32.5731 16.5971 32.2149 17.6475L28.9423 27.2421C28.8402 27.5413 28.7892 27.6909 28.7023 27.8151C28.6253 27.9251 28.528 28.0202 28.4154 28.0954C28.2883 28.1804 28.1353 28.2302 27.8292 28.33L18.0114 31.5282C16.9366 31.8783 16.3992 32.0534 16.0418 31.9288C15.7308 31.8204 15.4861 31.5813 15.3752 31.2773C15.2477 30.9281 15.4269 30.4029 15.7851 29.3525L19.0577 19.7579C19.1598 19.4587 19.2108 19.3091 19.2977 19.1849C19.3747 19.0749 19.472 18.9798 19.5846 18.9046C19.7117 18.8196 19.8647 18.7698 20.1708 18.67L29.9886 15.4718Z" stroke="#4871F7" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default IconNationwideCoverage;
