// ================ Action types ================ //

import { uploadFilesToGCS } from '../../util/api';
import { updateProfile } from '../ProfileSettingsPage/ProfileSettingsPage.duck';

export const UPDATE_BRAND_DETAILS_REQUEST = 'app/ui/UPDATE_BRAND_DETAILS_REQUEST';
export const UPDATE_BRAND_DETAILS_SUCCESS = 'app/ui/UPDATE_BRAND_DETAILS_SUCCESS';
export const UPDATE_BRAND_DETAILS_ERROR = 'app/ui/UPDATE_BRAND_DETAILS_ERROR';

// ================ Reducer ================ //

const initialState = {
  updateBrandDetailsInProgress: false,
  updateBrandDetailsError: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case UPDATE_BRAND_DETAILS_REQUEST: {
      return { ...state, updateBrandDetailsInProgress: true, updateBrandDetailsError: false };
    }
    case UPDATE_BRAND_DETAILS_SUCCESS: {
      return { ...state, updateBrandDetailsInProgress: false, updateBrandDetailsError: false };
    }
    case UPDATE_BRAND_DETAILS_ERROR: {
      return { ...state, updateBrandDetailsInProgress: false, updateBrandDetailsError: payload };
    }

    default:
      return state;
  }
}

// ================ Action creators ================ //

const updateBrandDetailsRequest = () => ({ type: UPDATE_BRAND_DETAILS_REQUEST });
const updateBrandDetailsSuccess = () => ({ type: UPDATE_BRAND_DETAILS_SUCCESS });
const updateBrandDetailsError = () => ({ type: UPDATE_BRAND_DETAILS_ERROR });

// ================ Selectors ================ //

export const updateBrandDetails = (params, callback) => async (dispatch) => {
  dispatch(updateBrandDetailsRequest());
  try {
    const {
      banner,
      headerBanner,
      imageCarousel,
      profileImage,
      imageId,
      otherOfferingServices,
      companyLegalName,
      address: location,
      socials = [],
      ...res
    } = params;

    const {
      selectedPlace: { address, origin },
    } = location;

    const formData = new FormData();
    const alreadyUploadedImagesCarousel = [];

    for (let i = 0; i < imageCarousel.length; i++) {
      if (typeof imageCarousel[i] === 'string') {
        alreadyUploadedImagesCarousel.push(imageCarousel[i]);
      } else {
        formData.append('files', imageCarousel[i]);
      }
    }

    const hasNewCarouselImages = formData.getAll('files').length;
    const imagesCarouselRes = hasNewCarouselImages
      ? await uploadFilesToGCS(formData)
      : { data: { data: imageCarousel } };

    const headerBannerFormData = new FormData();
    const alreadyUploadedImagesHeaderBanner = [];

    for (let i = 0; i < headerBanner.length; i++) {
      if (typeof headerBanner[i] === 'string') {
        alreadyUploadedImagesHeaderBanner.push(headerBanner[i]);
      } else {
        headerBannerFormData.append('files', headerBanner[i]);
      }
    }

    const bannerFormData = new FormData();
    const alreadyUploadedImagesBanner = [];

    for (let i = 0; i < banner.length; i++) {
      if (typeof banner[i] === 'string') {
        alreadyUploadedImagesBanner.push(banner[i]);
      } else {
        bannerFormData.append('files', banner[i]);
      }
    }

    const isEmptyObject = (obj) => {
      for (const key in obj) {
          if (Object.hasOwn(obj, key)) {
            return false;
          }
      }
      return true;
  }

    const isEmptyOtherServices =
      !!(
          otherOfferingServices.length &&
          (otherOfferingServices.filter((v) => isEmptyObject(v) ||
            !Object.values(v).filter(Boolean).length
          )).length
        );

    const hasNewBannerImages = bannerFormData.getAll('files').length;
    const bannerRes = hasNewBannerImages
      ? await uploadFilesToGCS(bannerFormData)
      : { data: { data: banner } };

    const hasNewHeaderBannerImages = headerBannerFormData.getAll('files').length;
    const headerBannerRes = hasNewHeaderBannerImages
      ? await uploadFilesToGCS(headerBannerFormData)
      : { data: { data: headerBanner } };

    const data = {
      ...(imageId ? { profileImageId: imageId?.uuid } : {}),
      publicData: {
        companyLegalName,
        companyAddress: { address, origin },
        brandDetails: {
          ...res,
          otherOfferingServices: isEmptyOtherServices ? undefined : otherOfferingServices,
          socials: socials.filter(({ url }) => !!url),
          banner: hasNewBannerImages
            ? [...alreadyUploadedImagesBanner, ...bannerRes?.data?.data]
            : bannerRes?.data?.data,
          headerBanner: hasNewHeaderBannerImages
            ? [...alreadyUploadedImagesHeaderBanner, ...headerBannerRes?.data?.data]
            : headerBannerRes?.data?.data,
          imageCarousel: hasNewCarouselImages
            ? [...alreadyUploadedImagesCarousel, ...imagesCarouselRes?.data?.data]
            : imagesCarouselRes?.data?.data,
        },
      },
    };

    await dispatch(updateProfile(data));
    dispatch(updateBrandDetailsSuccess());
    callback();
  } catch (e) {
    dispatch(updateBrandDetailsError(e.message));
  }
};
