import React from 'react';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';

import css from './RFQComponent.module.css';

const RFQComponent = ({ className, rootClassName }) => {
  const classes = classNames(rootClassName || css.root, className);

  return (
    <span className={classes}>
      <FormattedMessage id="ListingPage.RFQ" />
    </span>
  );
}

export default RFQComponent;
