import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconSocialMediaYoutube.module.css';

const IconSocialMediaYoutube = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      width="10"
      height="17"
      viewBox="0 0 10 17"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.125 2.245A2.278 2.278 0 0 0 16.522.63C15.108.25 9.438.25 9.438.25s-5.67 0-7.085.381C1.573.841.958 1.46.75 2.245.37 3.668.37 6.638.37 6.638s0 2.97.38 4.393a2.245 2.245 0 0 0 1.603 1.588C3.767 13 9.438 13 9.438 13s5.67 0 7.084-.381a2.245 2.245 0 0 0 1.603-1.588c.38-1.423.38-4.393.38-4.393s0-2.97-.38-4.393ZM7.583 9.335V3.941l4.74 2.696-4.74 2.696Z"
        fillRule="evenodd"
      />
    </svg>
  );
};

IconSocialMediaYoutube.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

IconSocialMediaYoutube.propTypes = { rootClassName: string, className: string };

export default IconSocialMediaYoutube;
