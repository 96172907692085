import React from 'react';

export const IconAddress = ({ rootClassName, stroke="#4871F7", fill="#FFFFFF" }) => {
  return (
    <svg
      className={rootClassName}
      width="24"
      height="25"
      viewBox="0 0 24 25"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.5 12.7408C19.5 17.7446 14.0093 21.1193 12.4239 21.9895C12.1563 22.1363 11.8437 22.1363 11.5761 21.9895C9.9907 21.1193 4.5 17.7446 4.5 12.7408C4.5 8.25453 8.13401 5.26367 12 5.26367C16 5.26367 19.5 8.25453 19.5 12.7408Z"
        stroke={stroke}
        fill={fill}
      />
      <path
        d="M15.5 12.7407C15.5 14.6656 13.9344 16.2285 12 16.2285C10.0656 16.2285 8.5 14.6656 8.5 12.7407C8.5 10.8159 10.0656 9.25293 12 9.25293C13.9344 9.25293 15.5 10.8159 15.5 12.7407Z"
        stroke={stroke}
        fill={fill}
      />
    </svg>
  );
};
