export const BUSINESS = 'business';
export const INDIVIDUAL = 'individual';
export const EQUIPMENT_PROCUREMENT = 'equipmentProcurement';
export const EQUIPMENT_PROVIDER = 'equipmentProvider';
export const NO_SUBSCRIPTION_PLAN = "None";
export const SUBSCRIPTION_PLAN_BUSINESS = "Business";
export const SUBSCRIPTION_PLAN_PREMIER = "Premier";
export const SUBSCRIPTION_PLAN_PREMIUM = "Premium";
export const SUBSCRIPTION_PLAN_PLATINUM = "Platinum";
export const RFQ = 'RFQ';

export const PREMIUM_PARTNER_COLLECT_IN_STORE_SERVICE = 'Collect In-Store';
export const PREMIUM_PARTNER_NATIONWIDE_COVERAGE_SERVICE = 'Nationwide Coverage';
export const PREMIUM_PARTNER_BREAKDOWN_ASSIST_SERVICE = '24/7 Breakdown Assist';
export const PREMIUM_PARTNER_SAME_DAY_BOOK_COLLECT_SERVICE = 'Same Day Book & Collect';
export const PREMIUM_PARTNER_TRANSPORTATION_AVAILABLE_SERVICE = 'Transportation Available';
export const PREMIUM_PARTNER_TRAINING_CERTIFICATION_SERVICE = 'Training & Certification';
export const PREMIUM_PARTNER_CERTIFIED_MANUFACTURER_RESELLER_SERVICE = 'Certified Manufacturer Reseller';
export const PREMIUM_PARTNER_MACHINE_OPERATORS_AVAILABLE_SERVICE = 'Machine Operators Available';
export const PREMIUM_PARTNER_CREDIT_TERMS_AVAILABLE_SERVICE = 'Credit Terms Available';

export const PREMIUM_PARTNERS_SERVICE_OFFERINGS = [
  { label: PREMIUM_PARTNER_COLLECT_IN_STORE_SERVICE, value: PREMIUM_PARTNER_COLLECT_IN_STORE_SERVICE },
  { label: PREMIUM_PARTNER_NATIONWIDE_COVERAGE_SERVICE, value: PREMIUM_PARTNER_NATIONWIDE_COVERAGE_SERVICE },
  { label: PREMIUM_PARTNER_BREAKDOWN_ASSIST_SERVICE, value: PREMIUM_PARTNER_BREAKDOWN_ASSIST_SERVICE },
  { label: PREMIUM_PARTNER_SAME_DAY_BOOK_COLLECT_SERVICE, value: PREMIUM_PARTNER_SAME_DAY_BOOK_COLLECT_SERVICE },
  { label: PREMIUM_PARTNER_TRANSPORTATION_AVAILABLE_SERVICE, value: PREMIUM_PARTNER_TRANSPORTATION_AVAILABLE_SERVICE },
  { label: PREMIUM_PARTNER_TRAINING_CERTIFICATION_SERVICE, value: PREMIUM_PARTNER_TRAINING_CERTIFICATION_SERVICE },
  { label: PREMIUM_PARTNER_CERTIFIED_MANUFACTURER_RESELLER_SERVICE, value: PREMIUM_PARTNER_CERTIFIED_MANUFACTURER_RESELLER_SERVICE },
  { label: PREMIUM_PARTNER_MACHINE_OPERATORS_AVAILABLE_SERVICE, value: PREMIUM_PARTNER_MACHINE_OPERATORS_AVAILABLE_SERVICE },
  { label: PREMIUM_PARTNER_CREDIT_TERMS_AVAILABLE_SERVICE, value: PREMIUM_PARTNER_CREDIT_TERMS_AVAILABLE_SERVICE },
];

export const exportListingsTemplateExample = [
  {
    "Title": "Put the Title of your Product here",
    "Description": "Put Description of your Product here",
    "Price Amount": "Enter Price here",
    "VAT Rate": 23,
    "Category": "Cranes",
    "Sub-category": "Mobile Cranes",
    "Listing Type": "item",
    "Is product new": "Put Yes if New",
    "Year": "Put year",
    "Serial Numbers": "Put one or more comma separated serial numbers",
    "Image Links": "Put one or more comma separated links to the images",
    "Free Attachment label 1": "Put the title of free attachment if you have it",
    "Free Attachment description 1": "Put description of free attachment if you have it",
    "Paid Attachment label 1": "Put the title of Paid attachment if you have it",
    "Paid Attachment description 1": "Put description of Paid attachment if you have it",
    "Attachment Price Amount 1": "Enter attachment  daily price here",
    "Attachment Price Per Week 1": "Enter attachment weekly price here",
    "Attachment Price Per Month 1": "Enter attachment monthly price here",
    "Attachment VAT Rate 1": 23,
    "Location": "Enter location for collection",
    "Shipping Location": "Enter source location for shipping",
    "Location Building": "Enter building location for collection",
    "Free transportation range": "Enter free transportation KM each way if you have it",
    "Location Price Per One KM": "Enter per KM price for Shipping if you have it",
   },
   {
    "Title": "Put the Title of your Rental here",
    "Description": "Put Description of your Rental here",
    "Price Amount": "Enter Price here",
    "Price Per Week": "Enter Price here",
    "Price Per Month": "Enter Price here",
    "VAT Rate": 13.5,
    "Category": "Bulldozers",
    "Sub-category": "Wheeled Bulldozer",
    "Listing Type": "day",
    "Is product new": "Put Yes if New",
    "Year": "Put year",
    "Serial Numbers": "Put one or more comma separated serial numbers",
    "Image Links": "Put one or more comma separated links to the images",
    "Free Attachment label 1": "Put the title of free attachment if you have it",
    "Free Attachment description 1": "Put description of free attachment if you have it",
    "Paid Attachment label 1": "Put the title of Paid attachment if you have it",
    "Paid Attachment description 1": "Put description of Paid attachment if you have it",
    "Attachment Price Amount 1": "Enter attachment price here",
    "Attachment VAT Rate 1": 23,
    "Location": "Enter location for collection",
    "Shipping Location": "Enter source location for shipping",
    "Location Building": "Enter building location for collection",
    "Free transportation range": "Enter free transportation KM each way if you have it",
    "Location Price Per One KM": "Enter per KM price for Shipping if you have it",
   }
];
