import { parse } from '../../util/urlHelpers';
import { dateToTimestamp } from '../../util/dates';
import moment from 'moment';
import { approveListing, updateUserProfile } from '../../util/api';

const LISTING_TYPE_TENDER = 'tender';

const MAIN_CPO_ID = process.env.REACT_APP_MAIN_CPO_ID;

// ================ Action types ================ //

export const SET_INITIAL_VALUES = 'app/ui/SET_INITIAL_VALUES';

export const CREATE_TENDER_REQUEST = 'app/ui/CREATE_TENDER_REQUEST';
export const CREATE_TENDER_SUCCESS = 'app/ui/CREATE_TENDER_SUCCESS';
export const CREATE_TENDER_ERROR = 'app/ui/CREATE_TENDER_ERROR';

export const GET_ADMIN_PROFILE_REQUEST = 'app/ListingPage/GET_ADMIN_PROFILE_REQUEST';
export const GET_ADMIN_PROFILE_SUCCESS = 'app/ListingPage/GET_ADMIN_PROFILE_SUCCESS';
export const GET_ADMIN_PROFILE_ERROR = 'app/ListingPage/GET_ADMIN_PROFILE_ERROR';

export const GET_TRANSACTIONS_BY_LISTING_ID_REQUEST =
  'app/ListingPage/GET_TRANSACTIONS_BY_LISTING_ID_REQUEST';
export const GET_TRANSACTIONS_BY_LISTING_ID_SUCCESS =
  'app/ListingPage/GET_TRANSACTIONS_BY_LISTING_ID_SUCCESS';
export const GET_TRANSACTIONS_BY_LISTING_ID_ERROR =
  'app/ListingPage/GET_TRANSACTIONS_BY_LISTING_ID_ERROR';

// ================ Reducer ================ //

const initialState = {
  tenders: [],
  createTenderInProgress: false,
  createTenderError: null,
  pagination: null,
  adminProfile: null,
  getAdminProfileInProgress: false,
  getAdminProfileError: null,
  initiatedTenderId: null,
  initiatedTenderIdInProgress: false,
  initiatedTenderIdInError: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_VALUES: {
      return {
        ...state,
        tenders: payload.data,
        pagination: payload.meta,
      };
    }

    case CREATE_TENDER_REQUEST: {
      return { ...state, createTenderInProgress: true, createTenderError: false };
    }
    case CREATE_TENDER_SUCCESS: {
      return {
        ...state,
        createTenderInProgress: false,
        createTenderError: payload,
        tenders: [payload, ...state.tenders],
      };
    }
    case CREATE_TENDER_ERROR: {
      return { ...state, createTenderInProgress: false, createTenderError: payload };
    }

    case GET_ADMIN_PROFILE_REQUEST:
      return { ...state, getAdminProfileInProgress: true, getAdminProfileError: null };
    case GET_ADMIN_PROFILE_SUCCESS:
      return {
        ...state,
        getAdminProfileInProgress: false,
        getAdminProfileError: null,
        adminProfile: payload,
      };
    case GET_ADMIN_PROFILE_ERROR:
      return { ...state, getAdminProfileInProgress: false, getAdminProfileError: payload };

    case GET_TRANSACTIONS_BY_LISTING_ID_REQUEST:
      return {
        ...state,
        initiatedTenderIdInProgress: true,
        initiatedTenderIdInError: null,
        initiatedTenderId: null,
      };
    case GET_TRANSACTIONS_BY_LISTING_ID_SUCCESS:
      return {
        ...state,
        initiatedTenderIdInProgress: false,
        initiatedTenderIdInError: null,
        initiatedTenderId: payload,
      };
    case GET_TRANSACTIONS_BY_LISTING_ID_ERROR:
      return {
        ...state,
        initiatedTenderIdInProgress: false,
        initiatedTenderIdInError: payload,
        initiatedTenderId: null,
      };

    default:
      return state;
  }
}

// ================ Action creators ================ //

const createTenderRequest = () => ({ type: CREATE_TENDER_REQUEST });
const createTenderSuccess = data => ({ type: CREATE_TENDER_SUCCESS, payload: data });
const createTenderError = error => ({ type: CREATE_TENDER_ERROR, payload: error });

export const getAdminProfileRequest = () => ({ type: GET_ADMIN_PROFILE_REQUEST });
export const getAdminProfileSuccess = data => ({ type: GET_ADMIN_PROFILE_SUCCESS, payload: data });
export const getAdminProfileError = error => ({ type: GET_ADMIN_PROFILE_ERROR, payload: error });

export const getTransactionsByListingIdRequest = () => ({
  type: GET_TRANSACTIONS_BY_LISTING_ID_REQUEST,
});
export const getTransactionsByListingIdSuccess = payload => ({
  type: GET_TRANSACTIONS_BY_LISTING_ID_SUCCESS,
  payload,
});
export const getTransactionsByListingIdError = error => ({
  type: GET_TRANSACTIONS_BY_LISTING_ID_ERROR,
  payload: error,
});

// ================ Selectors ================ //

export const createTender = (params, refNumber) => (dispatch, getState, sdk) => {
  dispatch(createTenderRequest());
  try {
    return sdk.ownListings
      .create(params, { expand: true })
      .then(res => approveListing({ id: res?.data?.data?.id?.uuid }))
      .then(listing => dispatch(createTenderSuccess(listing.data.data)))
      .then(() =>
        dispatch(
          updateAdminProfile({
            id: MAIN_CPO_ID,
            params: { publicData: { referenceNumber: refNumber } },
          })
        )
      )
      .catch(e => dispatch(createTenderError(e.message)));
  } catch (e) {
    dispatch(createTenderError(e.message));
  }
};

export const loadData = (params, search) => (dispatch, getState, sdk) => {
  const queryParams = parse(search);

  const { page = 1, sort, keywords, pub_tenderStatus, dates, startDate } = queryParams;

  const [deadlineStart, deadlineEnd] = (dates && dates.split(',')) || [];
  const deadlineStartTimestamp = deadlineStart && dateToTimestamp(deadlineStart);
  const deadlineEndTimestamp = deadlineEnd && dateToTimestamp(moment(deadlineEnd).endOf('day'));

  const deadlineParams =
    deadlineStartTimestamp && deadlineEndTimestamp
      ? {
          pub_submissionDeadlineDate: `${deadlineStartTimestamp},${deadlineEndTimestamp}`,
        }
      : {};

  const [dateStart, dateEnd] = (startDate && startDate.split(',')) || [];
  const startDateTimestamp = dateStart && dateToTimestamp(dateStart);
  const endDateTimestamp = dateEnd && dateToTimestamp(moment(dateEnd).endOf('day'));

  const startDateParams =
    startDateTimestamp && endDateTimestamp
      ? {
          pub_tenderDatesStart: `${startDateTimestamp},${endDateTimestamp}`,
        }
      : {};

  dispatch(getAdminProfile());

  return sdk.listings
    .query({
      pub_listingType: LISTING_TYPE_TENDER,
      page,
      perPage: 12,
      sort,
      keywords,
      pub_tenderStatus,
      ...deadlineParams,
      ...startDateParams,
    })
    .then(res => dispatch({ type: SET_INITIAL_VALUES, payload: res.data }));
};

export const getAdminProfile = () => (dispatch, getState, sdk) => {
  dispatch(getAdminProfileRequest());
  return sdk.users
    .show({ id: MAIN_CPO_ID })
    .then(res => dispatch(getAdminProfileSuccess(res?.data?.data)))
    .catch(e => dispatch(getAdminProfileError(e?.message)));
};

export const updateAdminProfile = ({ id, params }) => () => {
  return updateUserProfile({ id, params }).catch(e => console.log(e.message));
};

export const getTransactionsByListingId = id => (dispatch, getState, sdk) => {
  dispatch(getTransactionsByListingIdRequest());

  return sdk.transactions
    .query({
      only: 'order',
      listingId: id,
    })
    .then(res => {
      dispatch(getTransactionsByListingIdSuccess(res?.data?.data[0]?.id?.uuid));
      return res?.data?.data[0]?.id?.uuid;
    })
    .catch(e => dispatch(getTransactionsByListingIdError(e)));
};
