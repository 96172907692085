import React from 'react';

const IconCollectInStore = ({width, height}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 48 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill='none' d="M29.4168 2V23.6667M29.4168 23.6667L37.0002 15.5417M29.4168 23.6667L21.8335 15.5417" stroke="#4871F7" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
      <path fill='none' d="M11.0002 49.8555H16.6558C17.3932 49.8555 18.1261 49.9432 18.8409 50.1187L24.8168 51.5709C26.1134 51.8868 27.4642 51.9175 28.7744 51.663L35.3816 50.3776C37.127 50.0376 38.7326 49.2018 39.9909 47.9778L44.6656 43.4304C46.0006 42.1339 46.0006 40.0303 44.6656 38.7316C43.4637 37.5624 41.5604 37.4308 40.2006 38.4223L34.7525 42.3972C33.9722 42.9675 33.0228 43.2746 32.0464 43.2746H26.7854L30.1341 43.2745C32.0216 43.2745 33.5505 41.7872 33.5505 39.9511V39.2865C33.5505 37.7619 32.4839 36.4326 30.964 36.0641L25.7954 34.8071C24.9543 34.6031 24.0929 34.5 23.227 34.5C21.1365 34.5 17.3526 36.2308 17.3526 36.2308L11.0002 38.8872M2.3335 37.9667L2.3335 50.5333C2.3335 51.7468 2.3335 52.3535 2.56965 52.817C2.77737 53.2247 3.10883 53.5561 3.51652 53.7638C3.97999 54 4.58671 54 5.80016 54H7.53349C8.74694 54 9.35367 54 9.81714 53.7638C10.2248 53.5561 10.5563 53.2247 10.764 52.817C11.0002 52.3535 11.0002 51.7468 11.0002 50.5333V37.9667C11.0002 36.7532 11.0002 36.1465 10.764 35.683C10.5563 35.2753 10.2248 34.9439 9.81714 34.7362C9.35367 34.5 8.74694 34.5 7.5335 34.5L5.80016 34.5C4.58672 34.5 3.97999 34.5 3.51652 34.7362C3.10883 34.9439 2.77737 35.2753 2.56965 35.683C2.3335 36.1465 2.3335 36.7532 2.3335 37.9667Z" stroke="#4871F7" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default IconCollectInStore;
