import { updateTransactionMetadata, uploadFilesToGCS } from '../../util/api';
import { storableError } from '../../util/errors';
import { getProcess } from '../../transactions/transaction';
import { fetchCurrentUserHasOrdersSuccess } from '../../ducks/user.duck';
import {
  sendInquiryError,
  sendInquiryRequest,
  sendInquirySuccess,
} from '../ListingPage/ListingPage.duck';
import { denormalisedResponseEntities } from '../../util/data';
import { getTransactionsByListingId } from '../TendersPage/TendersPage.duck';

const ALIAS = 'tender-inquiry/release-1';

// ================ Action types ================ //

export const FETCH_TENDER_VALUES_REQUEST = 'app/ui/FETCH_INITIAL_VALUES_REQUEST';
export const FETCH_TENDER_VALUES_SUCCESS = 'app/ui/FETCH_TENDER_VALUES_SUCCESS';
export const FETCH_TENDER_VALUES_ERROR = 'app/ui/FETCH_TENDER_VALUES_ERROR';

// ================ Reducer ================ //

const initialState = {
  tender: null,
  fetchTenderInProgress: false,
  fetchTenderError: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_TENDER_VALUES_REQUEST: {
      return {
        ...state,
        fetchTenderInProgress: true,
        fetchTenderError: null,
      };
    }
    case FETCH_TENDER_VALUES_SUCCESS: {
      return {
        ...state,
        fetchTenderInProgress: false,
        fetchTenderError: null,
        tender: payload,
      };
    }
    case FETCH_TENDER_VALUES_ERROR: {
      return {
        ...state,
        fetchTenderInProgress: false,
        fetchTenderError: payload,
      };
    }

    default:
      return state;
  }
}

// ================ Action creators ================ //

const fetchTenderRequest = () => ({ type: FETCH_TENDER_VALUES_REQUEST });
const fetchTenderSuccess = data => ({ type: FETCH_TENDER_VALUES_SUCCESS, payload: data });
const fetchTenderError = error => ({ type: FETCH_TENDER_VALUES_ERROR, payload: error });

// ================ Selectors ================ //

export const sendInquiryTender = (listing, message, quotes) => (dispatch, getState, sdk) => {
  dispatch(sendInquiryRequest());
  const processAlias = ALIAS;

  const listingId = listing?.id;
  const [processName] = processAlias.split('/');
  const transitions = getProcess(processName)?.transitions;

  const bodyParams = {
    transition: transitions.INQUIRE_TENDER,
    processAlias,
    params: {
      listingId,
      protectedData: { tenderId: listingId.uuid }},
  };

  let transactionId;

  const isEmptyQuotes = !quotes || !quotes?.length;

  return sdk.transactions
    .initiate(bodyParams)
    .then(response => {
      transactionId = response.data.data.id;

      if (!message) {
        return transactionId
      }

      // Send the message to the created transaction
      return sdk.messages.send({ transactionId, content: message }).then(() => {
        dispatch(fetchCurrentUserHasOrdersSuccess(true));
        return transactionId;
      });
    })
    .then(() => {
      if (isEmptyQuotes) {
        dispatch(sendInquirySuccess());
        return;
      }

      const formData = new FormData();

      for (let i = 0; i < quotes.length; i++) {
        formData.append('files', quotes[i]);
      }

      return uploadFilesToGCS(formData);
    })
    .then(uploadedFiles => {
      dispatch(sendInquirySuccess());
      return updateTransactionMetadata({
        id: transactionId,
        metadata: { quotes: uploadedFiles?.data?.data },
      });
    })
    .catch(e => {
      dispatch(sendInquiryError(storableError(e)));
      throw e;
    });
};

export const loadData = params => (dispatch, getState, sdk) => {
  dispatch(fetchTenderRequest());

  const { id } = params;

  return sdk.listings
    .show({ id, include: ['author'] })
    .then(response => {
      const entities = denormalisedResponseEntities(response);
      dispatch(fetchTenderSuccess(entities[0]));
    })
    .then(() => {
      return dispatch(getTransactionsByListingId(id));
    })
    .catch(e => dispatch(fetchTenderError(e.message)));
};
