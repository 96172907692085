import React from 'react';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import {
  propTypes,
  LINE_ITEM_GRAND_VAT_TOTAL,
  LINE_ITEM_PROVIDER_COMMISSION,
} from '../../util/types';

import css from './OrderBreakdown.module.css';

const { Money } = sdkTypes;

const LineItemSubtotal = props => {
  const { lineItems, intl } = props;

  const noVATLineItems = lineItems.filter(
    item =>
      !item.code.includes('vat') &&
      item.code !== LINE_ITEM_GRAND_VAT_TOTAL &&
      item.code !== LINE_ITEM_PROVIDER_COMMISSION &&
      !item.reversal
  );

  const subtotalAmount = noVATLineItems?.reduce((acc, curr) => {
    acc += curr.lineTotal.amount;
    return acc;
  }, 0);

  const subtotal = new Money(subtotalAmount, lineItems[0]?.lineTotal?.currency);

  return subtotal ? (
    <>
      <hr className={css.totalDivider} />
      <div className={css.lineItem}>
        <span className={css.itemLabel}>
          <FormattedMessage id="OrderBreakdown.subtotalItem" />
        </span>
        <span className={css.itemValue}>{formatMoney(intl, subtotal)}</span>
      </div>
    </>
  ) : null;
};

LineItemSubtotal.propTypes = {
  lineItems: propTypes.lineItems.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemSubtotal;
