import { uploadFilesToGCS } from '../../util/api';
import { updateProfile } from '../ProfileSettingsPage/ProfileSettingsPage.duck';

// ================ Action types ================ //

export const UPDATE_DOCUMENTS_REQUEST = 'app/ui/UPDATE_DOCUMENTS_REQUEST';
export const UPDATE_DOCUMENTS_SUCCESS = 'app/ui/UPDATE_DOCUMENTS_SUCCESS';
export const UPDATE_DOCUMENTS_ERROR = 'app/ui/UPDATE_DOCUMENTS_ERROR';

// ================ Reducer ================ //

const initialState = {
  updateDocumentsInProgress: false,
  updateDocumentsError: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case UPDATE_DOCUMENTS_REQUEST: {
      return { ...state, updateDocumentsInProgress: true, updateDocumentsError: false };
    }
    case UPDATE_DOCUMENTS_SUCCESS: {
      return { ...state, updateDocumentsInProgress: false, updateDocumentsError: false };
    }
    case UPDATE_DOCUMENTS_ERROR: {
      return { ...state, updateDocumentsInProgress: false, updateDocumentsError: payload };
    }

    default:
      return state;
  }
}

// ================ Action creators ================ //

const updateDocumentsRequest = () => ({ type: UPDATE_DOCUMENTS_REQUEST });
const updateDocumentsSuccess = () => ({ type: UPDATE_DOCUMENTS_SUCCESS });
const updateDocumentsError = () => ({ type: UPDATE_DOCUMENTS_ERROR });

// ================ Selectors ================ //

export const updateDocuments = (params, bucket) => async (dispatch, getState, sdk) => {
  dispatch(updateDocumentsRequest());
  try {
    const { currentUser } = getState().user;
    const { hireTerms, salesTerms } = params;

    const updatedData = {};
    if (!hireTerms?.length) {
      updatedData.hireTerms = null;
    } else if (typeof hireTerms[0] === 'string') {
      updatedData.hireTerms = hireTerms;
    } else {
      const formData = new FormData();
      formData.append('files', hireTerms[0]);
      formData.append('userId', currentUser?.id?.uuid);
      formData.append('bucketName', bucket);
      const result = await uploadFilesToGCS(formData);
      updatedData.hireTerms = result.data.data;
    }

    if (!salesTerms?.length) {
      updatedData.salesTerms = null;
    } else if (typeof salesTerms[0] === 'string') {
      updatedData.salesTerms = salesTerms;
    } else {
      const formData = new FormData();
      formData.append('files', salesTerms[0]);
      formData.append('userId', currentUser?.id?.uuid);
      formData.append('bucketName', bucket);
      const result = await uploadFilesToGCS(formData);
      updatedData.salesTerms = result.data.data;
    }

    const data = {
      publicData: updatedData,
    };

    await dispatch(updateProfile(data));

    return await dispatch(updateDocumentsSuccess());
  } catch (e) {
    dispatch(updateDocumentsError(e.message));
  }
};
