import React from 'react';

export const IconPhone = ({ rootClassName }) => {
  return (
    <svg
      className={rootClassName}
      width="33"
      height="34"
      viewBox="0 0 33 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.8617 19.9608L31.614 24.713C32.253 25.3521 32.253 26.3882 31.614 27.0272C28.159 30.4822 22.6894 30.8709 18.7806 27.9393L15.9534 25.8189C12.8245 23.4722 10.045 20.6927 7.69832 17.5638L5.57792 14.7366C2.6463 10.8278 3.03502 5.35812 6.48996 1.90318C7.129 1.26414 8.16509 1.26414 8.80413 1.90318L13.5564 6.65543C14.2572 7.35626 14.2572 8.49252 13.5564 9.19334L11.7239 11.0259C11.4326 11.3171 11.3604 11.762 11.5446 12.1304C13.6742 16.3895 17.1277 19.843 21.3868 21.9726C21.7552 22.1567 22.2001 22.0845 22.4913 21.7933L24.3238 19.9608C25.0247 19.26 26.1609 19.26 26.8617 19.9608Z"
        stroke="#4871F7"
        stroke-width="1.79457"
        fill="#ffffff"
      />
    </svg>
  );
};
