import React from "react";

const FileIcon = ({ color }) => {
  return (
    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill="transparent" d="M50.6668 34.6667V40C50.6668 47.5425 50.6668 51.3137 48.3237 53.6569C45.9805 56 42.2093 56 34.6668 56H29.3335C21.791 56 18.0198 56 15.6766 53.6569C13.3335 51.3137 13.3335 47.5425 13.3335 40V24C13.3335 16.4575 13.3335 12.6863 15.6766 10.3431C18.0198 8 21.791 8 29.3335 8H32.0002" stroke={color} strokeWidth="2.66667" strokeLinecap="round"/>
      <path d="M48 8L48 24" stroke={color} strokeWidth="2.66667" strokeLinecap="round"/>
      <path d="M56 16L40 16" stroke={color} strokeWidth="2.66667" strokeLinecap="round"/>
      <path d="M24 34.6667L40 34.6667" stroke={color} strokeWidth="2.66667" strokeLinecap="round"/>
      <path d="M24 24L34.6667 24" stroke={color} strokeWidth="2.66667" strokeLinecap="round"/>
      <path d="M24 45.3333L34.6667 45.3333" stroke={color} strokeWidth="2.66667" strokeLinecap="round"/>
    </svg>
  )
}

export default FileIcon;
