import React from 'react';

export const IconMessage = ({ rootClassName }) => {
  return (
    <svg
      className={rootClassName}
      width="32"
      height="24"
      viewBox="0 0 32 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1.83496"
        y="1.13184"
        width="28.7132"
        height="21.5349"
        rx="3.58915"
        stroke="#4871F7"
        stroke-width="1.79457"
        fill="#ffffff"
      />
      <path
        d="M1.83496 6.51562L14.5864 12.8914C15.5969 13.3966 16.7862 13.3966 17.7967 12.8914L30.5481 6.51562"
        stroke="#4871F7"
        stroke-width="1.79457"
        fill="#ffffff"
      />
    </svg>
  );
};
