import React from 'react';

export const IconUploadCloud = ({ rootClassName }) => {
  return (
    <svg
      className={rootClassName}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.0008 2.08301C8.13666 2.08301 6.53333 3.19587 5.81725 4.79173C5.74848 4.94498 5.69976 5.0535 5.66221 5.1325C5.64362 5.17161 5.62966 5.19946 5.61897 5.21934C5.61102 5.23415 5.60677 5.24096 5.60583 5.24248C5.60572 5.24265 5.60565 5.24276 5.60563 5.2428C5.54812 5.32318 5.51772 5.34769 5.49997 5.35918C5.48215 5.37071 5.44713 5.38852 5.34935 5.40817C5.31979 5.41411 5.25838 5.41634 5.00114 5.41634C3.39031 5.41634 2.08447 6.72218 2.08447 8.33301C2.08447 9.94384 3.39031 11.2497 5.00114 11.2497H5.56111L6.39444 10.4163H5.00114C3.85055 10.4163 2.91781 9.4836 2.91781 8.33301C2.91781 7.18241 3.85055 6.24967 5.00114 6.24967L5.04573 6.2497C5.23385 6.24985 5.39017 6.24997 5.51357 6.22516C5.66435 6.19486 5.81264 6.14947 5.95282 6.05873C6.093 5.96798 6.19512 5.85127 6.28449 5.72611C6.33266 5.65866 6.37567 5.57267 6.41484 5.49025C6.4568 5.40199 6.50925 5.28509 6.57529 5.13792L6.5753 5.13789L6.57754 5.13289C7.16417 3.82554 8.47676 2.91634 10.0008 2.91634C11.5247 2.91634 12.8371 3.82529 13.4238 5.13235L13.4261 5.13741L13.4261 5.13748C13.4923 5.2849 13.5449 5.40202 13.587 5.49044C13.6262 5.57304 13.6694 5.65922 13.7177 5.7268C13.807 5.85169 13.909 5.96816 14.049 6.05876C14.189 6.14935 14.337 6.1947 14.4875 6.22504C14.6112 6.24997 14.7679 6.24985 14.9565 6.2497L15.0011 6.24967C16.1517 6.24967 17.0845 7.18241 17.0845 8.33301C17.0845 9.4836 16.1517 10.4163 15.0011 10.4163H13.6078L14.4412 11.2497H15.0011C16.612 11.2497 17.9178 9.94384 17.9178 8.33301C17.9178 6.72218 16.612 5.41634 15.0011 5.41634C14.7433 5.41634 14.6818 5.4141 14.6521 5.40813C14.5545 5.38845 14.5195 5.37067 14.5018 5.35917C14.4841 5.34771 14.4537 5.32327 14.3962 5.24303C14.3962 5.24299 14.3961 5.24288 14.396 5.24271C14.3951 5.24119 14.3908 5.23437 14.3828 5.21953C14.3721 5.19961 14.3581 5.17171 14.3395 5.13252C14.3019 5.05337 14.253 4.94463 14.1841 4.79108C13.4679 3.19557 11.8647 2.08301 10.0008 2.08301Z"
        fill="#4A4A4A"
      />
      <path
        d="M10.0003 10L9.7057 9.70537L10.0003 9.41074L10.295 9.70537L10.0003 10ZM10.417 17.5C10.417 17.7301 10.2304 17.9167 10.0003 17.9167C9.77021 17.9167 9.58366 17.7301 9.58366 17.5L10.417 17.5ZM6.37236 13.0387L9.7057 9.70537L10.295 10.2946L6.96162 13.628L6.37236 13.0387ZM10.295 9.70537L13.6283 13.0387L13.039 13.628L9.7057 10.2946L10.295 9.70537ZM10.417 10L10.417 17.5L9.58366 17.5L9.58366 10L10.417 10Z"
        fill="#4A4A4A"
      />
    </svg>
  );
};
