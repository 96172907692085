import React from 'react';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import {
  LINE_ITEM_TRANSPORTATION,
  LINE_ITEM_TRANSPORTATION_VAT,
  propTypes,
} from '../../util/types';
import { RFQ } from '../../constants';
const { types } = require('sharetribe-flex-sdk');
const { Money } = types;

import css from './OrderBreakdown.module.css';

const readableLineItemCode = {
  deliveryPickedUp: 'Delivery & Picked Up to Job site',
  delivery: 'Delivery to Job site',
  pickedUp: 'Picked Up from Job site',
  selfArranged: 'Self Arranged Pick Up And Return',
  shipping: 'Shipping',
  collection: 'Collection',
};

const LineItemTransportationRefundMaybe = props => {
  const { transaction, lineItems, intl } = props;

  const transportationLineItem = lineItems.find(
    item => item.code === LINE_ITEM_TRANSPORTATION && item.reversal
  );

  const transportationVATLineItem = lineItems.find(
    item => item.code === LINE_ITEM_TRANSPORTATION_VAT && item.reversal
  );

  const { quote } = transaction?.attributes?.protectedData || {};

  const transportationLineItemFromQuote = quote?.lineItems?.find(
    item => item.code === LINE_ITEM_TRANSPORTATION && item.reversal
  );

  if (transportationLineItem && transportationLineItemFromQuote) {
    transportationLineItem.deliveryType = transportationLineItemFromQuote?.deliveryType;
    transportationLineItem.hideQuantity = transportationLineItemFromQuote?.hideQuantity;
  }

  const code = readableLineItemCode[(transportationLineItem?.deliveryType || transaction?.attributes?.protectedData?.deliveryMethod)];
  const isRFQ = transportationLineItem?.type === RFQ;
  const freeRange = transportationLineItem?.freeRange;

  const total = transportationVATLineItem
    ? new Money(
        transportationVATLineItem.lineTotal?.amount + transportationLineItem?.lineTotal?.amount,
        transportationLineItem?.lineTotal?.currency
      )
    : transportationLineItem?.lineTotal;

  return transportationLineItem ? (
    <div className={css.lineItem}>
      <span className={css.itemLabel}>
        <FormattedMessage
          id="OrderBreakdown.transportationRefundMaybe"
          values={{
            code,
            quantity: transportationLineItem?.hideQuantity
              ? 0
              : total?.amount > 0
              ? +transportationLineItem?.quantity + (transportationLineItem?.freeRange || 0)
              : transportationLineItem?.quantity,
            freeRange: freeRange ? <span>(free {freeRange} km)</span> : '',
            vat: transportationVATLineItem ? <span>+ VAT 23%</span> : '',
          }}
        />
      </span>
      <span className={css.itemValue}>{isRFQ ? RFQ : formatMoney(intl, total)}</span>
    </div>
  ) : null;
};

LineItemTransportationRefundMaybe.propTypes = {
  lineItems: propTypes.lineItems.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemTransportationRefundMaybe;
