import React from 'react';

export const IconCompass = ({ rootClassName }) => {
  return (
    <svg
      className={rootClassName}
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="16.9997"
        cy="17.0007"
        r="13.4583"
        stroke="#4871F7"
        stroke-width="1.41667"
        fill="#ffffff"
      />
      <path
        d="M26.208 17H27.6247"
        stroke="#4871F7"
        stroke-width="1.41667"
        stroke-linecap="round"
        fill="#ffffff"
      />
      <path
        d="M6.375 17H7.79167M17 6.375V7.79167M17 26.2083V27.625"
        stroke="#4871F7"
        stroke-width="1.41667"
        stroke-linecap="round"
        fill="#ffffff"
      />
      <path
        d="M9.98675 10.0788L13.2741 17.7493C13.8473 19.0867 14.913 20.1524 16.2504 20.7256L23.9209 24.0129C23.9797 24.0381 24.0391 23.9787 24.0139 23.9199L20.7265 16.2494C20.1534 14.912 19.0877 13.8463 17.7503 13.2731L10.0798 9.98577C10.021 9.96058 9.96156 10.02 9.98675 10.0788Z"
        stroke="#4871F7"
        stroke-width="1.41667"
        stroke-linecap="round"
        fill="#ffffff"
      />
      <circle
        cx="17"
        cy="17"
        r="3.54167"
        stroke="#4871F7"
        stroke-width="1.41667"
        stroke-linecap="round"
        fill="#ffffff"
      />
    </svg>
  );
};
