import React from 'react';

const DummyListingImageIcon = ({ width = 92, height = 92 }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 92 92" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill="none" d="M12.1315 42.0957C12.1315 27.8145 12.1315 20.674 16.5681 16.2374C21.0046 11.8008 28.1452 11.8008 42.4264 11.8008H50.0001C64.2813 11.8008 71.4218 11.8008 75.8584 16.2374C80.295 20.674 80.295 27.8145 80.295 42.0957V49.6694C80.295 63.9506 80.295 71.0912 75.8584 75.5277C71.4218 79.9643 64.2813 79.9643 50.0001 79.9643H42.4264C28.1452 79.9643 21.0046 79.9643 16.5681 75.5277C12.1315 71.0912 12.1315 63.9506 12.1315 49.6694V42.0957Z" stroke="#FCFCFC" strokeWidth="3.78686"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M72.7126 54.5565L71.4126 53.2565L71.3526 53.1965L71.3526 53.1965C69.8357 51.6796 68.6124 50.4562 67.5387 49.5514C66.4329 48.6194 65.356 47.91 64.0953 47.5297C62.312 46.9917 60.4096 46.9917 58.6263 47.5297C57.3657 47.91 56.2888 48.6194 55.1829 49.5514C54.1093 50.4562 52.886 51.6796 51.3692 53.1964L51.3691 53.1965L51.3091 53.2565C50.174 54.3916 49.4164 55.1464 48.8004 55.6558C48.1893 56.1611 47.925 56.2433 47.8181 56.2621C47.3604 56.3424 46.8893 56.2516 46.4942 56.0071C46.4019 55.95 46.187 55.7755 45.8073 55.0793C45.4246 54.3776 45.0015 53.3955 44.3691 51.92L44.1669 51.4481L44.1212 51.3416C42.7406 48.12 41.643 45.5588 40.5735 43.6584C39.4868 41.7273 38.2807 40.1965 36.5408 39.3242C34.9825 38.5431 33.2389 38.2073 31.502 38.3538C29.5625 38.5175 27.8742 39.4909 26.1481 40.8803C24.4493 42.2476 22.479 44.2179 20.0006 46.6964L19.9187 46.7783L19.7054 46.9915V49.6694C19.7054 50.6023 19.7057 51.4933 19.7071 52.3453L22.5964 49.456C25.1752 46.8772 27.0021 45.054 28.5225 43.8303C30.0342 42.6135 30.9998 42.1965 31.8204 42.1273C32.8626 42.0394 33.9087 42.2408 34.8437 42.7095C35.5799 43.0786 36.3215 43.8243 37.2733 45.5155C38.2304 47.2164 39.2495 49.5877 40.6862 52.9398L40.8884 53.4117L40.9247 53.4964L40.9248 53.4965C41.5112 54.8649 42.0038 56.0144 42.4828 56.8926C42.9747 57.7944 43.5768 58.6549 44.5012 59.2271C45.6863 59.9607 47.0998 60.2329 48.4727 59.9919C49.5434 59.804 50.4221 59.2287 51.2137 58.574C51.9846 57.9365 52.8689 57.0522 53.9216 55.9994L53.9868 55.9342C55.5777 54.3434 56.6878 53.2355 57.6233 52.4471C58.5407 51.6739 59.1587 51.3246 59.7201 51.1552C60.7901 50.8324 61.9315 50.8324 63.0015 51.1552C63.563 51.3246 64.181 51.6739 65.0983 52.4471C66.0339 53.2355 67.144 54.3434 68.7348 55.9342L72.6289 59.8283C72.6788 58.2741 72.7019 56.5315 72.7126 54.5565Z" fill="#FCFCFC"/>
      <circle cx="63.2542" cy="28.8414" r="5.6803" fill="#FCFCFC"/>
    </svg>
  )
}

export default DummyListingImageIcon;
