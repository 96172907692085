import React from 'react';
import { bool } from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { propTypes } from '../../util/types';
import { getProcess, resolveLatestProcessName } from '../../transactions/transaction';
import { types as sdkTypes } from '../../util/sdkLoader';
import { RFQ } from '../../constants';
import { transitions as transition } from '../../transactions/transactionProcessRFQBooking';

import css from './OrderBreakdown.module.css';

const { Money } = sdkTypes;

const LineItemTotalPrice = props => {
  const { transaction, isProvider, intl, lineItems } = props;
  const processName = resolveLatestProcessName(transaction?.attributes?.processName);
  if (!processName) {
    return null;
  }

  const hasRFQQuote = !!transaction?.attributes?.transitions?.find(
    t => t.transition === transition.INQUIRE_RFQ
  );
  const receivedQuote = !!transaction?.attributes?.transitions?.find(
    t => t.transition === transition.SEND_QUOTE_AFTER_INQUIRE
  );

  const process = getProcess(processName);
  const isCompleted = process.isCompleted(transaction?.attributes?.lastTransition);
  const isRefunded = process.isRefunded(transaction?.attributes?.lastTransition);

  let providerTotalMessageId = 'OrderBreakdown.providerTotalDefault';
  if (isCompleted) {
    providerTotalMessageId = 'OrderBreakdown.providerTotalReceived';
  } else if (isRefunded) {
    providerTotalMessageId = 'OrderBreakdown.providerTotalRefunded';
  }

  const totalLabel = isProvider ? (
    <FormattedMessage id={providerTotalMessageId} />
  ) : (
    <FormattedMessage id="OrderBreakdown.total" />
  );

  let currency = '';

  let hasRFQItems = lineItems.find(item => item.type === RFQ);

  const calculateTotalAmount = user =>
    lineItems.reduce((acc, curr) => {
      if (curr.includeFor.includes(user)) {
        acc += curr.lineTotal.amount;
        currency = curr.lineTotal.currency;
      }
      return acc;
    }, 0);

  const totalPrice =
    hasRFQQuote && isProvider
      ? new Money(calculateTotalAmount('provider'), currency)
      : hasRFQQuote
      ? new Money(calculateTotalAmount('customer'), currency)
      : isProvider
      ? transaction.attributes.payoutTotal
      : transaction.attributes.payinTotal;
  const formattedTotalPrice =
    hasRFQQuote && isProvider
      ? formatMoney(intl, totalPrice)
      : receivedQuote && !isProvider
      ? formatMoney(intl, totalPrice)
      : hasRFQQuote || (hasRFQItems && !isProvider)
      ? RFQ
      : formatMoney(intl, totalPrice);

  return (
    <>
      <hr className={css.totalDivider} />
      <div className={css.lineItemTotal}>
        <div className={css.totalLabel}>{totalLabel}</div>
        <div className={css.totalPrice}>{formattedTotalPrice}</div>
      </div>
    </>
  );
};

LineItemTotalPrice.propTypes = {
  transaction: propTypes.transaction.isRequired,
  isProvider: bool.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemTotalPrice;
