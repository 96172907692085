/**
 * This component will show the booking info and calculated total price.
 * I.e. dates and other details related to payment decision in receipt format.
 */
import React from 'react';
import { oneOf, string } from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import {
  propTypes,
  LISTING_UNIT_TYPES,
  LINE_ITEM_CUSTOMER_COMMISSION,
  LINE_ITEM_PROVIDER_COMMISSION,
} from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';

import { IconSpinner } from '../index';
import LineItemBookingPeriod from './LineItemBookingPeriod';
import LineItemBasePriceMaybe from './LineItemBasePriceMaybe';
import LineItemShippingFeeMaybe from './LineItemShippingFeeMaybe';
import LineItemPickupFeeMaybe from './LineItemPickupFeeMaybe';
import LineItemCustomerCommissionMaybe from './LineItemCustomerCommissionMaybe';
import LineItemCustomerCommissionRefundMaybe from './LineItemCustomerCommissionRefundMaybe';
import LineItemProviderCommissionMaybe from './LineItemProviderCommissionMaybe';
import LineItemProviderCommissionRefundMaybe from './LineItemProviderCommissionRefundMaybe';
import LineItemRefundMaybe from './LineItemRefundMaybe';
import LineItemTotalPrice from './LineItemTotalPrice';
import LineItemUnknownItemsMaybe from './LineItemUnknownItemsMaybe';
import LineItemTransportationMaybe from './LineItemTransportationMaybe';
import LineItemTotalVAT from './LineItemTotalVAT';
import LineItemSubtotal from './LineItemSubtotal';
import LineItemSubtotalRefundMaybe from './LineItemSubtotalRefundMaybe';
import LineItemTotalVATRefundMaybe from './LineItemTotalVATRefundMaybe';
import LineItemTransportationRefundMaybe from './LineItemTransportationRefundMaybe';
import LineItemBasePriceRefundMaybe from './LineItemBasePriceRefundMaybe';
import LineItemUnknownItemsRefundMaybe from './LineItemUnknownItemsRefundMaybe';

import css from './OrderBreakdown.module.css';

const { Money } = sdkTypes;

export const OrderBreakdownComponent = props => {
  const {
    rootClassName,
    className,
    userRole,
    transaction,
    booking,
    intl,
    dateType,
    timeZone,
    currency,
    marketplaceName,
    listingTitle,
    breakdownType,
  } = props;

  const isCustomer = userRole === 'customer';
  const isProvider = userRole === 'provider';

  const isHire = transaction?.listing?.attributes?.publicData?.unitType === 'day';

  const getlineItems = () => {
    switch (breakdownType) {
      case 'main': {
        return {
          lineItems: transaction?.attributes?.lineItems,
          bookingDates: {
            displayStart: transaction?.booking?.attributes?.displayStart,
            displayEnd: transaction?.booking?.attributes?.displayEnd,
          },
        };
      }
      case 'quote': {
        return {
          lineItems: transaction?.attributes?.protectedData?.quote?.lineItems?.map(item => {
            return {
              ...item,
              lineTotal: new Money(item.lineTotal.amount, item.lineTotal.currency),
              unitPrice: new Money(item.unitPrice.amount, item.unitPrice.currency),
            };
          }),
          bookingDates: {
            displayStart: transaction?.attributes?.protectedData?.quoteBookingStartDate,
            displayEnd: transaction?.attributes?.protectedData?.quoteBookingEndDate,
          },
        };
      }
      case 'rfq': {
        return {
          lineItems: transaction?.attributes?.protectedData?.bookingData?.lineItems?.map(item => {
            return {
              ...item,
              lineTotal: new Money(item.lineTotal.amount, item.lineTotal.currency),
              unitPrice: new Money(item.unitPrice.amount, item.unitPrice.currency),
            };
          }),
          bookingDates: {
            displayStart: transaction?.attributes?.protectedData?.bookingStart,
            displayEnd: transaction?.attributes?.protectedData?.bookingEnd,
          },
        };
      }
      default: {
        return {
          lineItems: transaction?.attributes?.lineItems,
          bookingDates: {
            displayStart: transaction?.booking?.attributes?.displayStart,
            displayEnd: transaction?.booking?.attributes?.displayEnd,
          },
        };
      }
    }
  };

  const { lineItems, bookingDates } = getlineItems();

  if (!lineItems || !lineItems.length) {
    return (
      <div className={css.spinner}>
        <IconSpinner />
      </div>
    );
  }

  const unitLineItem = lineItems?.find(
    item => LISTING_UNIT_TYPES.includes(item.code) && !item.reversal
  );
  // Line-item code that matches with base unit: day, night, hour, item
  const lineItemUnitType = unitLineItem?.code;

  const hasCommissionLineItem = lineItems.find(item => {
    const hasCustomerCommission = isCustomer && item.code === LINE_ITEM_CUSTOMER_COMMISSION;
    const hasProviderCommission = isProvider && item.code === LINE_ITEM_PROVIDER_COMMISSION;
    return (hasCustomerCommission || hasProviderCommission) && !item.reversal;
  });

  const classes = classNames(rootClassName || css.root, className);

  /**
   * OrderBreakdown contains different line items:
   *
   * LineItemBookingPeriod: prints booking start and booking end types. Prop dateType
   * determines if the date and time or only the date is shown
   *
   * LineItemShippingFeeMaybe: prints the shipping fee (combining additional fee of
   * additional items into it).
   *
   * LineItemShippingFeeRefundMaybe: prints the amount of refunded shipping fee
   *
   * LineItemBasePriceMaybe: prints the base price calculation for the listing, e.g.
   * "$150.00 * 2 nights $300"
   *
   * LineItemUnknownItemsMaybe: prints the line items that are unknown. In ideal case there
   * should not be unknown line items. If you are using custom pricing, you should create
   * new custom line items if you need them.
   *
   * LineItemSubTotalMaybe: prints subtotal of line items before possible
   * commission or refunds
   *
   * LineItemRefundMaybe: prints the amount of refund
   *
   * LineItemCustomerCommissionMaybe: prints the amount of customer commission
   * The default transaction process used by this template doesn't include the customer commission.
   *
   * LineItemCustomerCommissionRefundMaybe: prints the amount of refunded customer commission
   *
   * LineItemProviderCommissionMaybe: prints the amount of provider commission
   *
   * LineItemProviderCommissionRefundMaybe: prints the amount of refunded provider commission
   *
   * LineItemTotalPrice: prints total price of the transaction
   *
   */

  return (
    <div className={classes}>
      <LineItemBookingPeriod
        booking={booking}
        code={lineItemUnitType}
        dateType={dateType}
        timeZone={timeZone}
        bookingDates={bookingDates}
        isHire={isHire}
      />

      <LineItemBasePriceMaybe
        lineItems={lineItems}
        code={lineItemUnitType}
        intl={intl}
        listingTitle={listingTitle}
        transaction={transaction}
      />
      <LineItemBasePriceRefundMaybe
        lineItems={lineItems}
        code={lineItemUnitType}
        intl={intl}
        listingTitle={listingTitle}
        transaction={transaction}
      />
      <LineItemShippingFeeMaybe lineItems={lineItems} intl={intl} />
      <LineItemPickupFeeMaybe lineItems={lineItems} intl={intl} />
      <LineItemTransportationMaybe transaction={transaction} lineItems={lineItems} intl={intl} />
      <LineItemTransportationRefundMaybe
        transaction={transaction}
        lineItems={lineItems}
        intl={intl}
      />
      <LineItemUnknownItemsMaybe lineItems={lineItems} isProvider={isProvider} intl={intl} />
      <LineItemUnknownItemsRefundMaybe lineItems={lineItems} isProvider={isProvider} intl={intl} />
      <LineItemSubtotal lineItems={lineItems} intl={intl} />
      <LineItemSubtotalRefundMaybe lineItems={lineItems} intl={intl} />
      <LineItemTotalVAT lineItems={lineItems} intl={intl} />
      <LineItemTotalVATRefundMaybe lineItems={lineItems} intl={intl} />

      <LineItemCustomerCommissionMaybe
        lineItems={lineItems}
        isCustomer={isCustomer}
        marketplaceName={marketplaceName}
        intl={intl}
      />
      <LineItemCustomerCommissionRefundMaybe
        lineItems={lineItems}
        isCustomer={isCustomer}
        marketplaceName={marketplaceName}
        intl={intl}
      />

      <LineItemProviderCommissionMaybe
        lineItems={lineItems}
        isProvider={isProvider}
        marketplaceName={marketplaceName}
        intl={intl}
      />
      <LineItemProviderCommissionRefundMaybe
        lineItems={lineItems}
        isProvider={isProvider}
        marketplaceName={marketplaceName}
        intl={intl}
      />

      <LineItemRefundMaybe lineItems={lineItems} intl={intl} marketplaceCurrency={currency} />

      <LineItemTotalPrice
        transaction={transaction}
        isProvider={isProvider}
        intl={intl}
        lineItems={lineItems}
      />

      {/*{hasCommissionLineItem ? (*/}
      {/*  <span className={css.feeInfo}>*/}
      {/*    <span className={css.redStar}>*</span>*/}
      {/*    <FormattedMessage id="OrderBreakdown.commissionFeeNote" />*/}
      {/*  </span>*/}
      {/*) : null}*/}
    </div>
  );
};

OrderBreakdownComponent.defaultProps = {
  rootClassName: null,
  className: null,
  booking: null,
  dateType: null,
};

OrderBreakdownComponent.propTypes = {
  rootClassName: string,
  className: string,

  marketplaceName: string.isRequired,
  userRole: oneOf(['customer', 'provider']).isRequired,
  transaction: propTypes.transaction.isRequired,
  booking: propTypes.booking,
  dateType: propTypes.dateType,

  // from injectIntl
  intl: intlShape.isRequired,
};

const OrderBreakdown = injectIntl(OrderBreakdownComponent);

OrderBreakdown.displayName = 'OrderBreakdown';

export default OrderBreakdown;
