import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { IconEmailAttention, NamedLink, SecondaryButton } from '../../components';

import css from './ModalMissingInformation.module.css';

const PhoneReminder = props => {
  const { className, user } = props;

  const phone = user.id ? (
    <span className={css.email}>
      {user.attributes.profile.publicData.phoneNumber}
    </span>
  ) : '';

  return (
    <div className={className}>
      <IconEmailAttention className={css.modalIcon} />
      <p className={css.modalTitle}>
        <FormattedMessage id="ModalMissingInformation.verifyPhoneTitle" />
      </p>
      <p className={css.modalMessage}>
        <FormattedMessage id="ModalMissingInformation.verifyPhoneText" />
      </p>
      <p className={css.modalMessage}>
        <FormattedMessage id="ModalMissingInformation.checkPhoneInbox" values={{ phone }} />
      </p>

      <div className={css.bottomWrapper}>
        <NamedLink className={css.button} name="ContactDetailsPage">
          <SecondaryButton>
            <FormattedMessage id="ModalMissingInformation.verifyButtonText" />
          </SecondaryButton>
        </NamedLink>
      </div>
    </div>
  );
};

export default PhoneReminder;
