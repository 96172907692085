/**
 * Export loadData calls from ducks modules of different containers
 */
import { loadData as AuthenticationPageLoader } from './AuthenticationPage/AuthenticationPage.duck';
import { loadData as LandingPageLoader } from './LandingPage/LandingPage.duck';
import { setInitialValues as CheckoutPageInitialValues } from './CheckoutPage/CheckoutPage.duck';
import { loadData as CMSPageLoader } from './CMSPage/CMSPage.duck';
import { loadData as ContactDetailsPageLoader } from './ContactDetailsPage/ContactDetailsPage.duck';
import { loadData as EditListingPageLoader } from './EditListingPage/EditListingPage.duck';
import { loadData as EmailVerificationPageLoader } from './EmailVerificationPage/EmailVerificationPage.duck';
import { loadData as InboxPageLoader, loadDataTenders as InboxTenderPageLoader } from './InboxPage/InboxPage.duck';
import { loadData as ListingPageLoader } from './ListingPage/ListingPage.duck';
import { loadData as ManageListingsPageLoader } from './ManageListingsPage/ManageListingsPage.duck';
import { loadData as PaymentMethodsPageLoader } from './PaymentMethodsPage/PaymentMethodsPage.duck';
import { loadData as PrivacyPolicyPageLoader } from './PrivacyPolicyPage/PrivacyPolicyPage.duck';
import { loadData as ProfilePageLoader } from './ProfilePage/ProfilePage.duck';
import { loadData as SearchPageLoader } from './SearchPage/SearchPage.duck';
import { loadData as StripePayoutPageLoader } from './StripePayoutPage/StripePayoutPage.duck';
import { loadData as TermsOfServicePageLoader } from './TermsOfServicePage/TermsOfServicePage.duck';
import { loadData as TendersPageLoader } from './TendersPage/TendersPage.duck';
import { loadData as PartnerProfileAboutUsPageLoader } from './PartnerProfileAboutUsPage/PartnerProfileAboutUsPage.duck';
import { loadData as PartnerProfileContactUsPageLoader } from './PartnerProfileContactUsPage/PartnerProfileContactUsPage.duck';
import { loadData as TenderPageLoader } from './TenderPage/TenderPage.duck';
import { loadData as TenderDetailsPageLoader } from './TenderDetailsPage/TenderDetailsPage.duck';
import {
  loadData as TransactionPageLoader,
  setInitialValues as TransactionPageInitialValues,
} from './TransactionPage/TransactionPage.duck';
import { loadData as PartnerPortalLoader } from './PartnerPortal/PartnerPortal.duck';
import { loadData as ListingsEditPageLoader } from './ListingsEditPage/ListingsEditPage.duck';


const getPageDataLoadingAPI = () => {
  return {
    AuthenticationPage: {
      loadData: AuthenticationPageLoader,
    },
    LandingPage: {
      loadData: LandingPageLoader,
    },
    CheckoutPage: {
      setInitialValues: CheckoutPageInitialValues,
    },
    CMSPage: {
      loadData: CMSPageLoader,
    },
    ContactDetailsPage: {
      loadData: ContactDetailsPageLoader,
    },
    EditListingPage: {
      loadData: EditListingPageLoader,
    },
    EmailVerificationPage: {
      loadData: EmailVerificationPageLoader,
    },
    InboxPage: {
      loadData: InboxPageLoader,
    },
    InboxTenderPage: {
      loadData: InboxTenderPageLoader,
    },
    ListingPage: {
      loadData: ListingPageLoader,
    },
    ManageListingsPage: {
      loadData: ManageListingsPageLoader,
    },
    PaymentMethodsPage: {
      loadData: PaymentMethodsPageLoader,
    },
    PrivacyPolicyPage: {
      loadData: PrivacyPolicyPageLoader,
    },
    ProfilePage: {
      loadData: ProfilePageLoader,
    },
    SearchPage: {
      loadData: SearchPageLoader,
    },
    StripePayoutPage: {
      loadData: StripePayoutPageLoader,
    },
    TermsOfServicePage: {
      loadData: TermsOfServicePageLoader,
    },
    TransactionPage: {
      loadData: TransactionPageLoader,
      setInitialValues: TransactionPageInitialValues,
    },
    TendersPage: {
      loadData: TendersPageLoader
    },
    PartnerProfileAboutUsPage: {
      loadData: PartnerProfileAboutUsPageLoader,
    },
    PartnerProfileContactUsPage: {
      loadData: PartnerProfileContactUsPageLoader,
    },
    TenderPage: {
      loadData: TenderPageLoader
    },
    TenderDetailsPage: {
      loadData: TenderDetailsPageLoader
    },
    PartnerPortal: {
      loadData: PartnerPortalLoader,
    },
    ListingsEditPage: {
      loadData: ListingsEditPageLoader
    }
  };
};

export default getPageDataLoadingAPI;
