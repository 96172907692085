import React from 'react';

const IconSameDayBookCollect = ({width, height}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 43 47" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill='none' d="M41 19.2H2M30.1667 2V10.6M12.8333 2V10.6M15 32.1L19.3333 36.4L29.0833 26.725M12.4 45H30.6C34.2403 45 36.0605 45 37.4509 44.297C38.674 43.6786 39.6684 42.6919 40.2915 41.4782C41 40.0985 41 38.2923 41 34.68V16.62C41 13.0077 41 11.2015 40.2915 9.82176C39.6684 8.60812 38.674 7.62139 37.4509 7.00301C36.0605 6.3 34.2403 6.3 30.6 6.3H12.4C8.75966 6.3 6.93949 6.3 5.54906 7.00301C4.32601 7.62139 3.33163 8.60812 2.70846 9.82176C2 11.2015 2 13.0077 2 16.62V34.68C2 38.2923 2 40.0985 2.70846 41.4782C3.33163 42.6919 4.32601 43.6786 5.54906 44.297C6.93949 45 8.75966 45 12.4 45Z" stroke="#4871F7" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default IconSameDayBookCollect;
