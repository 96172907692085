import React from 'react';
import classNames from 'classnames';
import { FieldArray } from 'react-final-form-arrays';
import FileIcon from '../../containers/DocumentsPage/components/AttachFileIcon/FileIcon';

import css from './ImageUpload.module.css';

const MAIN_BUCKET_NAME = process.env.REACT_APP_GCS_MAIN_BUCKET_NAME;
const GCS_URL = process.env.REACT_APP_GCS_URL;

const PDF = 'pdf';
const TYPE_PDF = 'application/pdf';

const RemoveImageButton = props => {
  const { className, rootClassName, onClick } = props;
  const classes = classNames(rootClassName || css.removeImage, className);
  return (
    <span className={classes} onClick={onClick}>
      <svg
        width="10px"
        height="10px"
        viewBox="0 0 10 10"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g strokeWidth="1" fillRule="evenodd">
          <g transform="translate(-821.000000, -311.000000)">
            <g transform="translate(809.000000, 299.000000)">
              <path
                d="M21.5833333,16.5833333 L17.4166667,16.5833333 L17.4166667,12.4170833 C17.4166667,12.1866667 17.2391667,12 17.00875,12 C16.77875,12 16.5920833,12.18625 16.5920833,12.41625 L16.5883333,16.5833333 L12.4166667,16.5833333 C12.18625,16.5833333 12,16.7695833 12,17 C12,17.23 12.18625,17.4166667 12.4166667,17.4166667 L16.5875,17.4166667 L16.5833333,21.5829167 C16.5829167,21.8129167 16.7691667,21.9995833 16.9991667,22 L16.9995833,22 C17.2295833,22 17.41625,21.81375 17.4166667,21.58375 L17.4166667,17.4166667 L21.5833333,17.4166667 C21.8133333,17.4166667 22,17.23 22,17 C22,16.7695833 21.8133333,16.5833333 21.5833333,16.5833333"
                transform="translate(17.000000, 17.000000) rotate(-45.000000) translate(-17.000000, -17.000000) "
              />
            </g>
          </g>
        </g>
      </svg>
    </span>
  );
};

const ImageUpload = ({
  label,
  fileTip,
  name,
  id,
  disabled,
  onChange,
  error,
  bucketName,
  hideUpload,
}) => {
  return (
    <>
      {!hideUpload && (
        <label htmlFor={id}>
          <div className={css.fileSection}>
            <div className={css.fileIconWrapper}>
              <FileIcon color="#4871F7" />
              <span className={css.btnLabel}>{label}</span>
              <span className={css.fileTip}>{fileTip}</span>
            </div>
          </div>
        </label>
      )}
      <input
        id={id}
        name={name}
        className={css.uploadInput}
        disabled={disabled}
        onChange={onChange}
        type="file"
      />
      <FieldArray name={name}>
        {({ fields }) => (
          <div className={classNames(css.filesContainer, { [css.largePreview]: hideUpload })}>
            {fields.map((name, index) => {
              const file = fields.value[index];

              if (typeof file === 'string') {
                const isPDF = file.split('.').at(-1) === PDF;

                return isPDF ? (
                  <div
                    className={classNames(css.imageContainer, { [css.largePreview]: hideUpload })}
                    key={String(index)}
                  >
                    <embed
                      src={`${GCS_URL}/${bucketName || MAIN_BUCKET_NAME}/${file}`}
                      width="100%"
                      type={TYPE_PDF}
                    />
                    <RemoveImageButton onClick={() => fields.remove(index)} />
                  </div>
                ) : (
                  <div
                    className={classNames(css.imageContainer, { [css.largePreview]: hideUpload })}
                    key={String(index)}
                  >
                    <img
                      src={`${GCS_URL}/${bucketName || MAIN_BUCKET_NAME}/${file}`}
                      alt="No image"
                      className={css.image}
                    />
                    <RemoveImageButton onClick={() => fields.remove(index)} />
                  </div>
                );
              } else {
                const isPDF = file.type === TYPE_PDF;

                return isPDF ? (
                  <div
                    className={classNames(css.imageContainer, { [css.largePreview]: hideUpload })}
                    key={String(index)}
                  >
                    <embed src={URL.createObjectURL(file)} width="100%" type={TYPE_PDF} />
                    <RemoveImageButton onClick={() => fields.remove(index)} />
                  </div>
                ) : (
                  <div
                    className={classNames(css.imageContainer, { [css.largePreview]: hideUpload })}
                    key={String(index)}
                  >
                    <img src={URL.createObjectURL(file)} alt="No image" className={css.image} />
                    <RemoveImageButton onClick={() => fields.remove(index)} />
                  </div>
                );
              }
            })}
          </div>
        )}
      </FieldArray>
      {!!error && <div className={css.error}>{error}</div>}
    </>
  );
};

export default ImageUpload;
