import React from 'react';

const IconCreditTermsAvailable = ({width, height}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 48 37" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill='none' d="M32.8 30.6L37.2 35L46 26.2M46 13H2M46 17.4V9.04C46 6.57577 46 5.34366 45.5204 4.40245C45.0986 3.57453 44.4255 2.90142 43.5976 2.47958C42.6563 2 41.4242 2 38.96 2H9.04C6.57577 2 5.34365 2 4.40244 2.47957C3.57453 2.90141 2.90141 3.57453 2.47957 4.40244C2 5.34365 2 6.57577 2 9.04V25.76C2 28.2242 2 29.4563 2.47957 30.3976C2.90141 31.2255 3.57453 31.8986 4.40244 32.3204C5.34365 32.8 6.57577 32.8 9.04 32.8H24" stroke="#4871F7" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default IconCreditTermsAvailable;
