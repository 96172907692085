import {
  updatedEntities,
  denormalisedEntities,
  denormalisedResponseEntities,
} from '../../util/data';
import { storableError } from '../../util/errors';
import { createImageVariantConfig } from '../../util/sdkLoader';
import { parse } from '../../util/urlHelpers';
import { getSubscriptionPlan } from '../../util/api';
import * as geocoderMapbox from '../../components/LocationAutocompleteInput/GeocoderMapbox';
import * as geocoderGoogleMaps from '../../components/LocationAutocompleteInput/GeocoderGoogleMaps';

const getGeocoderVariant = mapProvider => {
  const isGoogleMapsInUse = mapProvider === 'googleMaps';
  return isGoogleMapsInUse ? geocoderGoogleMaps : geocoderMapbox;
};

const getGeocoder = (mapProvider) => {
  const geocoderVariant = getGeocoderVariant(mapProvider);
  const Geocoder = geocoderVariant.default;

  return new Geocoder();
}

// Pagination page size might need to be dynamic on responsive page layouts
// Current design has max 3 columns 42 is divisible by 2 and 3
// So, there's enough cards to fill all columns on full pagination pages
const RESULT_PAGE_SIZE = 42;

// ================ Action types ================ //

export const FETCH_LISTINGS_REQUEST = 'app/ManageListingsPage/FETCH_LISTINGS_REQUEST';
export const FETCH_LISTINGS_SUCCESS = 'app/ManageListingsPage/FETCH_LISTINGS_SUCCESS';
export const FETCH_LISTINGS_ERROR = 'app/ManageListingsPage/FETCH_LISTINGS_ERROR';

export const OPEN_LISTING_REQUEST = 'app/ManageListingsPage/OPEN_LISTING_REQUEST';
export const OPEN_LISTING_SUCCESS = 'app/ManageListingsPage/OPEN_LISTING_SUCCESS';
export const OPEN_LISTING_ERROR = 'app/ManageListingsPage/OPEN_LISTING_ERROR';

export const CLOSE_LISTING_REQUEST = 'app/ManageListingsPage/CLOSE_LISTING_REQUEST';
export const CLOSE_LISTING_SUCCESS = 'app/ManageListingsPage/CLOSE_LISTING_SUCCESS';
export const CLOSE_LISTING_ERROR = 'app/ManageListingsPage/CLOSE_LISTING_ERROR';

export const ADD_OWN_ENTITIES = 'app/ManageListingsPage/ADD_OWN_ENTITIES';

export const QUERY_SUBSCRIPTION_PLAN_REQUEST =
  'app/ManageListingsPage/QUERY_SUBSCRIPTION_PLAN_REQUEST';
export const QUERY_SUBSCRIPTION_PLAN_SUCCESS =
  'app/ManageListingsPage/QUERY_SUBSCRIPTION_PLAN_SUCCESS';
export const QUERY_SUBSCRIPTION_PLAN_ERROR = 'app/ManageListingsPage/QUERY_SUBSCRIPTION_PLAN_ERROR';

export const FETCH_OPEN_LISTINGS_REQUEST = 'app/ManageListingsPage/FETCH_OPEN_LISTINGS_REQUEST';
export const FETCH_OPEN_LISTINGS_SUCCESS = 'app/ManageListingsPage/FETCH_OPEN_LISTINGS_SUCCESS';
export const FETCH_OPEN_LISTINGS_ERROR = 'app/ManageListingsPage/FETCH_OPEN_LISTINGS_ERROR';

export const UPLOAD_LISTINGS_REQUEST = 'app/ManageListingsPage/UPLOAD_LISTINGS_REQUEST';
export const UPLOAD_LISTINGS_SUCCESS = 'app/ManageListingsPage/UPLOAD_LISTINGS_SUCCESS';
export const UPLOAD_LISTINGS_ERROR = 'app/ManageListingsPage/UPLOAD_LISTINGS_ERROR';

// ================ Reducer ================ //

const initialState = {
  pagination: null,
  queryParams: null,
  queryInProgress: false,
  queryListingsError: null,
  currentPageResultIds: [],
  ownEntities: {},
  openingListing: null,
  openingListingError: null,
  closingListing: null,
  closingListingError: null,
  subscriptionPlan: null,
  subscriptionPlanError: null,
  openListings: [],
  queryOpenListingsInProgress: false,
  queryOpenListingsError: null,
  uploadListingInProgress: false,
  uploadListingError: null,
};

const resultIds = data => data.data.map(l => l.id);

const merge = (state, sdkResponse) => {
  const apiResponse = sdkResponse.data;
  return {
    ...state,
    ownEntities: updatedEntities({ ...state.ownEntities }, apiResponse),
  };
};

const updateListingAttributes = (state, listingEntity) => {
  const oldListing = state.ownEntities.ownListing[listingEntity.id.uuid];
  const updatedListing = { ...oldListing, attributes: listingEntity.attributes };
  const ownListingEntities = {
    ...state.ownEntities.ownListing,
    [listingEntity.id.uuid]: updatedListing,
  };
  return {
    ...state,
    ownEntities: { ...state.ownEntities, ownListing: ownListingEntities },
  };
};

const manageListingsPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_LISTINGS_REQUEST:
      return {
        ...state,
        queryParams: payload.queryParams,
        queryInProgress: true,
        queryListingsError: null,
        currentPageResultIds: [],
      };
    case FETCH_LISTINGS_SUCCESS:
      return {
        ...state,
        currentPageResultIds: resultIds(payload.data),
        pagination: payload.data.meta,
        queryInProgress: false,
      };
    case FETCH_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, queryInProgress: false, queryListingsError: payload };

    case OPEN_LISTING_REQUEST:
      return {
        ...state,
        openingListing: payload.listingId,
        openingListingError: null,
      };
    case OPEN_LISTING_SUCCESS:
      return {
        ...updateListingAttributes(state, payload.data),
        openingListing: null,
      };
    case OPEN_LISTING_ERROR: {
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        openingListing: null,
        openingListingError: {
          listingId: state.openingListing,
          error: payload,
        },
      };
    }

    case CLOSE_LISTING_REQUEST:
      return {
        ...state,
        closingListing: payload.listingId,
        closingListingError: null,
      };
    case CLOSE_LISTING_SUCCESS:
      return {
        ...updateListingAttributes(state, payload.data),
        closingListing: null,
      };
    case CLOSE_LISTING_ERROR: {
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        closingListing: null,
        closingListingError: {
          listingId: state.closingListing,
          error: payload,
        },
      };
    }

    case ADD_OWN_ENTITIES:
      return merge(state, payload);

    case QUERY_SUBSCRIPTION_PLAN_REQUEST:
      return { ...state, subscriptionPlanError: null };
    case QUERY_SUBSCRIPTION_PLAN_SUCCESS:
      return { ...state, subscriptionPlan: payload };
    case QUERY_SUBSCRIPTION_PLAN_ERROR:
      return { ...state, subscriptionPlanError: payload };

    case FETCH_OPEN_LISTINGS_REQUEST:
      return { ...state, queryOpenListingsInProgress: true, queryOpenListingsError: null };
    case FETCH_OPEN_LISTINGS_SUCCESS:
      return {
        ...state,
        queryOpenListingsInProgress: false,
        queryOpenListingsError: null,
        openListings: payload,
      };
    case FETCH_OPEN_LISTINGS_ERROR:
      return { ...state, queryOpenListingsInProgress: false, queryOpenListingsError: payload };

    case UPLOAD_LISTINGS_REQUEST:
      return { ...state, uploadListingInProgress: true };
    case UPLOAD_LISTINGS_SUCCESS:
      return { ...state, uploadListingInProgress: false, uploadListingError: null };
    case UPLOAD_LISTINGS_ERROR:
      return { ...state, uploadListingInProgress: false, uploadListingError: payload };

    default:
      return state;
  }
};

export default manageListingsPageReducer;

// ================ Selectors ================ //

/**
 * Get the denormalised own listing entities with the given IDs
 *
 * @param {Object} state the full Redux store
 * @param {Array<UUID>} listingIds listing IDs to select from the store
 */
export const getOwnListingsById = (state, listingIds) => {
  const { ownEntities } = state.ManageListingsPage;
  const resources = listingIds.map(id => ({
    id,
    type: 'ownListing',
  }));
  const throwIfNotFound = false;
  return denormalisedEntities(ownEntities, resources, throwIfNotFound);
};

// ================ Action creators ================ //

// This works the same way as addMarketplaceEntities,
// but we don't want to mix own listings with searched listings
// (own listings data contains different info - e.g. exact location etc.)
export const addOwnEntities = sdkResponse => ({
  type: ADD_OWN_ENTITIES,
  payload: sdkResponse,
});

export const openListingRequest = listingId => ({
  type: OPEN_LISTING_REQUEST,
  payload: { listingId },
});

export const openListingSuccess = response => ({
  type: OPEN_LISTING_SUCCESS,
  payload: response.data,
});

export const openListingError = e => ({
  type: OPEN_LISTING_ERROR,
  error: true,
  payload: e,
});

export const closeListingRequest = listingId => ({
  type: CLOSE_LISTING_REQUEST,
  payload: { listingId },
});

export const closeListingSuccess = response => ({
  type: CLOSE_LISTING_SUCCESS,
  payload: response.data,
});

export const closeListingError = e => ({
  type: CLOSE_LISTING_ERROR,
  error: true,
  payload: e,
});

export const queryListingsRequest = queryParams => ({
  type: FETCH_LISTINGS_REQUEST,
  payload: { queryParams },
});

export const queryListingsSuccess = response => ({
  type: FETCH_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const queryListingsError = e => ({
  type: FETCH_LISTINGS_ERROR,
  error: true,
  payload: e,
});

const querySubscriptionPlanRequest = () => ({
  type: QUERY_SUBSCRIPTION_PLAN_REQUEST,
});
const querySubscriptionPlanSuccess = payload => ({
  type: QUERY_SUBSCRIPTION_PLAN_SUCCESS,
  payload,
});
const querySubscriptionPlanError = e => ({
  type: QUERY_SUBSCRIPTION_PLAN_ERROR,
  error: true,
  payload: e,
});

export const queryOpenListingsRequest = () => ({
  type: FETCH_OPEN_LISTINGS_REQUEST,
});
export const queryOpenListingsSuccess = payload => ({
  type: FETCH_OPEN_LISTINGS_SUCCESS,
  payload,
});
export const queryOpenListingsError = payload => ({
  type: FETCH_OPEN_LISTINGS_ERROR,
  payload,
});

export const uploadListingsRequest = () => ({
  type: UPLOAD_LISTINGS_REQUEST,
});
export const uploadListingsSuccess = payload => ({
  type: UPLOAD_LISTINGS_SUCCESS,
  payload,
});
export const uploadListingsError = payload => ({
  type: UPLOAD_LISTINGS_ERROR,
  payload,
});

export const queryOwnListings = (queryParams) => (dispatch, getState, sdk) => {
  dispatch(queryListingsRequest(queryParams));
  // const queryParams = parse(search);
  // const page = queryParams.page || 1;

  // const {
  //   aspectWidth = 1,
  //   aspectHeight = 1,
  //   variantPrefix = 'listing-card',
  // } = config.layout.listingImage;
  // const aspectRatio = aspectHeight / aspectWidth;

  // const querySearchParams = {
  //   ...queryParams,
  //   page,
  //   perPage: RESULT_PAGE_SIZE,
  //   include: ['images', 'currentStock'],
  //   'fields.image': [`variants.${variantPrefix}`, `variants.${variantPrefix}-2x`],
  //   ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
  //   ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
  //   'limit.images': 1,
  // };

  // dispatch(queryListingsRequest(querySearchParams));

  // const currentUser = getState().user?.currentUser;

  // const { perPage, ...rest } = querySearchParams;
  // const params = { ...rest, perPage };
  // params.author_id = currentUser?.id.uuid;

  // if (!params.author_id) {
  //   return;
  // }

  const { perPage, ...rest } = queryParams;
  const params = { ...rest, perPage };

  return sdk.ownListings
    .query(params)
    .then(response => {
      // const listingRefs = response.data.data.map(({ id, type }) => ({ id, type }));
      // dispatch(addMarketplaceEntities(response));
      dispatch(addOwnEntities(response));
      dispatch(queryListingsSuccess(response));
      // dispatch(queryListingsSuccess(listingRefs, response));

      dispatch(getUserSubscriptionPlan());

      return response;
    })
    .catch(e => {
      dispatch(queryListingsError(storableError(e)));
      throw e;
    });
};

export const closeListing = listingId => (dispatch, getState, sdk) => {
  dispatch(closeListingRequest(listingId));

  return sdk.ownListings
    .close({ id: listingId }, { expand: true })
    .then(response => {
      dispatch(closeListingSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(closeListingError(storableError(e)));
    });
};

export const openListing = listingId => (dispatch, getState, sdk) => {
  dispatch(openListingRequest(listingId));

  return sdk.ownListings
    .open({ id: listingId }, { expand: true })
    .then(response => {
      dispatch(openListingSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(openListingError(storableError(e)));
    });
};

export const getUserSubscriptionPlan = () => async (dispatch, getState) => {
  dispatch(querySubscriptionPlanRequest());

  const currentUserId = getState()?.user?.currentUser?.id;

  try {
    const response = await getSubscriptionPlan({ userId: currentUserId.uuid });

    const subscriptionPlan =
      response?.data?.data?.attributes?.profile?.privateData?.Subscription_plan ?? "None";

    dispatch(querySubscriptionPlanSuccess(subscriptionPlan));
  } catch( e) {
    dispatch(querySubscriptionPlanError(storableError(e)))
  }
};

export const queryOpenListings = (unitType) => (dispatch, getState, sdk) => {
  const aspectWidth = 1;
  const aspectHeight = 1;
  const variantPrefix = 'listing-card';
  const aspectRatio = aspectHeight / aspectWidth;

  const querySearchParams = {
    pub_listingType: unitType === 'item' ? 'product-selling' : 'daily-booking',
    include: ['images', 'currentStock'],
    'fields.image': [`variants.${variantPrefix}`, `variants.${variantPrefix}-2x`],
    ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
    'limit.images': 1,
  };

  dispatch(queryOpenListingsRequest());

  const currentUser = getState().user?.currentUser;

  if (!currentUser) {
    return;
  }

  querySearchParams.authorId = currentUser?.id?.uuid;

  return sdk.listings
    .query(querySearchParams)
    .then(response => {
      const entities = denormalisedResponseEntities(response);
      dispatch(queryOpenListingsSuccess(entities));
      return response;
    })
    .catch(e => {
      dispatch(queryOpenListingsError(storableError(e)));
      throw e;
    });
};

const queryLoadData = (dispatch) => {
  const queryParams = parse(window?.location?.search ?? "");
  const page = queryParams.page || 1;

  const aspectWidth = 1;
  const aspectHeight = 1;
  const variantPrefix = 'listing-card';
  const aspectRatio = aspectHeight / aspectWidth;

  dispatch(queryOwnListings({
    ...queryParams,
    page,
    perPage: RESULT_PAGE_SIZE,
    include: ['images', 'currentStock'],
    'fields.image': [`variants.${variantPrefix}`, `variants.${variantPrefix}-2x`],
    ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
    'limit.images': 1,
  }))
}

const formatLocation = (location) => {
  if (!location) {
    return location;
  }

  const {
    terms,
    bounds,
    secondaryText,
    address,
    origin
  } = location;

  return {
    predictions: [],
    search: address,
    selectedPlace: {
      address: address,
      origin: origin,
      bounds: bounds,
      secondaryText: secondaryText,
      terms: terms,
    }
  }
}

const getLocationPlace = async (location, config) => {
  if (!location) {
    return null;
  }

  const currentLocationBoundsDistance = config.maps?.search?.currentLocationBoundsDistance;
  const predictions = await getGeocoder(config.maps?.mapProvider)
    .getPlacePredictions(location, config.maps.search.countryLimit, config.localization.locale);
  const prediction = predictions?.predictions?.reduce((result, current) => {

    return current.matched_substrings.length > result.matchedSubstrings
      ? {
        matchedSubstrings: current.matched_substrings.length,
        prediction: current
      } : result;

  }, { matchedSubstrings: 0, prediction: null })

  if (!prediction.prediction || !predictions) {
    throw new Error("We can't determine the location! Fix location filed or leave blank field. The location field can be filled in later on the ad edit page.");
  }
  const place = await getGeocoder(config.maps?.mapProvider)
    .getPlaceDetails(prediction.prediction, currentLocationBoundsDistance);

  if (!place) {
    throw new Error("We can't determine the location! Fix location filed or leave blank field. The location field can be filled in later on the ad edit page.");
  }

  return place;
}

const getImagesIds = async (listing, sdk) => {
  const images = listing.images;
  const imagesIds = [];

  try {
    for (let j = 0; j < images.length; j++) {
      const response = await fetch(images[j], { method: 'GET' });
      const blob = await response.blob();
      const filename = images[j].substring(images[j].lastIndexOf('/') + 1);
      const file = new File([blob], filename, { type: blob.type });

      const imageResponse = await sdk.images.upload({
        image: file,
      });

      const id = imageResponse.data.data.id;
      imagesIds.push(id);
    }

    return imagesIds;
  } catch(e) {
    console.log(e);
    return [];
  }
}

export const uploadListings = (listings, newListings, config) => async (dispatch, getState, sdk) => {
  dispatch(uploadListingsRequest());

  try {
    for (let i = 0; i < newListings.length; i++) {
      const listing = newListings[i];

      const imagesIds = await getImagesIds(listing, sdk);
      listing.images = imagesIds;

      const locationPlace = await getLocationPlace(listing.publicData.location, config);
      const locationCollectionPlace = await getLocationPlace(listing.publicData.collectionLocation, config);

      const geolocation = locationPlace
        ? locationPlace.origin
        : locationCollectionPlace
        ? locationCollectionPlace.origin
        : undefined;

      const formattedLocation = formatLocation(locationPlace);
      const formattedCollectionLocation = formatLocation(locationCollectionPlace);

      if (geolocation) {
        listing.geolocation = geolocation
      }
      if (formattedLocation) {
        listing.publicData.location = formattedLocation;
      }
      if (formattedCollectionLocation) {
        listing.publicData.collectionLocation = formattedCollectionLocation;
      }


      const listingExist = listings.find(({attributes}) => attributes.title === listing.title);

      if (listingExist) {
        await sdk.ownListings.update({
          id: listingExist.id,
          ...listing,
        })
      } else {
        await sdk.ownListings.createDraft(listing);
      }
    }

    dispatch(uploadListingsSuccess());
    queryLoadData(dispatch);
  } catch(e) {
    dispatch(uploadListingsError(storableError(e)));
    throw e;
  }
}

export const loadData = (params, search, config) => {
  const queryParams = parse(search);
  const page = queryParams.page || 1;

  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const aspectRatio = aspectHeight / aspectWidth;

  // const querySearchParams = {
  //   ...queryParams,
  //   page,
  //   perPage: RESULT_PAGE_SIZE,
  //   include: ['images', 'currentStock'],
  //   'fields.image': [`variants.${variantPrefix}`, `variants.${variantPrefix}-2x`],
  //   ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
  //   ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
  //   'limit.images': 1,
  // };

  return queryOwnListings({
    ...queryParams,
    page,
    perPage: RESULT_PAGE_SIZE,
    include: ['images', 'currentStock'],
    'fields.image': [`variants.${variantPrefix}`, `variants.${variantPrefix}-2x`],
    ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
  });
};
