import React from 'react';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { LINE_ITEM_NIGHT, LINE_ITEM_DAY, propTypes, LINE_ITEM_HOUR } from '../../util/types';
import { RFQ } from '../../constants';
const { types } = require('sharetribe-flex-sdk');
const { Money } = types;

import css from './OrderBreakdown.module.css';

const LineItemBasePriceRefundMaybe = props => {
  const { lineItems, code, intl, listingTitle, transaction } = props;
  const isNightly = code === LINE_ITEM_NIGHT;
  const isDaily = code === LINE_ITEM_DAY;
  const isHourly = code === LINE_ITEM_HOUR;
  const translationKey = isNightly
    ? 'OrderBreakdown.baseUnitNightRefund'
    : isDaily
    ? 'OrderBreakdown.baseUnitDayRefund'
    : isHourly
    ? 'OrderBreakdown.baseUnitHourRefund'
    : 'OrderBreakdown.baseUnitQuantityRefund';

  // Find correct line-item for given code prop.
  // It should be one of the following: 'line-item/night, 'line-item/day', 'line-item/hour', or 'line-item/item'
  // These are defined in '../../util/types';
  const unitsPurchase = lineItems.filter(
    item =>
      (item.code.includes('day') || item.code.includes('sale')) &&
      !item.code.includes('vat') &&
      item.reversal
  );

  const { quote } = transaction?.attributes?.protectedData || {};

  const unitsPurchaseFromQuote = quote?.lineItems?.filter(
    item =>
      (item.code.includes('day') || item.code.includes('sale')) &&
      !item.code.includes('vat') &&
      item.reversal
  );

  if (unitsPurchase && unitsPurchaseFromQuote) {
    for (let i = 0; i < unitsPurchaseFromQuote.length; i++) {
      unitsPurchase[i].title = unitsPurchaseFromQuote[i].title;
    }
  }

  return unitsPurchase.length
    ? unitsPurchase.map(item => {
        const quantity = item.quantity.toString();
        const unitPrice = formatMoney(intl, item.unitPrice);

        const VATItem = lineItems.find(el => el.code === `${item.code}-vat` && el.reversal);

        const isRFQ = item?.type === RFQ;

        const VATRate = VATItem ? (
          <span>
            + VAT{' '}
            {Number(VATItem?.percentage?.d.join('.')).toFixed(
              VATItem.percentage.d.length > 1 ? 1 : 0
            )}
            %
          </span>
        ) : (
          ''
        );

        const total = VATItem
          ? new Money(
              VATItem.lineTotal?.amount + item?.lineTotal?.amount,
              item?.lineTotal?.currency
            )
          : item?.lineTotal;

        return (
          <div className={css.lineItem}>
            <span className={css.itemLabel}>
              <FormattedMessage
                id={translationKey}
                values={{ unitPrice, quantity, title: item?.title || listingTitle, vat: VATRate }}
              />
            </span>
            <span className={css.itemValue}>{isRFQ ? RFQ : formatMoney(intl, total)}</span>
          </div>
        );
      })
    : null;
};

LineItemBasePriceRefundMaybe.propTypes = {
  lineItems: propTypes.lineItems.isRequired,
  code: propTypes.lineItemUnitType.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemBasePriceRefundMaybe;
