import { updateTransactionMetadata, uploadFilesToGCS } from '../../util/api';
import { storableError } from '../../util/errors';
import { denormalisedEntities, updatedEntities } from '../../util/data';
import {
  fetchMessages,
  sendMessageError,
  sendMessageRequest,
  sendMessageSuccess,
  setInitialValues,
} from '../TransactionPage/TransactionPage.duck';
import pickBy from 'lodash/pickBy';
import isEmpty from 'lodash/isEmpty';
import { getAdminProfile } from '../TendersPage/TendersPage.duck';

// ================ Action types ================ //

export const FETCH_INITIAL_VALUES_REQUEST = 'app/ui/FETCH_INITIAL_VALUES_REQUEST';
export const FETCH_INITIAL_VALUES_SUCCESS = 'app/ui/FETCH_INITIAL_VALUES_SUCCESS';
export const FETCH_INITIAL_VALUES_ERROR = 'app/ui/FETCH_INITIAL_VALUES_ERROR';

export const UPDATE_TENDER_REQUEST = 'app/ui/UPDATE_TENDER_REQUEST';
export const UPDATE_TENDER_SUCCESS = 'app/ui/UPDATE_TENDER_SUCCESS';
export const UPDATE_TENDER_ERROR = 'app/ui/UPDATE_TENDER_ERROR';

// ================ Reducer ================ //

const initialState = {
  transaction: null,
  tender: null,
  updateTenderInProgress: false,
  updateTenderError: null,
  fetchInitialValuesInProgress: false,
  fetchInitialValuesError: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_INITIAL_VALUES_REQUEST: {
      return {
        ...state,
        fetchInitialValuesInProgress: true,
        fetchInitialValuesError: null,
        tender: null,
        transaction: null,
      };
    }
    case FETCH_INITIAL_VALUES_SUCCESS: {
      return {
        ...state,
        fetchInitialValuesInProgress: false,
        fetchInitialValuesError: null,
        transaction: payload.transaction,
        tender: payload.listing,
      };
    }
    case FETCH_INITIAL_VALUES_ERROR: {
      return {
        ...state,
        fetchInitialValuesInProgress: false,
        fetchInitialValuesError: payload,
      };
    }

    case UPDATE_TENDER_REQUEST: {
      return { ...state, updateTenderInProgress: true, updateTenderError: false };
    }
    case UPDATE_TENDER_SUCCESS: {
      return {
        ...state,
        updateTenderInProgress: false,
        updateTenderError: payload,
      };
    }
    case UPDATE_TENDER_ERROR: {
      return { ...state, updateTenderInProgress: false, updateTenderError: payload };
    }

    default:
      return state;
  }
}

// ================ Action creators ================ //

const updateTenderRequest = () => ({ type: UPDATE_TENDER_REQUEST });
const updateTenderSuccess = () => ({ type: UPDATE_TENDER_SUCCESS });
const updateTenderError = error => ({ type: UPDATE_TENDER_ERROR, payload: error });

const fetchInitialValuesRequest = () => ({ type: FETCH_INITIAL_VALUES_REQUEST });
const fetchInitialValuesSuccess = payload => ({ type: FETCH_INITIAL_VALUES_SUCCESS, payload });
const fetchInitialValuesError = error => ({ type: FETCH_INITIAL_VALUES_ERROR, payload: error });

// ================ Selectors ================ //

export const sendMessage = (txId, message, config, quotes) => (dispatch, getState, sdk) => {
  dispatch(sendMessageRequest());

  const alreadyUploadedQuotes = [];

  const promise = message
    ? sdk.messages.send({ transactionId: txId, content: message }).then(response => {
        const messageId = response.data.data.id;

        // We fetch the first page again to add sent message to the page data
        // and update possible incoming messages too.
        // TODO if there're more than 100 incoming messages,
        // this should loop through most recent pages instead of fetching just the first one.
        return dispatch(fetchMessages(txId, 1, config))
          .then(() => messageId)
          .catch(() => dispatch(sendMessageSuccess()));
      })
    : new Promise((resolve, reject) => resolve());

  return promise
    .then(() => {
      if (!quotes || !quotes?.length) {
        return updateTransactionMetadata({
          id: txId,
          metadata: { quotes: null },
        });
      }

      const formData = new FormData();

      for (let i = 0; i < quotes.length; i++) {
        if (typeof quotes[i] === 'string') {
          alreadyUploadedQuotes.push(quotes[i]);
        } else {
          formData.append('files', quotes[i]);
        }
      }

      const hasNewQuotes = formData.getAll('files').length;
      return hasNewQuotes ? uploadFilesToGCS(formData).then(res => res) : { data: { data: [] } };
    })
    .then(uploadedFiles => {
      return updateTransactionMetadata({
        id: txId,
        metadata: { quotes: [...alreadyUploadedQuotes, ...uploadedFiles.data.data] },
      });
    })
    .then(() => {
      return sdk.transactions
        .show({
          id: txId,
          include: ['customer', 'provider', 'listing'],
        })
        .then(response => {
          const listingId = listingRelationship(response).id;
          const entities = updatedEntities({}, response.data);
          const listingRef = { id: listingId, type: 'listing' };
          const transactionRef = { id: response?.data?.data?.id, type: 'transaction' };
          const [listing, transaction] = denormalisedEntities(entities, [
            listingRef,
            transactionRef,
          ]);
          dispatch(sendMessageSuccess());
          dispatch(fetchInitialValuesSuccess({ transaction, listing }));
        });
    })
    .catch(e => {
      dispatch(sendMessageError(storableError(e)));
      throw e;
    });
};

export const updateTender = (params, listingId) => (dispatch, getState, sdk) => {
  dispatch(updateTenderRequest());
  return sdk.ownListings
    .update({ id: listingId, ...params })
    .then(() => dispatch(updateTenderSuccess()))
    .catch(e => dispatch(updateTenderError(e.message)));
};

const listingRelationship = txResponse => {
  return txResponse.data.data.relationships.listing.data;
};

const isNonEmpty = value => {
  return typeof value === 'object' || Array.isArray(value) ? !isEmpty(value) : !!value;
};

export const loadData = (params, search, config) => (dispatch, getState, sdk) => {
  dispatch(fetchInitialValuesRequest());
  const { id } = params;

  const state = getState().TransactionPage;
  const txRef = state.transactionRef;

  const initialValues = txRef ? {} : pickBy(state, isNonEmpty);
  dispatch(setInitialValues({ ...initialValues, messages: [] }));

  return sdk.transactions
    .show(
      {
        id,
        include: ['customer', 'provider', 'listing'],
      },
      { expand: true }
    )
    .then(response => {
      const listingId = listingRelationship(response).id;
      const entities = updatedEntities({}, response.data);
      const listingRef = { id: listingId, type: 'listing' };
      const transactionRef = { id: response?.data?.data?.id, type: 'transaction' };
      const [listing, transaction] = denormalisedEntities(entities, [listingRef, transactionRef]);
      dispatch(fetchInitialValuesSuccess({ transaction, listing }));
    })
    .then(() => {
      dispatch(fetchMessages(id, 1, config));
      dispatch(getAdminProfile());
    })
    .catch(e => dispatch(fetchInitialValuesError(e.message)));
};
