import React from 'react';

const IconMachineOperatorsAvailable = ({width, height}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 48 43" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill='none' d="M32.8 34.5L37.2 38.8333L46 30.1667M24 28H15.2C11.0997 28 9.04957 28 7.43239 28.6597C5.27614 29.5393 3.56301 31.2265 2.66986 33.3501C2 34.9428 2 36.9618 2 41M31.7 2.62998C34.925 3.91565 37.2 7.02951 37.2 10.6667C37.2 14.3038 34.925 17.4177 31.7 18.7034M27.3 10.6667C27.3 15.4531 23.3601 19.3333 18.5 19.3333C13.6399 19.3333 9.7 15.4531 9.7 10.6667C9.7 5.8802 13.6399 2 18.5 2C23.3601 2 27.3 5.8802 27.3 10.6667Z" stroke="#4871F7" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default IconMachineOperatorsAvailable
