import {
  updatedEntities,
  denormalisedEntities,
  denormalisedResponseEntities,
} from '../../util/data';
import { storableError } from '../../util/errors';
import { createImageVariantConfig } from '../../util/sdkLoader';
import { parse } from '../../util/urlHelpers';

// ================ Action types ================ //

export const FETCH_LISTINGS_REQUEST = 'app/ListingsEditPage/FETCH_LISTINGS_REQUEST';
export const FETCH_LISTINGS_SUCCESS = 'app/ListingsEditPage/FETCH_LISTINGS_SUCCESS';
export const FETCH_LISTINGS_ERROR = 'app/ListingsEditPage/FETCH_LISTINGS_ERROR';

export const UPDATE_LISTING_REQUEST = 'app/ListingsEditPage/UPDATE_LISTING_REQUEST';
export const UPDATE_LISTING_SUCCESS = 'app/ListingsEditPage/UPDATE_LISTING_SUCCESS';
export const UPDATE_LISTING_ERROR = 'app/ListingsEditPage/UPDATE_LISTING_ERROR';

export const OPEN_LISTINGS_REQUEST = 'app/ListingsEditPage/OPEN_LISTINGS_REQUEST';
export const OPEN_LISTINGS_SUCCESS = 'app/ListingsEditPage/OPEN_LISTINGS_SUCCESS';
export const OPEN_LISTINGS_ERROR = 'app/ListingsEditPage/OPEN_LISTINGS_ERROR';

export const CLOSE_LISTINGS_REQUEST = 'app/ListingsEditPage/CLOSE_LISTINGS_REQUEST';
export const CLOSE_LISTINGS_SUCCESS = 'app/ListingsEditPage/CLOSE_LISTINGS_SUCCESS';
export const CLOSE_LISTINGS_ERROR = 'app/ListingsEditPage/CLOSE_LISTINGS_ERROR';

// ================ Reducer ================ //

const initialState = {
  queryInProgress: false,
  queryListingsError: null,
  currentPageResult: null,
  openingListings: null,
  openingListingsError: null,
  closingListings: null,
  closingListingsError: null,
  updateInProgress: false,
  updateError: null,
};

const manageListingsEditPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_LISTINGS_REQUEST:
      return {
        ...state,
        queryInProgress: true,
        queryListingsError: null,
        currentPageResult: null,
      };
    case FETCH_LISTINGS_SUCCESS:
      return {
        ...state,
        currentPageResult: payload?.filter(l => ['closed', 'published'].includes(l?.attributes?.state) && l?.attributes?.publicData?.listingType !== 'tender'),
        queryInProgress: false,
      };
    case FETCH_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, queryInProgress: false, queryListingsError: payload };

    case UPDATE_LISTING_REQUEST:
      return { ...state, updateInProgress: true, updateError: null };
    case UPDATE_LISTING_SUCCESS:
      return {
        ...state,
        updateInProgress: false,
        updateError: null,
        currentPageResult: state.currentPageResult.map(el =>
          el.id.uuid === payload.id.uuid ? payload : el
        ),
      };
    case UPDATE_LISTING_ERROR:
      return { ...state, updateInProgress: false, updateError: payload };

    case OPEN_LISTINGS_REQUEST:
      return { ...state, openingListings: true, openingListingsError: null };
    case OPEN_LISTINGS_SUCCESS:
      return {
        ...state,
        openingListings: false,
        openingListingsError: null,
        currentPageResult: state.currentPageResult.map(l =>
          payload.includes(l.id.uuid)
            ? { ...l, attributes: { ...l.attributes, state: 'published' } }
            : l
        ),
      };
    case OPEN_LISTINGS_ERROR:
      return { ...state, openingListings: false, openingListingsError: payload };

    case CLOSE_LISTINGS_REQUEST:
      return { ...state, closingListings: true, closingListingsError: null };
    case CLOSE_LISTINGS_SUCCESS:
      return {
        ...state,
        closingListings: false,
        closingListingsError: null,
        currentPageResult: state.currentPageResult.map(l =>
          payload.includes(l.id.uuid)
            ? { ...l, attributes: { ...l.attributes, state: 'closed' } }
            : l
        ),
      };
    case CLOSE_LISTINGS_ERROR:
      return { ...state, closingListings: false, closingListingsError: payload };

    default:
      return state;
  }
};

export default manageListingsEditPageReducer;

// ================ Action creators ================ //

export const queryListingsRequest = queryParams => ({
  type: FETCH_LISTINGS_REQUEST,
  payload: { queryParams },
});

export const queryListingsSuccess = response => ({
  type: FETCH_LISTINGS_SUCCESS,
  payload: response,
});

export const queryListingsError = e => ({
  type: FETCH_LISTINGS_ERROR,
  error: true,
  payload: e,
});

const updateListingRequest = () => ({ type: UPDATE_LISTING_REQUEST });
const updateListingSuccess = payload => ({ type: UPDATE_LISTING_SUCCESS, payload });
const updateListingError = e => ({ type: UPDATE_LISTING_ERROR, payload: e });

export const openListingsRequest = () => ({
  type: OPEN_LISTINGS_REQUEST,
});
export const openListingsSuccess = payload => ({
  type: OPEN_LISTINGS_SUCCESS,
  payload,
});
export const openListingsError = e => ({
  type: OPEN_LISTINGS_ERROR,
  payload: e,
});

export const closeListingsRequest = () => ({
  type: CLOSE_LISTINGS_REQUEST,
});
export const closeListingsSuccess = payload => ({
  type: CLOSE_LISTINGS_SUCCESS,
  payload,
});
export const closeListingsError = e => ({
  type: CLOSE_LISTINGS_ERROR,
  payload: e,
});

export const queryOwnListings = queryParams => (dispatch, getState, sdk) => {
  dispatch(queryListingsRequest(queryParams));

  const { perPage, ...rest } = queryParams;
  const params = { ...rest, perPage };

  return sdk.ownListings
    .query(params)
    .then(response => {
      const entities = denormalisedResponseEntities(response);
      dispatch(queryListingsSuccess(entities));
      return response;
    })
    .catch(e => {
      dispatch(queryListingsError(storableError(e)));
      throw e;
    });
};

export const closeListings = listingIds => async (dispatch, getState, sdk) => {
  dispatch(closeListingsRequest());
  try {
    for (let i = 0; i < listingIds.length; i++) {
      await sdk.ownListings.close({ id: listingIds[i] }, { expand: true });
    }
    dispatch(closeListingsSuccess(listingIds));
  } catch (e) {
    dispatch(closeListingsError(storableError(e)));
  }
};

export const openListings = listingIds => async (dispatch, getState, sdk) => {
  dispatch(openListingsRequest());
  try {
    for (let i = 0; i < listingIds.length; i++) {
      await sdk.ownListings.open({ id: listingIds[i] }, { expand: true });
    }
    dispatch(openListingsSuccess(listingIds));
  } catch (e) {
    dispatch(openListingsError(storableError(e)));
  }
};

export const updateListing = (id, data) => async (dispatch, getState, sdk) => {
  dispatch(updateListingRequest());
  try {
    const aspectWidth = 1;
    const aspectHeight = 1;
    const variantPrefix = 'listing-card';
    const aspectRatio = aspectHeight / aspectWidth;
    const updatedListing = await sdk.ownListings.update(
      { id, ...data },
      {
        expand: true,
        include: ['images', 'currentStock'],
        'fields.image': [`variants.${variantPrefix}`, `variants.${variantPrefix}-2x`],
        ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
        ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
        'limit.images': 1,
      }
    );
    const entities = denormalisedResponseEntities(updatedListing);
    dispatch(updateListingSuccess(entities[0]));
  } catch (e) {
    dispatch(updateListingError(storableError(e)));
  }
};

export const loadData = (params, search, config) => {
  const queryParams = parse(search);

  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const aspectRatio = aspectHeight / aspectWidth;

  return queryOwnListings({
    ...queryParams,
    include: ['images', 'currentStock'],
    'fields.image': [`variants.${variantPrefix}`, `variants.${variantPrefix}-2x`],
    ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
    'limit.images': 1,
  });
};
