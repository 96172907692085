import { storableError } from '../../util/errors';
import { getPremiumPartnersApi } from '../../util/api';

// ================ Action types ================ //

export const QUERY_PREMIUM_PARTNER_REQUEST = 'app/PartnerPortal/QUERY_PREMIUM_PARTNER_REQUEST';
export const QUERY_PREMIUM_PARTNER_SUCCESS = 'app/PartnerPortal/QUERY_PREMIUM_PARTNER_SUCCESS';
export const QUERY_PREMIUM_PARTNER_ERROR = 'app/PartnerPortal/QUERY_PREMIUM_PARTNER_ERROR';

// ================ Reducer ================ //

const initialState = {
  premiumPartners: [],
  premiumPartnersError: null,
};

export default function partnerPortalReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {

    case QUERY_PREMIUM_PARTNER_REQUEST:
      return { ...state, premiumPartnersError: null };
    case QUERY_PREMIUM_PARTNER_SUCCESS:
      return { ...state, premiumPartners: payload };
    case QUERY_PREMIUM_PARTNER_ERROR:
      return { ...state, premiumPartnersError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

const queryPremiumPartnerRequest = () => ({
  type: QUERY_PREMIUM_PARTNER_REQUEST,
});
const queryPremiumPartnerSuccess = payload => ({
  type: QUERY_PREMIUM_PARTNER_SUCCESS,
  payload,
});
const queryPremiumPartnerError = e => ({
  type: QUERY_PREMIUM_PARTNER_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //


const getPremiumPartners = () => async (dispatch) => {
  dispatch(queryPremiumPartnerRequest());

  try {
    const response = await getPremiumPartnersApi();

    const compare = (a, b) => {
      if (a.attributes.profile.publicData.companyLegalName < b.attributes.profile.publicData.companyLegalName){
        return -1;
      }
      if (a.attributes.profile.publicData.companyLegalName > b.attributes.profile.publicData.companyLegalName){
        return 1;
      }
      return 0;
    }

    dispatch(queryPremiumPartnerSuccess(response.users.sort(compare)));
  } catch( e) {
    dispatch(queryPremiumPartnerError(storableError(e)))
  }
};

export const loadData = () => (dispatch) => {

  return dispatch(getPremiumPartners());
};
