/**
 * Transaction process graph for bookings:
 *   - default-booking
 */

/**
 * Transitions
 *
 * These strings must sync with values defined in Marketplace API,
 * since transaction objects given by API contain info about last transitions.
 * All the actions in API side happen in transitions,
 * so we need to understand what those strings mean.
 */

export const transitions = {
  INQUIRE_TENDER: 'transition/inquire-tender',
  INQUIRE_BOOKING: 'transition/inquire-booking',
  INQUIRE_EXPIRE: 'transition/inquire-expire',
  INQUIRE_DECLINE_BY_PROVIDER: 'transition/inquire-decline-by-provider',
  CONFIRM_INQUIRE_BOOKING: 'transition/confirm-inquire-booking',
  INQUIRE_EXPIRE_AFTER_CONFIRM_BY_PROVIDER: 'transition/inquire-expire-after-confirm-by-provider',
  REQUEST_PAYMENT: 'transition/request-payment',
  EXPIRE_PAYMENT: 'transition/expire-payment',
  CONFIRM_PAYMENT: 'transition/confirm-payment',
  ACCEPT: 'transition/accept',
  LONG_TERM_PAYOUT: 'transition/long-term-payout',
  COMPLETE: 'transition/complete',
  CANCEL_BY_CUSTOMER: 'transition/cancel-by-customer',
  CANCEL: 'transition/cancel',
  DECLINE: 'transition/decline',
  LONG_TERM_COMPLETE: 'transition/long-term-complete',
  PAYOUT: 'transition/payout',
  READY_TO_REVIEW: 'transition/ready-to-review',
  REVIEW_1_BY_PROVIDER: 'transition/review-1-by-provider',
  REVIEW_2_BY_PROVIDER: 'transition/review-2-by-provider',
  REVIEW_1_BY_CUSTOMER: 'transition/review-1-by-customer',
  REVIEW_2_BY_CUSTOMER: 'transition/review-2-by-customer',
  EXPIRE_CUSTOMER_REVIEW_PERIOD: 'transition/expire-customer-review-period',
  EXPIRE_PROVIDER_REVIEW_PERIOD: 'transition/expire-provider-review-period',
  EXPIRE_REVIEW_PERIOD: 'transition/expire-review-period',
};

/**
 * States
 *
 * These constants are only for making it clear how transitions work together.
 * You should not use these constants outside of this file.
 *
 * Note: these states are not in sync with states used transaction process definitions
 *       in Marketplace API. Only last transitions are passed along transaction object.
 */
export const states = {
  INITIAL: 'initial',
  INQUIRY_TENDER: 'inquiry-tender',
  WAITING_CONFIRMATION: 'waiting-confirmation',
  INQUIRE_EXPIRED: 'inquire-expired',
  INQUIRE_DECLINED: 'inquire-declined',
  INQUIRE_BOOKING_CONFIRMED: 'inquire-booking-confirmed',
  INQUIRE_EXPIRED_AFTER_CONFIRM_BY_PROVIDER: 'inquire-expired-after-confirm-by-provider',
  PENDING_PAYMENT: 'pending-payment',
  PAYMENT_EXPIRED: 'payment-expired',
  PREAUTHORIZED: 'preauthorized',
  ACCEPTED: 'accepted',
  DECLINED: 'declined',
  CANCELED: 'cancelled',
  COMPLETED: 'completed',
  PAYOUT_INITIATED: 'payout-initiated',
  PAID: 'paid',
  DELIVERED: 'delivered',
  REVIEWED_BY_CUSTOMER: 'reviewed-by-customer',
  REVIEWED_BY_PROVIDER: 'reviewed-by-provider',
  REVIEWED: 'reviewed',
};

/**
 * Description of transaction process graph
 *
 * You should keep this in sync with transaction process defined in Marketplace API
 *
 * Note: we don't use yet any state machine library,
 *       but this description format is following Xstate (FSM library)
 *       https://xstate.js.org/docs/
 */
export const defaultBookingProcessName = 'default-booking/release-1';

export const graph = {
  // id is defined only to support Xstate format.
  // However if you have multiple transaction processes defined,
  // it is best to keep them in sync with transaction process aliases.
  id: defaultBookingProcessName,

  // This 'initial' state is a starting point for new transaction
  initial: states.INITIAL,

  // States
  states: {
    [states.INITIAL]: {
      on: {
        [transitions.INQUIRE_TENDER]: states.INQUIRY_TENDER,
      },
    },
    [states.INQUIRY_TENDER]: {},
    [states.INITIAL]: {
      on: {
        [transitions.INQUIRE_BOOKING]: states.WAITING_CONFIRMATION,
      },
    },
    [states.WAITING_CONFIRMATION]: {
      on: {
        [transitions.INQUIRE_EXPIRE]: states.INQUIRE_EXPIRED,
        [transitions.INQUIRE_DECLINE_BY_PROVIDER]: states.INQUIRE_DECLINED,
        [transitions.CONFIRM_INQUIRE_BOOKING]: states.INQUIRE_BOOKING_CONFIRMED,
      },
    },
    [states.INQUIRE_EXPIRED]: {},
    [states.INQUIRE_DECLINED]: {},
    [states.INQUIRE_BOOKING_CONFIRMED]: {
      on: {
        [transitions.INQUIRE_EXPIRE_AFTER_CONFIRM_BY_PROVIDER]:
          states.INQUIRE_EXPIRED_AFTER_CONFIRM_BY_PROVIDER,
        [transitions.REQUEST_PAYMENT]: states.PENDING_PAYMENT,
      },
    },
    [states.INQUIRE_EXPIRED_AFTER_CONFIRM_BY_PROVIDER]: {},
    [states.PENDING_PAYMENT]: {
      on: {
        [transitions.EXPIRE_PAYMENT]: states.PAYMENT_EXPIRED,
        [transitions.CONFIRM_PAYMENT]: states.PREAUTHORIZED,
      },
    },
    [states.PAYMENT_EXPIRED]: {},
    [states.PREAUTHORIZED]: {
      on: {
        [transitions.ACCEPT]: states.ACCEPTED,
      },
    },
    [states.ACCEPTED]: {
      on: {
        [transitions.LONG_TERM_PAYOUT]: states.PAYOUT_INITIATED,
        [transitions.COMPLETE]: states.COMPLETED,
        [transitions.CANCEL_BY_CUSTOMER]: states.CANCELED,
        [transitions.CANCEL]: states.CANCELED,
        [transitions.DECLINE]: states.DECLINED,
      },
    },
    [states.CANCELED]: {},
    [states.DECLINED]: {},
    [states.PAYOUT_INITIATED]: {
      on: {
        [transitions.LONG_TERM_COMPLETE]: states.DELIVERED,
      },
    },
    [states.COMPLETED]: {
      on: {
        [transitions.PAYOUT]: states.PAID,
      },
    },
    [states.PAID]: {
      on: {
        [transitions.READY_TO_REVIEW]: states.DELIVERED,
      },
    },
    [states.DELIVERED]: {
      on: {
        [transitions.REVIEW_1_BY_CUSTOMER]: states.REVIEWED_BY_CUSTOMER,
        [transitions.REVIEW_1_BY_PROVIDER]: states.REVIEWED_BY_PROVIDER,
        [transitions.EXPIRE_REVIEW_PERIOD]: states.REVIEWED,
      },
    },
    [states.REVIEWED_BY_CUSTOMER]: {
      on: {
        [transitions.REVIEW_2_BY_PROVIDER]: states.REVIEWED,
        [transitions.EXPIRE_PROVIDER_REVIEW_PERIOD]: states.REVIEWED,
      },
    },
    [states.REVIEWED_BY_PROVIDER]: {
      on: {
        [transitions.REVIEW_2_BY_CUSTOMER]: states.REVIEWED,
        [transitions.EXPIRE_CUSTOMER_REVIEW_PERIOD]: states.REVIEWED,
      },
    },
    [states.REVIEWED]: { type: 'final' },
  },
};

// Check if a transition is the kind that should be rendered
// when showing transition history (e.g. ActivityFeed)
// The first transition and most of the expiration transitions made by system are not relevant
export const isRelevantPastTransition = transition => {
  return [
    transitions.INQUIRE_TENDER,
    transitions.INQUIRE_BOOKING,
    transitions.INQUIRE_EXPIRE,
    transitions.INQUIRE_DECLINE_BY_PROVIDER,
    transitions.CONFIRM_INQUIRE_BOOKING,
    transitions.INQUIRE_EXPIRE_AFTER_CONFIRM_BY_PROVIDER,
    transitions.REQUEST_PAYMENT,
    transitions.EXPIRE_PAYMENT,
    transitions.CONFIRM_PAYMENT,
    transitions.ACCEPT,
    transitions.LONG_TERM_PAYOUT,
    transitions.COMPLETE,
    transitions.CANCEL_BY_CUSTOMER,
    transitions.CANCEL,
    transitions.DECLINE,
    transitions.LONG_TERM_COMPLETE,
    transitions.PAYOUT,
    transitions.READY_TO_REVIEW,
    transitions.REVIEW_1_BY_PROVIDER,
    transitions.REVIEW_2_BY_PROVIDER,
    transitions.REVIEW_1_BY_CUSTOMER,
    transitions.REVIEW_2_BY_CUSTOMER,
    transitions.EXPIRE_CUSTOMER_REVIEW_PERIOD,
    transitions.EXPIRE_PROVIDER_REVIEW_PERIOD,
    transitions.EXPIRE_REVIEW_PERIOD,
  ].includes(transition);
};

// Processes might be different on how reviews are handled.
// Default processes use two-sided diamond shape, where either party can make the review first
export const isCustomerReview = transition => {
  return [transitions.REVIEW_1_BY_CUSTOMER, transitions.REVIEW_2_BY_CUSTOMER].includes(transition);
};

// Processes might be different on how reviews are handled.
// Default processes use two-sided diamond shape, where either party can make the review first
export const isProviderReview = transition => {
  return [transitions.REVIEW_1_BY_PROVIDER, transitions.REVIEW_2_BY_PROVIDER].includes(transition);
};

// Check if the given transition is privileged.
//
// Privileged transitions need to be handled from a secure context,
// i.e. the backend. This helper is used to check if the transition
// should go through the local API endpoints, or if using JS SDK is
// enough.
export const isPrivileged = transition => {
  return [transitions.REQUEST_PAYMENT, transitions.INQUIRE_BOOKING].includes(transition);
};

// Check when transaction is completed (booking over)
export const isCompleted = transition => {
  const txCompletedTransitions = [
    transitions.READY_TO_REVIEW,
    transitions.LONG_TERM_COMPLETE,
    transitions.REVIEW_1_BY_CUSTOMER,
    transitions.REVIEW_1_BY_PROVIDER,
    transitions.REVIEW_2_BY_CUSTOMER,
    transitions.REVIEW_2_BY_PROVIDER,
    transitions.EXPIRE_REVIEW_PERIOD,
    transitions.EXPIRE_CUSTOMER_REVIEW_PERIOD,
    transitions.EXPIRE_PROVIDER_REVIEW_PERIOD,
  ];
  return txCompletedTransitions.includes(transition);
};

export const hiddenTransitions = [transitions.REQUEST_PAYMENT, transitions.CONFIRM_PAYMENT];

// Check when transaction is refunded (booking did not happen)
// In these transitions action/stripe-refund-payment is called
export const isRefunded = transition => {
  const txRefundedTransitions = [
    transitions.EXPIRE_PAYMENT,
    transitions.CANCEL,
    transitions.DECLINE,
  ];
  return txRefundedTransitions.includes(transition);
};

export const statesNeedingProviderAttention = [states.WAITING_CONFIRMATION];
