import React from 'react';

const IconCertifiedManufacturerReseller = ({width, height}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 43 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill='none' d="M9.58333 41.2224H6.33333C3.9401 41.2224 2 39.2712 2 36.8643V6.35804C2 3.95116 3.9401 2 6.33333 2H36.6667C39.0599 2 41 3.95116 41 6.35804V36.8643C41 39.2712 39.0599 41.2224 36.6667 41.2224H33.4167M21.5 39.0433C25.0899 39.0433 28 36.1166 28 32.5063C28 28.896 25.0899 25.9692 21.5 25.9692C17.9101 25.9692 15 28.896 15 32.5063C15 36.1166 17.9101 39.0433 21.5 39.0433ZM21.5 39.0433L21.5465 39.0429L14.6288 46L8.50053 39.8368L15.0426 33.2574M21.5 39.0433L28.4178 46L34.546 39.8368L28.004 33.2574M15 10.7161H28M10.6667 18.3427H32.3333" stroke="#4871F7" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default IconCertifiedManufacturerReseller;
