import React from 'react';
import { useConfiguration } from '../../context/configurationContext';
import loadable from '@loadable/component';

import {
  ExternalLink,
  IconSocialMediaFacebook,
  IconSocialMediaInstagram,
  // IconSocialMediaPinterest,
  IconSocialMediaTwitter,
  IconSocialMediaLinkedin,
  IconSocialMediaYoutube,
  LinkedLogo,
  Logo,
  NamedLink,
} from '../../components';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import { twitterPageURL } from '../../util/urlHelpers';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { EmailVerificationPageComponent } from '../EmailVerificationPage/EmailVerificationPage';
import SectionContainer from '../PageBuilder/SectionBuilder/SectionContainer';
import classNames from 'classnames';
import Field from '../PageBuilder/Field';
import customStyle from '../PageBuilder/SectionBuilder/SectionFooter/SectionFooterCustomStyle.css';
import BlockBuilder from '../PageBuilder/BlockBuilder';

import css from './FooterContainer.module.css';


const SectionBuilder = loadable(
  () => import(/* webpackChunkName: "SectionBuilder" */ '../PageBuilder/PageBuilder'),
  {
    resolveComponent: components => components.SectionBuilder,
  }
);

const renderSocialMediaLinks = intl => {
  const config = useConfiguration();
  const { siteFacebookPage, siteLinkedInPage, siteTwitterHandle, siteYoutubePage } = config;
  const siteTwitterPage = twitterPageURL(siteTwitterHandle);

  const goToFb = intl.formatMessage({ id: 'Footer.goToFacebook' });
  const goToLinkedIn = intl.formatMessage({ id: 'Footer.goToLinkedin' });
  const goToYoutube = intl.formatMessage({ id: 'Footer.goToYoutube' });

  const fbLink = (
    <ExternalLink key="linkToFacebook" href={siteFacebookPage} className={css.icon} title={goToFb}>
      <IconSocialMediaFacebook rootClassName={css.svg} />
    </ExternalLink>
  );

  const linkedInLink = (
    <ExternalLink
      key="siteLinkedInHandle"
      href={siteLinkedInPage}
      className={css.icon}
      title={goToLinkedIn}
    >
      <IconSocialMediaLinkedin rootClassName={css.svg} />
    </ExternalLink>
  );

  const youtubeLink = (
    <ExternalLink
      key="linkToYoutube"
      href={siteYoutubePage}
      className={css.icon}
      title={goToYoutube}
    >
      <IconSocialMediaYoutube rootClassName={css.svg} />
    </ExternalLink>
  );


  return [fbLink, linkedInLink, youtubeLink].filter(v => v != null);
};


const FooterComponent = (props) => {
  const { intl } = props;
  const { footer = {} } = useConfiguration();
  const socialMediaLinks = renderSocialMediaLinks(intl);

  // If footer asset is not set, let's not render Footer at all.
  if (Object.keys(footer).length === 0) {
    return null;
  }

  // The footer asset does not specify sectionId or sectionType. However, the SectionBuilder
  // expects sectionId and sectionType in order to identify the section. We add those
  // attributes here before passing the asset to SectionBuilder.
  const footerSection = {
    ...footer,
    sectionId: 'footer',
    sectionType: 'footer',
  };

  // return <SectionBuilder sections={[footerSection]} />;

  return  <div className={css.footerWrapper}>
    <div className={classNames(css.footerContent)}>
      <div className={css.linksHolder}>
        <div className={css.logoHolder}>
          <LinkedLogo
            rootClassName={css.logoLink}
            logoClassName={css.logoWrapper}
            logoImageClassName={css.logoImage}
          />
        </div>

        <div className={css.linksList}>
          <ul className={css.list}>
            <li className={css.listItem}>
              <NamedLink name="AboutUs" className={css.link}>
                <FormattedMessage id="Footer.toAboutPage" />
              </NamedLink>
            </li>
            <li className={css.listItem}>
              <NamedLink name="AboutUs" className={css.link}>
                <FormattedMessage id="Footer.toContactPage" />
              </NamedLink>
            </li>
            <li className={css.listItem}>
              <NamedLink name="SearchPage" to={{search: '?address=Ireland&bounds=55.46020003387486%2C-5.66890006746988%2C51.30659995812083%2C-10.76660009801868'}} className={css.link}>
                <FormattedMessage id="Footer.toEquipmentPage" />
              </NamedLink>
            </li>
            <li className={css.listItem}>
              <NamedLink name="NewListingPage" className={css.link}>
                <FormattedMessage id="Footer.toListEquipmentPage" />
              </NamedLink>
            </li>
            <li className={css.listItem}>
              <NamedLink name="TermsOfServicePage" className={css.link}>
                <FormattedMessage id="Footer.toTermsPage" />
              </NamedLink>
            </li>
            <li className={css.listItem}>
              <NamedLink name="PrivacyPolicyPage" className={css.link}>
                <FormattedMessage id="Footer.toPrivacyPage" />
              </NamedLink>
            </li>
          </ul>

          <div className={css.socialLinks}>
            {socialMediaLinks}
          </div>
        </div>
      </div>


      <div className={css.detailsInfo}>
        <div className={css.cardsHolder}>
          <div className="paymentIcons">
            <img src="/../static/icons/visa.png" alt="Visa Card" className={css.card} />
            <img src="/../static/icons/master.png" alt="Master Card" className={css.card} />
            <img src="/../static/icons/amex.png" alt="Amex Card" className={css.card} />
          </div>
          <div className={css.securePaymentTxt}>
            <FormattedMessage id="Footer.stripe" />
          </div>

        </div>

        <div className={css.copy}>
          <FormattedMessage id="Footer.copy" />
        </div>
      </div>
    </div>
  </div>



};

// NOTE: if you want to add dynamic data to FooterComponent,
//       you could just connect this FooterContainer to Redux Store
//
// const mapStateToProps = state => {
//   const { currentUser } = state.user;
//   return { currentUser };
// };
const FooterContainer = compose(injectIntl)(FooterComponent);
export default FooterContainer;



// export default FooterComponent;
