/////////////////////////////////////////////////////////
// Configurations related to listing.                  //
// Main configuration here is the extended data config //
/////////////////////////////////////////////////////////

// NOTE: if you want to change the structure of the data,
// you should also check src/util/configHelpers.js
// some validation is added there.

/**
 * Configuration options for listing fields (custom extended data fields):
 * - key:                           Unique key for the extended data field.
 * - scope (optional):              Scope of the extended data can be either 'public' or 'private'.
 *                                  Default value: 'public'.
 *                                  Note: listing doesn't support 'protected' scope atm.
 * - includeForListingTypes:        An array of listing types, for which the extended
 *   (optional)                     data is relevant and should be added.
 * - schemaType (optional):         Schema for this extended data field.
 *                                  This is relevant when rendering components and querying listings.
 *                                  Possible values: 'enum', 'multi-enum', 'text', 'long', 'boolean'.
 * - enumOptions (optional):        Options shown for 'enum' and 'multi-enum' extended data.
 *                                  These are used to render options for inputs and filters on
 *                                  EditListingPage, ListingPage, and SearchPage.
 * - filterConfig:                  Filter configuration for listings query.
 *    - indexForSearch (optional):    If set as true, it is assumed that the extended data key has
 *                                    search index in place. I.e. the key can be used to filter
 *                                    listing queries (then scope needs to be 'public').
 *                                    Note: Flex CLI can be used to set search index for the key:
 *                                    https://www.sharetribe.com/docs/references/extended-data/#search-schema
 *                                    Read more about filtering listings with public data keys from API Reference:
 *                                    https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
 *                                    Default value: false,
 *   - filterType:                    Sometimes a single schemaType can be rendered with different filter components.
 *                                    For 'enum' schema, filterType can be 'SelectSingleFilter' or 'SelectMultipleFilter'
 *   - label:                         Label for the filter, if the field can be used as query filter
 *   - searchMode (optional):         Search mode for indexed data with multi-enum schema.
 *                                    Possible values: 'has_all' or 'has_any'.
 *   - group:                         SearchPageWithMap has grouped filters. Possible values: 'primary' or 'secondary'.
 * - showConfig:                    Configuration for rendering listing. (How the field should be shown.)
 *   - label:                         Label for the saved data.
 *   - isDetail                       Can be used to hide detail row (of type enum, boolean, or long) from listing page.
 *                                    Default value: true,
 * - saveConfig:                    Configuration for adding and modifying extended data fields.
 *   - label:                         Label for the input field.
 *   - placeholderMessage (optional): Default message for user input.
 *   - isRequired (optional):         Is the field required for providers to fill
 *   - requiredMessage (optional):    Message for those fields, which are mandatory.
 */
export const listingFields = [
  {
    key: 'category',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { option: 'city-bikes', label: 'City bikes' },
      { option: 'electric-bikes', label: 'Electric bikes' },
      { option: 'mountain-bikes', label: 'Mountain bikes' },
      { option: 'childrens-bikes', label: "Children's bikes" },
    ],
    filterConfig: {
      indexForSearch: true,
      filterType: 'SelectMultipleFilter',
      label: 'Category',
      group: 'primary',
    },
    showConfig: {
      label: 'Category',
      isDetail: true,
    },
    saveConfig: {
      label: 'Category',
      placeholderMessage: 'Select an option…',
      isRequired: true,
      requiredMessage: 'You need to select a category.',
    },
  },
  {
    key: 'tire',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { option: '29', label: '29' },
      { option: '28', label: '28' },
      { option: '27', label: '27' },
      { option: '26', label: '26' },
      { option: '24', label: '24' },
      { option: '20', label: '20' },
      { option: '18', label: '18' },
    ],
    filterConfig: {
      indexForSearch: true,
      label: 'Tire size',
      group: 'secondary',
    },
    showConfig: {
      label: 'Tire size',
      isDetail: true,
    },
    saveConfig: {
      label: 'Tire size',
      placeholderMessage: 'Select an option…',
      isRequired: true,
      requiredMessage: 'You need to select a tire size.',
    },
  },
  {
    key: 'brand',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { option: 'cube', label: 'Cube' },
      { option: 'diamant', label: 'Diamant' },
      { option: 'ghost', label: 'GHOST' },
      { option: 'giant', label: 'Giant' },
      { option: 'kalkhoff', label: 'Kalkhoff' },
      { option: 'kona', label: 'Kona' },
      { option: 'otler', label: 'Otler' },
      { option: 'vermont', label: 'Vermont' },
    ],
    filterConfig: {
      indexForSearch: true,
      label: 'Brand',
      group: 'secondary',
    },
    showConfig: {
      label: 'Brand',
      isDetail: true,
    },
    saveConfig: {
      label: 'Brand',
      placeholderMessage: 'Select an option…',
      isRequired: true,
      requiredMessage: 'You need to select a brand.',
    },
  },
  {
    key: 'accessories',
    scope: 'public',
    schemaType: 'multi-enum',
    enumOptions: [
      { option: 'bell', label: 'Bell' },
      { option: 'lights', label: 'Lights' },
      { option: 'lock', label: 'Lock' },
      { option: 'mudguard', label: 'Mudguard' },
    ],
    filterConfig: {
      indexForSearch: true,
      label: 'Accessories',
      searchMode: 'has_all',
      group: 'secondary',
    },
    showConfig: {
      label: 'Accessories',
    },
    saveConfig: {
      label: 'Accessories',
      placeholderMessage: 'Select an option…',
      isRequired: false,
    },
  },

  // // An example of how to use transaction type specific custom fields and private data.
  // {
  //   key: 'note',
  //   scope: 'public',
  //   includeForListingTypes: ['product-selling'],
  //   schemaType: 'text',
  //   showConfig: {
  //     label: 'Extra notes',
  //   },
  //   saveConfig: {
  //     label: 'Extra notes',
  //     placeholderMessage: 'Some public extra note about this bike...',
  //   },
  // },
  // {
  //   key: 'privatenote',
  //   scope: 'private',
  //   includeForListingTypes: ['daily-booking'],
  //   schemaType: 'text',
  //   saveConfig: {
  //     label: 'Private notes',
  //     placeholderMessage: 'Some private note about this bike...',
  //   },
  // },
];

///////////////////////////////////////////////////////////////////////
// Configurations related to listing types and transaction processes //
///////////////////////////////////////////////////////////////////////

// A presets of supported listing configurations
//
// Note 1: With first iteration of hosted configs, we are unlikely to support
//         multiple listing types, even though this template has some
//         rudimentary support for it.
// Note 2: transaction type is part of listing type. It defines what transaction process and units
//         are used when transaction is created against a specific listing.

/**
 * Configuration options for listing experience:
 * - listingType:         Unique string. This will be saved to listing's public data on
 *                        EditListingWizard.
 * - label                Label for the listing type. Used as microcopy for options to select
 *                        listing type in EditListingWizard.
 * - transactionType      Set of configurations how this listing type will behave when transaction is
 *                        created.
 *   - process              Transaction process.
 *                          The process must match one of the processes that this client app can handle
 *                          (check src/util/transactions/transaction.js) and the process must also exists in correct
 *                          marketplace environment.
 *   - alias                Valid alias for the aforementioned process. This will be saved to listing's
 *                          public data as transctionProcessAlias and transaction is initiated with this.
 *   - unitType             Unit type is mainly used as pricing unit. This will be saved to
 *                          transaction's protected data.
 *                          Recommendation: don't use same unit types in completely different processes
 *                          ('item' sold should not be priced the same as 'item' booked).
 * - stockType            This is relevant only to listings using default-purchase process.
 *                        If set to 'oneItem', stock management is not showed and the listing is
 *                        considered unique (stock = 1).
 *                        Possible values: 'oneItem' and 'multipleItems'.
 *                        Default: 'multipleItems'.
 * - defaultListingFields This is relevant only to listings using default-inquiry process atm.
 *                        It contains price: true/false value to indicate, whether price should be shown.
 *                        If defaultListingFields.price is not explicitly set to _false_, price will be shown.
 */

export const listingTypes = [
  {
    listingType: 'daily-booking',
    label: 'For hire',
    transactionType: {
      process: 'default-booking',
      alias: 'default-booking/release-1',
      unitType: 'day',
    },
  },
  // // Here are some examples for other listingTypes
  // // TODO: SearchPage does not work well if both booking and product selling are used at the same time
  // {
  //   listingType: 'nightly-booking',
  //   label: 'Nightly booking',
  //   transactionType: {
  //     process: 'default-booking',
  //     alias: 'default-booking/release-1',
  //     unitType: 'night',
  //   },
  // },
  // {
  //   listingType: 'hourly-booking',
  //   label: 'Hourly booking',
  //   transactionType: {
  //     process: 'default-booking',
  //     alias: 'default-booking/release-1',
  //     unitType: 'hour',
  //   },
  // },
  {
    listingType: 'product-selling',
    label: 'For sale',
    transactionType: {
      process: 'default-purchase',
      alias: 'default-purchase/release-1',
      unitType: 'item',
    },
    stockType: 'multipleItems',
  },
  // {
  //   listingType: 'inquiry',
  //   label: 'Inquiry',
  //   transactionType: {
  //     process: 'default-inquiry',
  //     alias: 'default-inquiry/release-1',
  //     unitType: 'inquiry',
  //   },
  //   defaultListingFields: {
  //     price: false,
  //   },
  // },
];

const STATUS_OPEN = 'open';
const STATUS_FULFILLED = 'fulfilled';
const STATUS_EXPIRED = 'expired';

export const tenderFields = [
  {
    key: 'sort',
    label: 'newest',
  },
  {
    key: 'tenderStatus',
    scope: 'public',
    includeForListingTypes: ['tender'],
    schemaType: 'enum',
    enumOptions: [
      { option: STATUS_OPEN, label: 'Open' },
      { option: STATUS_FULFILLED, label: 'Fulfilled' },
      { option: STATUS_EXPIRED, label: 'Expired' },
    ],
    filterConfig: {
      indexForSearch: true,
      label: 'Tender Status',
      group: 'primary',
    },
    showConfig: {
      label: 'Tender Status',
    },
    saveConfig: {
      label: 'Tender Status',
    },
  },
  {
    availability: 'time-partial',
    dateRangeMode: 'day',
    key: 'dates',
    label: 'Deadline Dates',
    schemaType: 'dates',
  },
  {
    availability: 'time-partial',
    dateRangeMode: 'day',
    key: 'startDate',
    label: 'Start Date',
    schemaType: 'number',
  },
];

// SearchPage can enforce listing query to only those listings with valid listingType
// However, it only works if you have set 'enum' type search schema for the public data fields
//   - listingType
//
//  Similar setup could be expanded to 2 other extended data fields:
//   - transactionProcessAlias
//   - unitType
//
// Read More:
// https://www.sharetribe.com/docs/how-to/manage-search-schemas-with-flex-cli/#adding-listing-search-schemas
export const enforceValidListingType = false;

const weight = {
  label: 'Weight',
  type: 'number',
  placeholder: 'Enter value',
  measurement: 'kg',
  required: false,
  value: 'weight',
};

const fuelType_powerSource = {
  label: 'Fuel Type / Power Source',
  type: 'select',
  placeholder: 'Select a power source',
  required: false,
  values: ['Diesel', 'Petrol', 'Hybrid', 'Propane LPG', 'Electric', 'Manual', 'Other (Free input)'],
  value: 'fuelType_powerSource',
};

const liftingCapacity = {
  label: 'Lifting Capacity',
  type: 'number',
  placeholder: 'Enter value',
  measurement: 'kg',
  required: false,
  value: 'liftingCapacity',
};

const maxSpeed = {
  label: 'Max Speed',
  type: 'number',
  placeholder: 'Enter value',
  measurement: '(km/h)',
  required: false,
  value: 'maxSpeed',
};

const operatingWeight = {
  label: 'Operating Weight',
  type: 'number',
  placeholder: 'Enter value',
  measurement: 'kg',
  required: false,
  value: 'operatingWeight',
};

const enginePower = {
  label: 'Engine Power',
  type: 'number',
  placeholder: 'Enter value',
  measurement: 'hp',
  required: false,
  value: 'enginePower',
};

const bucketCapacity = {
  label: 'Bucket Capacity',
  type: 'number',
  placeholder: 'Enter value',
  measurement: 'm3',
  required: false,
  value: 'bucketCapacity',
};

const noiseLevel = {
  label: 'Noise Level',
  type: 'number',
  placeholder: 'Enter the value',
  measurement: 'dB',
  required: false,
  value: 'noiseLevel',
};

const dimensions = {
  label: 'Dimensions',
  type: 'number',
  placeholder: 'Enter value',
  required: false,
  measurement: 'm',
  value: 'dimensions',
};

export const listingMasterCategories = [
  { label: 'Forklifts', value: 'forklifts' },
  { label: "Mobile Elevated Work Platforms - MEWP's", value: 'MEWP' },
  { label: 'Cranes', value: 'cranes' },
  { label: 'Bulldozers', value: 'bulldozers' },
  { label: 'Dumpers', value: 'dumpers' },
  { label: 'Excavators', value: 'excavators' },
  { label: 'Compactors', value: 'compactors' },
  { label: 'Generators', value: 'generators' },
  { label: 'Concrete Equipment', value: 'concreteEquipment' },
  { label: 'Loaders', value: 'loaders' },
  { label: 'Power Tools', value: 'powerTools' },
  { label: 'Skips', value: 'skips' },
  { label: 'Material Handling Equipment', value: 'materialHandlingEquipment' },
  { label: 'Air Compressors', value: 'airCompressors' },
  { label: 'Welding Equipment', value: 'weldingEquipment' },
  { label: 'Welfare Units', value: 'welfareUnits' },
  { label: 'Garden & Landscape', value: 'gardenLandscape' },
  { label: 'Pumps', value: 'pumps' },
  { label: 'Climate Control', value: 'climateControl' },
  { label: 'Cabling', value: 'cabling' },
  { label: 'Roofing', value: 'roofing' },
  { label: 'Plumbing', value: 'plumbing' },
  { label: 'Lighting', value: 'lighting' },
  { label: 'Cleaning Equipment', value: 'cleaningEquipment' },
  { label: 'Scaffolding', value: 'scaffolding' },
  { label: 'Services', value: 'services' },
  { label: 'Attachments', value: 'attachments' },
  { label: 'Surveying', value: 'surveying' },
  { label: 'Site Control', value: 'siteControl' },
  { label: 'Heavy Goods Vehicles (HGVs)', value: 'HGV' },
  { label: 'Storage and Containment', value: 'storageContainment' },
  { label: 'Other', value: 'other' },
];

export const listingAttributesFields = [
  weight,
  fuelType_powerSource,
  liftingCapacity,
  operatingWeight,
  enginePower,
  bucketCapacity,
  noiseLevel,
  dimensions,
  maxSpeed,
  {
    label: 'Platform Height',
    type: 'number',
    placeholder: 'Enter value',
    measurement: 'm',
    required: false,
    value: 'platformHeight',
  },
  {
    label: 'Platform Capacity',
    type: 'number',
    placeholder: 'Enter value',
    measurement: 'kg',
    required: false,
    value: 'platformCapacity',
  },
  {
    label: 'Outreach',
    type: 'number',
    placeholder: 'Enter value',
    measurement: 'm',
    required: false,
    value: 'outreach',
  },
  {
    label: 'Max Lift Height',
    type: 'number',
    placeholder: 'Enter value',
    measurement: 'm',
    required: false,
    value: 'maxLiftHeight',
  },
  {
    label: 'Turning Radius',
    type: 'number',
    placeholder: 'Enter value',
    measurement: 'degrees',
    required: false,
    value: 'turningRadius',
  },
  {
    label: 'Boom Length',
    type: 'number',
    placeholder: 'Enter value',
    measurement: 'm',
    required: false,
    value: 'boomLength',
  },
  {
    label: 'Counterweight',
    type: 'number',
    placeholder: 'Enter value',
    measurement: 'kg',
    required: false,
    value: 'counterweight',
  },
  {
    label: 'Max Height',
    type: 'number',
    placeholder: 'Enter value',
    measurement: 'm',
    required: false,
    value: 'maxHeight',
  },
  {
    label: 'Boom Angle Range',
    type: 'number',
    placeholder: 'Enter value',
    measurement: 'degrees',
    required: false,
    value: 'boomAngleRange',
  },
  {
    label: 'Blade Width',
    type: 'number',
    placeholder: 'Enter value',
    measurement: '(cm)',
    required: false,
    value: 'bladeWidth',
  },
  {
    label: 'Ground Clearance',
    type: 'number',
    placeholder: 'Enter value',
    measurement: 'm',
    required: false,
    value: 'groundClearance',
  },
  {
    label: 'Payload Capacity',
    type: 'number',
    placeholder: 'Enter value',
    measurement: 'kg',
    required: false,
    value: 'payloadCapacity',
  },
  {
    label: 'Dumping Height',
    type: 'number',
    placeholder: 'Enter value',
    measurement: 'm',
    required: false,
    value: 'dumpingHeight',
  },
  {
    label: 'Steering Type',
    type: 'select',
    placeholder: 'Select a steering type',
    required: false,
    values: [
      'Two-Wheel Steering (2WS)',
      'Four-Wheel Steering (4WS)',
      'Crab Steering',
      'Articulated Steering',
      'Hydrostatic Steering',
      'Electronic Steering',
      'Hydraulic Steering',
      'Power Steering',
      'Mechanical Steering',
      'Other (Free input)',
    ],
    value: 'steeringType',
  },
  {
    label: 'Digging Depth',
    type: 'number',
    placeholder: 'Enter value',
    measurement: 'm',
    required: false,
    value: 'diggingDepth',
  },
  {
    label: 'Max Reach',
    type: 'number',
    placeholder: 'Enter value',
    measurement: 'm',
    required: false,
    value: 'maxReach',
  },
  {
    label: 'Compaction Force',
    type: 'number',
    placeholder: 'Enter value',
    measurement: 'lb',
    required: false,
    value: 'compactionForce',
  },
  {
    label: 'Drum Width',
    type: 'number',
    placeholder: 'Enter value',
    measurement: '(cm)',
    required: false,
    value: 'drumWidth',
  },
  {
    label: 'Vibration Frequency',
    type: 'number',
    placeholder: 'Enter value',
    measurement: 'hz',
    required: false,
    value: 'vibrationFrequency',
  },
  {
    label: 'Power Output',
    type: 'number',
    placeholder: 'Enter value',
    measurement: 'kw',
    required: false,
    value: 'powerOutput',
  },
  {
    label: 'Run Time',
    type: 'number',
    placeholder: 'Enter the value',
    measurement: 'h',
    required: false,
    value: 'runTime',
  },
  {
    label: 'Mixer Capacity',
    type: 'number',
    placeholder: 'Enter value',
    measurement: 'm3',
    required: false,
    value: 'mixerCapacity',
  },
  {
    label: 'Concrete Equipment Type',
    type: 'select',
    placeholder: 'Select type',
    required: false,
    values: [
      'Concrete Mixers',
      'Concrete Batch Plants',
      'Concrete Pump Trucks (Boom pumps and Line pumps)',
      'Concrete Vibrators (Internal and External)',
      'Concrete Trowels',
      'Concrete Screeds (Manual and Powered)',
      'Concrete Finishing Machines (Floaters, Trowels, and Edgers)',
      'Concrete Cutters and Saws (Walk-behind, Handheld, Diamond blade)',
      'Concrete Crushers and Pulverizers',
      'Concrete Testing Equipment (Compression testers, Slump cones, etc.)',
      'Concrete Batching and Mixing Software',
      'Concrete Transport Vehicles (Mixer trucks, Agitator trucks, etc.)',
      'Concrete Buckets and Hoppers',
      'Concrete Rebar Benders and Cutters',
      'Other (Free input)',
    ],
    value: 'concreteEquipmentType',
  },
  {
    label: 'Max Output',
    type: 'number',
    placeholder: 'Enter value',
    measurement: 'm3',
    required: false,
    value: 'maxOutput',
  },
  {
    label: 'Dump Height',
    type: 'number',
    placeholder: 'Enter value',
    measurement: 'm',
    required: false,
    value: 'dumpHeight',
  },
  {
    label: 'Power Rating',
    type: 'number',
    placeholder: 'Enter value',
    measurement: 'W',
    required: false,
    value: 'powerRating',
  },
  {
    label: 'Torque',
    type: 'number',
    placeholder: 'Enter value',
    measurement: 'Nm',
    required: false,
    value: 'torque',
  },
  {
    label: 'Speed',
    type: 'number',
    placeholder: 'Enter value',
    measurement: '(Rpm)',
    required: false,
    value: 'speed',
  },
  {
    label: 'Voltage',
    type: 'number',
    placeholder: 'Enter value',
    measurement: 'V',
    required: false,
    value: 'voltage',
  },
  {
    label: 'Capacity',
    type: 'number',
    placeholder: 'Enter value',
    measurement: 'm3',
    required: false,
    value: 'capacity',
  },
  {
    label: 'Material Type',
    type: 'select',
    placeholder: 'Select a material type',
    required: false,
    values: [
      'Steel',
      'Aluminum',
      'Plastic',
      'Wood',
      'Composite Materials',
      'Fiberglass',
      'Polyethylene',
      'Bamboo',
      'Other (Free input)',
    ],
    value: 'materialType',
  },
  {
    label: 'Forklift Pockets',
    type: 'select',
    placeholder: 'Select a forklift pockets',
    required: false,
    values: [
      'Forklift Pockets',
      'Crane Lifting Lugs',
      'Hook Points',
      'Cradles',
      'Bail Arm Attachments',
      'Pockets for Tines',
      'Other (Free input)',
    ],
    value: 'forkliftPockets',
  },
  {
    label: 'Load Capacity',
    type: 'number',
    placeholder: 'Enter value',
    measurement: 'kg',
    required: false,
    value: 'loadCapacity',
  },
  {
    label: 'Operating Environment',
    type: 'select',
    placeholder: 'Select a operating environment',
    required: false,
    values: ['Indoor', 'Outdoor', 'Both'],
    value: 'operatingEnvironment',
  },
  {
    label: 'Lift Height',
    type: 'number',
    placeholder: 'Enter value',
    measurement: 'm',
    required: false,
    value: 'liftHeight',
  },
  {
    label: 'Power Output',
    type: 'number',
    placeholder: 'Enter value',
    measurement: 'hp',
    required: false,
    value: 'powerOutput',
  },
  {
    label: 'Pressure',
    type: 'number',
    placeholder: 'Enter value',
    measurement: 'PSI',
    required: false,
    value: 'Pressure',
  },
  {
    label: 'Tank Capacity',
    type: 'number',
    placeholder: 'Enter value',
    measurement: 'l',
    required: false,
    value: 'tankCapacity',
  },
  {
    label: 'Welding Type',
    type: 'select',
    placeholder: 'Select a welding type',
    required: false,
    values: [
      'Shielded Metal Arc Welding (SMAW) or Stick Welding',
      'Gas Metal Arc Welding (GMAW) or MIG Welding',
      'Gas Tungsten Arc Welding (GTAW) or TIG Welding',
      'Flux-Cored Arc Welding (FCAW)',
      'Submerged Arc Welding (SAW)',
      'Spot Welding',
      'Projection Welding',
      'Stud Welding',
      'Plasma Arc Welding (PAW)',
      'Electroslag Welding (ESW)',
      'Oxy-Acetylene Welding (OAW)',
      'Laser Beam Welding (LBW)',
      'Electron Beam Welding (EBW)',
      'Other (Free input)',
    ],
    value: 'weldingType',
  },
  {
    label: 'Duty Cycle',
    type: 'number',
    placeholder: 'Enter value',
    measurement: 'percentage',
    required: false,
    value: 'dutyCycle',
  },
  {
    label: 'Facilities',
    type: 'select',
    placeholder: 'Select a facilities',
    required: false,
    values: [
      'Restrooms',
      'Handwashing Facilities',
      'Changing Rooms',
      'Showers',
      'Kitchenette or Break Area',
      'Rest Area',
      'Heating and Cooling',
      'Ventilation',
      'Lighting',
      'Fire Safety Equipment',
      'First Aid Station',
      'Waste Disposal Bins',
      'Notice Board',
      'Lockable Doors and Security',
      'Accessibility Features',
      'Regular Cleaning',
      'Compliance with Regulations',
      'Other (Free input)',
    ],
    value: 'facilities',
  },
  {
    label: 'Size',
    type: 'number',
    placeholder: 'Enter value',
    measurement: 'm2',
    required: false,
    value: 'size',
  },
  {
    label: 'Heating',
    type: 'select',
    placeholder: 'Select a heating',
    required: false,
    values: [
      'Electric Heaters',
      'Gas Heaters',
      'Oil Heaters',
      'Central Heating Systems',
      'Air Conditioning/Heat Pump Units (for heating)',
      'Other (Free input)',
    ],
    value: 'heating',
  },
  {
    label: 'Cooling',
    type: 'select',
    placeholder: 'Select a cooling',
    required: false,
    values: [
      'Air Conditioning',
      'Ventilation',
      'Fans',
      'Shade and Awnings',
      'Cooling Trailers (with dedicated air conditioning)',
      'Other (Free input)',
    ],
    value: 'cooling',
  },
  {
    label: 'Safety Features',
    type: 'select',
    placeholder: 'Select a safety features',
    required: false,
    values: [
      'Dead-Man or Operator Presence Control',
      'Blade Guards and Shields',
      'Safety Interlocks',
      'Throttle and Speed Control',
      'Emergency Shut-Off Switch',
      'Safety Labels and Instructions',
      'Personal Protective Equipment (PPE) Recommendations',
      'Anti-Vibration Features',
      'Fuel and Oil Caps with Venting',
      'Kickback Protection',
      'Rollover Protection (ROPS) for larger equipment',
      'Protective Clothing and Guards',
      'Regular Maintenance',
      'Guardrails',
      'Toe Boards',
      'Other (Free input)',
    ],
    value: 'safetyFeatures',
  },
  {
    label: 'Cutting Width',
    type: 'number',
    placeholder: 'Enter value',
    measurement: '(m)',
    required: false,
    value: 'cuttingWidth',
  },
  {
    label: 'Cutting Height',
    type: 'number',
    placeholder: 'Enter value',
    measurement: '(m)',
    required: false,
    value: 'cuttingHeight',
  },
  {
    label: 'Flow Rate',
    type: 'number',
    placeholder: 'Enter value',
    measurement: 'Gallons Per Minute(GPM)',
    required: false,
    value: 'flowRate',
  },
  {
    label: 'Head',
    type: 'select',
    placeholder: 'Select a head',
    required: false,
    values: [
      'Elevation Head (H_e)',
      'Pressure Head (H_p)',
      'Velocity Head (H_v)',
      'Static Head (H_s)',
      'Friction Head (H_f)',
      'Total Head (H_total or TDH)',
      'Other (Free input)',
    ],
    value: 'head',
  },
  {
    label: 'Inlet Pressure',
    type: 'number',
    placeholder: 'Enter value',
    measurement: '(Pounds Per Square Inch)',
    required: false,
    value: 'inletPressure',
  },
  {
    label: 'Outlet Pressure',
    type: 'number',
    placeholder: 'Enter value',
    measurement: '(Pounds Per Square Inch)',
    required: false,
    value: 'outletPressure',
  },
  {
    label: 'Cooling Capacity',
    type: 'number',
    placeholder: 'Enter value',
    measurement: 'British Thermal Units (BTU)',
    required: false,
    value: 'coolingCapacity',
  },
  {
    label: 'Heating Capacity',
    type: 'number',
    placeholder: 'Enter value',
    measurement: 'British Thermal Units (BTU)',
    required: false,
    value: 'heatingCapacity',
  },
  {
    label: 'Temperature Range',
    type: 'string',
    placeholder: 'Enter value',
    measurement: '(Degrees)',
    required: false,
    value: 'temperatureRange',
  },
  {
    label: 'Coverage Area',
    type: 'number',
    placeholder: 'Enter value',
    measurement: '(Meters Squared)',
    required: false,
    value: 'coverageArea',
  },
  {
    label: 'Cabling Type',
    type: 'select',
    placeholder: 'Select Cabling Type',
    required: false,
    values: [
      'Polyethylene (PE)',
      'Polyvinyl Chloride (PVC)',
      'Cross-Linked Polyethylene (XLPE or PEX)',
      'Ethylene Propylene Rubber (EPR)',
      'Silicone Rubber',
      'Polytetrafluoroethylene (PTFE)',
      'Mineral-Insulated (MI) Cable',
      'Paper (historical use)',
      'Air (for air-insulated transmission lines)',
      'Other (Free input)',
    ],
    value: 'cablingType',
  },
  {
    label: 'Conductor Size',
    type: 'number',
    placeholder: 'Enter value',
    measurement: '(Millimeter Squared)',
    required: false,
    value: 'conductorSize',
  },
  {
    label: 'Insulation Type',
    type: 'select',
    placeholder: 'Select Insulation Type',
    required: false,
    values: [
      'Polyethylene (PE)',
      'Polyvinyl Chloride (PVC)',
      'Cross-Linked Polyethylene (XLPE or PEX)',
      'Ethylene Propylene Rubber (EPR)',
      'Silicone Rubber',
      'Polytetrafluoroethylene (PTFE)',
      'Mineral-Insulated (MI) Cable',
      'Paper (historical use)',
      'Air (for air-insulated transmission lines)',
      'Polypropylene (PP)',
      'Foam Insulation (e.g., foamed polyethylene or foamed polypropylene)',
      'Other (Free input)',
    ],
    value: 'insulationType',
  },
  {
    label: 'Voltage Rating',
    type: 'number',
    placeholder: 'Enter value',
    measurement: '(Voltage)',
    required: false,
    value: 'voltageRating',
  },
  {
    label: 'Light Type',
    type: 'select',
    placeholder: 'Select Light Type',
    required: false,
    values: ['Indoor', 'Outdoor', 'Both', 'Other (Free input)'],
    value: 'lightType',
  },
  {
    label: 'Wattage',
    type: 'number',
    placeholder: 'Enter value',
    measurement: '(Watts)',
    required: false,
    value: 'wattage',
  },
  {
    label: 'Color Temperature',
    type: 'select',
    placeholder: 'Select Color Temperature',
    required: false,
    values: [
      'Warm White (2700K - 3000K)',
      'Soft White (3000K - 3500K)',
      'Neutral White (3500K - 4000K)',
      'Cool White (4000K - 5000K)',
      'Daylight White (5000K - 6500K)',
      'Daylight (6500K and above)',
      'Other (Free input)',
    ],
    value: 'colorTemperature',
  },
  {
    label: 'Voltage',
    type: 'number',
    placeholder: 'Enter value',
    measurement: '(Voltage)',
    required: false,
    value: 'lightingVoltage',
  },
  {
    label: 'Beam Angle',
    type: 'number',
    placeholder: 'Enter value',
    measurement: '(Degress)',
    required: false,
    value: 'beamAngle',
  },
  {
    label: 'Cleaning Type',
    type: 'select',
    placeholder: 'Select Cleaning Type',
    required: false,
    values: ['Indoor', 'Outdoor', 'Both', 'Other (Free input)'],
    value: 'cleaningType',
  },
  {
    label: 'Cleaning Width',
    type: 'number',
    placeholder: 'Enter value',
    measurement: '(m)',
    required: false,
    value: 'cleaningWidth',
  },
  {
    label: 'Tank Capacity',
    type: 'number',
    placeholder: 'Enter value',
    measurement: '(Litres)',
    required: false,
    value: 'tankCapacity',
  },
  {
    label: 'Scaffold Type',
    type: 'select',
    placeholder: 'Select Scaffold Type',
    required: false,
    values: ['Frame', 'Tube and Clamp', 'System', 'Other (Free input)'],
    value: 'scaffoldType',
  },
  {
    label: 'Load Capacity',
    type: 'number',
    placeholder: 'Enter value',
    measurement: '(Kilograms)',
    required: false,
    value: 'loadCapacity',
  },
  {
    label: 'Platform Height',
    type: 'number',
    placeholder: 'Enter value',
    measurement: '(Meters)',
    required: false,
    value: 'platformHeight',
  },
  {
    label: 'Centrifugal Force',
    type: 'number',
    placeholder: 'Enter value',
    measurement: '(n)',
    required: false,
    value: 'centrifugalForce',
  },
  {
    label: 'Mixer Capacity',
    type: 'number',
    placeholder: 'Enter value',
    measurement: '(I)',
    required: false,
    value: 'mixerCapacity',
  },
  {
    label: 'Max Output',
    type: 'number',
    placeholder: 'Enter value',
    measurement: '(m3/h)',
    required: false,
    value: 'maxOutput',
  },
  {
    label: 'Power Range',
    type: 'number',
    placeholder: 'Enter value',
    measurement: '(W)',
    required: false,
    value: 'powerRange',
  },
  {
    label: 'Static Pressure',
    type: 'number',
    placeholder: 'Enter value',
    measurement: '(kg/m2)',
    required: false,
    value: 'staticPressure',
  },
];

export const masterCategoryFields = [
  {
    key: 'masterCategory',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: listingMasterCategories.map(({label, value}) => ({ option: value, label })),
    filterConfig: {
      indexForSearch: true,
      filterType: 'SelectMultipleFilter',
      label: 'Category',
      group: 'primary',
    },
    showConfig: {
      label: 'Category',
    },
    saveConfig: {
      label: 'Category',
    },
  },
];
export const listingServicesSubCategories = [
  'Other',
];
export const listingAttachmentsSubCategories = [
  'Forklifts',
  "Mobile Elevated Work Platforms - MEWP's",
  'Cranes',
  'Bulldozers',
  'Dumpers',
  'Excavators',
  'Compactors',
  'Generators',
  'Concrete Equipment',
  'Loaders',
  'Power Tools',
  'Skips',
  'Material Handling Equipment',
  'Air Compressors',
  'Welding Equipment',
  'Welfare Units',
  'Garden & Landscape',
  'Pumps',
  'Climate Control',
  'Cabling',
  'Roofing',
  'Plumbing',
  'Lighting',
  'Cleaning Equipment',
  'Scaffolding',
  'Services',
  'Attachments',
  'Surveying',
  'Site Control',
  'Heavy Goods Vehicles (HGVs)',
  'Storage and Containment',
  'Other',
];
export const listingSurveyingSubCategories = [
  'Total Stations',
  'Global Navigation Satellite System (GNSS) Receivers',
  'Theodolites',
  'Levels',
  'Laser Scanners',
  'Digital and GPS Levels',
  'Field Books and Surveying Accessories',
  'Drone/UAV Surveying Equipment',
  'Cable Detection',
  'Metal Detection',
  'Other',
];
export const listingSiteControlSubCategories = [
  'Traffic Control Equipment',
  'Site Security Systems',
  'Communication Systems',
  'Environmental Monitoring Equipment',
  'Site Lighting Equipment',
  'Site Management Software/Technology',
  'Signage',
  'Other',
];
export const listingHGVSubCategories = [
  'Tractor-Trailers',
  'Flatbed Trucks',
  'Box Trucks',
  'Refrigerated Trucks',
  'Dump Trucks',
  'Tanker Trucks',
  'Hot Boxes',
  'Horse Box',
  'Single Axel Trailers',
  'Double Axel Trailers',
  'Plant Trailer',
  'Other',
];
export const listingStorageContainmentSubCategories = [
  'Fuel Tanks',
  'Fuel Storage',
  'Other',
];
export const listingConcreteEquipmentSubCategories = [
  'Screeds',
  'Portable Cement Mixer',
  'Floats',
  'Vapor Barriers',
  'Groove Cutters and Edgers',
  'Plate Compactors',
  'Power Hammers and Drills',
  'Concrete Batching Plant',
  'Concrete Vibrators',
  'Concrete Pavers',
  'Concrete Pumps',
  'Concrete Tanks',
  'Concrete Crushers',
  'Concrete Conveyors',
  'Concrete Boom Placers',
  'Other',
];
export const listingGeneratorsSubCategories = [
  'Portable Generators',
  'Inverter Generators',
  'Portable Power Stations',
  'Standby Generators',
  'Other',
];
export const listingCompactorsSubCategories = [
  'Vibrating plates',
  'Pedestrian rollers',
  'Trench compactors',
  'Tandem rollers',
  'Self-propelled rollers',
  'Pneumatic-tyred rollers',
  'Combination rollers',
  'Towed rollers',
  'Plate Compactors',
  'Jumping Jack Compactors (Rammer)',
  'Vibratory Soil Compactors',
  'Smooth Drum Rollers',
  'Pneumatic Rollers',
  'Sheepsfoot Rollers',
  'Tamping Rollers',
  'Landfill Compactors',
  'Trash Compactors',
  'Balers',
  'Roller Compactors (Pharmaceutical)',
  'Hand-Held Trash Compactors',
  'Other',
];
export const listingExcavatorsSubCategories = [
  'Tracked Excavator',
  'Wheeled Excavator',
  'Mini Excavator',
  'Long-Reach Excavators',
  'Dragline Excavators',
  'Backhoe Excavators',
  'Other',
];
export const listingDumpersSubCategories = [
  'Standard Dump Truck',
  'Articulated Dump Truck (ADT)',
  'Transfer Dump Truck',
  'Side Dump Truck',
  'Off-Highway Dump Truck',
  'Super Dump Truck',
  'Semi-Trailer End Dump Truck',
  'Semi-Trailer Bottom Dump Truck',
  'Rigid Dump Truck',
  'Mini Dump Truck',
  'Swivel Skip',
  'Wheeled Swivel',
  'Tracked Swivel',
  'Other',
];
export const listingBulldozersSubCategories = [
  'Crawler Bulldozer',
  'Mini Bulldozer',
  'Wheeled Bulldozer',
  'Other',
];
export const listingCranesSubCategories = [
  'Tower Crane',
  'Mobile Crane',
  'Overhead Crane',
  'Mobile Cranes',
  'Vehicle Mounted Cranes',
  'Rough Terrain Cranes',
  'All Terrain Cranes',
  'Crawler Cranes',
  'Railroad Cranes',
  'Aerial Cranes',
  'Telescopic Crane',
  'Loader Crane',
  'Floating Crane',
  'Mini Cranes',
  'Other',
];
export const listingForkliftsSubCategories = [
  'Electric Forklifts',
  'LPG Gas Forklift',
  'Counterbalance',
  'Order Pickers',
  'Reach Trucks',
  'High-Capacity Forklifts',
  'Pallet Jacks',
  'Pallet Stackers',
  'Pallet trucks',
  'Forklift',
  'Diesel Forklifts',
  'Articulated',
  'Power Pallet Truck',
  'Other',
];
export const listingMEWPSubCategories = [
  'Push Around Vertical (PAV)',
  'Mast Lifts',
  'Scissor Lifts',
  'Articulating Boom Lift',
  'Straight Boom Lifts',
  'Track Mounts',
  'Truck Mounts',
  'Tracked Spider Lift',
  'Other',
];
export const listingWelfareUnitsSubCategories = [
  'Mobile Welfare Units',
  'Static Welfare Units',
  'Towable Welfare Units',
  'Eco-Friendly Welfare Units',
  'Site Offices',
  'Canteen Units',
  'Toilet and Shower Units',
  'Drying Rooms',
  'Changing Rooms',
  'First Aid Rooms',
  'Secure Welfare Units',
  'Hybrid Welfare Units (Combining offices, canteens, and toilets)',
  'Welfare Unit Accessories (e.g., furniture, heating, lighting)',
  'Welfare Unit Rental Options',
  'Welfare Unit Purchase Options',
  'Eco-Toilet Welfare Units',
  'Solar-Powered Welfare Units',
  'Off-Grid Welfare Units',
  'Welfare Unit Compliance with Regulations',
  'Customized Welfare Units (Tailored to specific needs)',
  'Standard Welfare Units',
  'Other',
];
export const listingWeldingEquipmentSubCategories = [
  'Arc Welders (e.g., SMAW, GMAW, GTAW)',
  'MIG Welding Machines',
  'TIG Welding Machines',
  'Spot Welders',
  'Plasma Cutters',
  'Welding Generators',
  'Multi-Process Welders',
  'Submerged Arc Welding (SAW) Machines',
  'Welding Positioners',
  'Welding Helmets',
  'Welding Gloves',
  'Welding Jackets and Aprons',
  'Welding Goggles',
  'Welding Tables and Workbenches',
  'Welding Fume Extractors',
  'Welding Wire and Electrodes',
  'Welding Accessories',
  'Welding Safety Equipment',
  'Welding Carts and Stands',
  'Welding Consumable Storage',
  'Shielded Metal Arc Welding Machine (Stick)',
  'Flux-Cored Arc Welding Machine',
  'Energy Beam Welding Machine',
  'Plasma Arc Welding Machine',
  'Transformers',
  'Other',
];
export const listingAirCompressorsSubCategories = [
  'Reciprocating Air Compressors',
  'Rotary Screw Air Compressors',
  'Centrifugal Air Compressors',
  'Scroll Air Compressors',
  'Oil-Free Air Compressors',
  'Portable Air Compressors',
  'Stationary Air Compressors',
  'Single-Stage Air Compressors',
  'Two-Stage Air Compressors',
  'Variable Speed Drive (VSD) Air Compressors',
  'High-Pressure Air Compressors',
  'Low-Noise Air Compressors',
  'Oil-Injected Air Compressors',
  'Oil-Free Scroll Air Compressors',
  'Dental Air Compressors',
  'Medical Air Compressors',
  'Oil-Free Piston Air Compressors',
  'Truck-Mounted Air Compressors',
  'Natural Gas Compressors',
  'Air Compressor Accessories',
  'Axial Compressors',
  'Other',
];
export const listingMaterialHandlingEquipmentSubCategories = [
  'Hand Trucks (Dollies)',
  'Conveyor Systems',
  'Hoists',
  'Industrial Shelving and Racking',
  'Order Pickers',
  'Reach Trucks',
  'Stackers',
  'Drum Handling Equipment',
  'Carton Flow Racks',
  'Platform Trucks',
  'Automated Guided Vehicles (AGVs)',
  'Tow Tractors and Tuggers',
  'Warehouse Robots',
  'Vacuum Lifters',
  'Scissor Lift Tables',
  'Packaging Machinery',
  'Material Handling Carts and Trolleys',
  'Dock Equipment (Loading and Unloading)',
  'Storage and Handling Equipment',
  'Industrial Trucks',
  'Teleporters',
  'Telehandler',
  'Other',
];
export const listingSkipsSubCategories = [
  'Open Skips',
  'Closed Skips',
  'Roll-On/Roll-Off (RoRo) Skips',
  'Mini Skips',
  'Midi Skips',
  "Builder's Skips",
  'Industrial Skips',
  'Lockable Skips',
  'Drop Door Skips',
  'Compactor Skips',
  'Rear End Loader (REL) Skips',
  'Front End Loader (FEL) Skips',
  'Skip Bags',
  'Hazardous Waste Skips',
  'Enclosed Skips',
  'Underground Skips (for waste chutes)',
  'Mobile Skip Bins (on trailers)',
  'Hook Lift Skips',
  'Marrel Skips',
  'Skip Bins with Wheel and Handle',
  'Other',
];
export const listingPowerToolsSubCategories = [
  'Electric Drills',
  'Circular Saws',
  'Jigsaws',
  'Reciprocating Saws',
  'Miter Saws',
  'Table Saws',
  'Angle Grinders',
  'Belt Sanders',
  'Random Orbital Sanders',
  'Impact Drivers',
  'Cordless Screwdrivers',
  'Rotary Hammers',
  'Demolition Hammers',
  'Heat Guns',
  'Power Planers',
  'Router Machines',
  'Bench Grinders',
  'Scroll Saws',
  'Polishers',
  'Nail Guns',
  'Cut Off Saw',
  'Consaw',
  'Chainsaw',
  'Other',
];
export const listingLoadersSubCategories = [
  'Skid Steer Loaders',
  'Backhoe Loaders',
  'Wheel Loaders',
  'Compact Track Loaders',
  'Mini Loaders',
  'Front-End Loaders',
  'Compact Wheel Loaders',
  'Telescopic Wheel Loaders',
  'Compact Utility Loaders',
  'Track Loaders',
  'Pivot Steer',
  'Other',
];
export const listingCleaningEquipmentSubCategories = [
  'Vacuum Cleaners',
  'Carpet Cleaners',
  'Floor Scrubbers',
  'Floor Polishers',
  'Pressure Washers',
  'Steam Cleaners',
  'Window Cleaning Equipment',
  'Industrial Sweepers',
  'Industrial Scrubbers',
  'Commercial Mops and Mop Buckets',
  'Brooms and Dustpans',
  'Cleaning Carts and Trolleys',
  'Trash Compactors',
  'Trash Bins and Waste Receptacles',
  'Janitorial Carts',
  'Carpet Extractors',
  'Ultrasonic Cleaners',
  'Air Purifiers and Filtration Systems',
  'Tile and Grout Cleaners',
  'Industrial Vacuum Systems',
  'Burnishers',
  'Specialty Cleaning Machines',
  'Road Sweepers',
  'Other',
];
export const listingLightingSubCategories = [
  'Work Lights',
  'Task Lighting',
  'Floodlights',
  'Spotlights',
  'Strobe Lights',
  'Construction Site Lighting',
  'Stage Lighting',
  'Lighting Trusses (for stage lighting)',
  'Event Lighting Fixtures',
  'Portable Lighting Towers',
  'Lighting Control Boards',
  'Lighting Gels (for color effects)',
  'Followspotlights (for events)',
  'Temporary Construction Lighting',
  'Light Towers',
  'Event Lighting',
  'Other',
];
export const listingPlumbingSubCategories = [
  'Pipes and Pipe Fittings',
  'Plumbing Fixtures',
  'Valves',
  'Plumbing Tools',
  'Pipe Support and Hangers',
  'Drain and Sewer Equipment',
  'Plumbing Adhesives and Sealants',
  'Water Treatment and Filtration',
  'Plumbing Pumps',
  'Water Supply Line Components',
  'Plumbing Hardware and Accessories',
  'Plumbing Ventilation',
  'Plumbing Materials',
  'Plumbing Fixtures and Appliance Parts',
  'Plumbing Safety Gear',
  'Plumbing Accessories',
  'Other',
];
export const listingRoofingSubCategories = [
  'Roofing Shingles',
  'Roofing Underlayment',
  'Roofing Nails and Fasteners',
  'Roofing Ventilation Products',
  'Roof Flashing',
  'Roofing Adhesives and Sealants',
  'Roofing Insulation',
  'Roofing Hammers',
  'Roofing Nail Guns',
  'Roofing Shovels',
  'Roofing Safety Harnesses',
  'Roofing Ladders',
  'Roofing Gutters and Downspouts',
  'Skylights and Roof Windows',
  'Roofing Tar and Roof Coatings',
  'Roofing Edge Trim',
  'Roofing Membranes (EPDM, TPO, PVC)',
  'Roofing Vents and Ridge Caps',
  'Roofing Drip Edges',
  'Roof Snow Guards',
  'Roofing Solar Panels (Solar Roofs)',
  'Roofing Repair Kits',
  'Roofing Inspections and Maintenance Tools',
  'Roofing Safety Equipment (Fall Protection)',
  'Other',
];
export const listingCablingSubCategories = [
  'Electrical Cables',
  'Data Cables',
  'Network Equipment',
  'Cable Connectors and Adapters',
  'Cable Testing and Tools',
  'Cable Management Accessories',
  'Electrical Boxes and Conduit',
  'Wiring Devices',
  'Audio-Visual Cables',
  'Security and Surveillance Cables',
  'Fiber Optic Equipment',
  'Telecommunications Cabling',
  'Industrial Cabling',
  'Other',
];
export const listingClimateControlSubCategories = [
  'Air Conditioners',
  'Heaters (Electric, Gas, Oil)',
  'Heat Pumps',
  'HVAC Systems (Heating, Ventilation, and Air Conditioning)',
  'Dehumidifiers',
  'Humidifiers',
  'Air Purifiers',
  'Ventilation Fans',
  'Ductless Mini-Split Systems',
  'Evaporative Coolers (Swamp Coolers)',
  'Thermostats and Temperature Controls',
  'Portable Air Conditioners',
  'Window Air Conditioners',
  'Ceiling Fans',
  'Radiators',
  'Baseboard Heaters',
  'Infrared Heaters',
  'Commercial and Industrial HVAC Systems',
  'Air Curtains',
  'Energy-Efficient Climate Control Solutions',
  'Other',
];
export const listingPumpsSubCategories = [
  'Centrifugal Pumps',
  'Submersible Pumps',
  'Diaphragm Pumps',
  'Reciprocating Pumps',
  'Vacuum Pumps',
  'Gear Pumps',
  'Piston Pumps',
  'Peristaltic Pumps (Hose Pumps)',
  'Screw Pumps',
  'Progressive Cavity Pumps',
  'Magnetic Drive Pumps',
  'Hydraulic Pumps',
  'Jet Pumps',
  'Booster Pumps',
  'Sewage Pumps',
  'Slurry Pumps',
  'Chemical Pumps',
  'Fire Pumps',
  'Well Pumps',
  'Drum Pumps',
  'Cam pumps',
  'Other',
];
export const listingGardenLandscapeSubCategories = [
  'Lawn Mowers',
  'String Trimmers (Weed Whackers)',
  'Hedge Trimmers',
  'Leaf Blowers',
  'Chainsaws',
  'Garden Tillers (Rototillers)',
  'Lawn Aerators',
  'Garden Edgers / Strimmers',
  'Garden Shredders (Chippers)',
  'Pruning Shears',
  'Garden Sprayers',
  'Garden Carts and Wheelbarrows',
  'Garden Rakes',
  'Garden Hoses and Hose Reels',
  'Garden Trowels and Hand Tools',
  'Lawn Rollers',
  'Soil Test Kits',
  'Garden Irrigation Systems',
  'Garden Sprinklers',
  'Garden and Landscape Lighting',
  'Shredder & Wood Chipper',
  'Rotovator',
  'Auger',
  'Backpack Sprayer',
  'Core Aerator',
  'Drum Aerators',
  'Stum Grinder',
  'Sod Cutters',
  'Ride On Mower',
  'Lawn Scarifier',
  'Stone',
  'Decorative Stone',
  'Other',
];
export const listingScaffoldingSubCategories = [
  'Scaffolding Frames',
  'Scaffolding Planks (Walkboards)',
  'Scaffolding Braces (Cross Braces)',
  'Scaffolding Pins and Clips',
  'Scaffold Couplers and Clamps',
  'Scaffolding Wheels and Casters',
  'Scaffolding Base Plates',
  'Scaffolding Screw Jacks',
  'Scaffolding Ladders and Access Gates',
  'Scaffolding Safety Nets',
  'Scaffolding Safety Harnesses',
  'Scaffolding Decks and Platforms',
  'Scaffolding Staircases and Stair Towers',
  'Scaffolding Guardrails',
  'Scaffolding Toe Boards',
  'Scaffolding Outriggers',
  'Scaffolding Hoists and Pulleys',
  'Scaffolding Leveling Jacks',
  'Scaffolding Ties and Anchors',
  'Scaffolding Tool Trays and Holders',
  'Temporary Roof',
  'Suspended Scaffolding',
  'Aluminium Towers',
  'Other',
];

export const categoriesFields = [
  {
    key: 'masterCategory',
    scope: 'public',
    includeForListingTypes: ['product-selling', 'daily-booking'],
    schemaType: 'masterCategory',
    enumOptions: listingMasterCategories.map(({ label, value }) => ({ option: value, label })),
    filterConfig: {
      indexForSearch: true,
      label: 'Category',
      group: 'primary',
    },
    subCategory: {
      options: {
        listingServicesSubCategories,
        listingAttachmentsSubCategories,
        listingSurveyingSubCategories,
        listingSiteControlSubCategories,
        listingHGVSubCategories,
        listingStorageContainmentSubCategories,
        listingConcreteEquipmentSubCategories,
        listingGeneratorsSubCategories,
        listingCompactorsSubCategories,
        listingExcavatorsSubCategories,
        listingDumpersSubCategories,
        listingBulldozersSubCategories,
        listingCranesSubCategories,
        listingForkliftsSubCategories,
        listingMEWPSubCategories,
        listingWelfareUnitsSubCategories,
        listingWeldingEquipmentSubCategories,
        listingAirCompressorsSubCategories,
        listingMaterialHandlingEquipmentSubCategories,
        listingSkipsSubCategories,
        listingPowerToolsSubCategories,
        listingLoadersSubCategories,
        listingCleaningEquipmentSubCategories,
        listingLightingSubCategories,
        listingPlumbingSubCategories,
        listingRoofingSubCategories,
        listingCablingSubCategories,
        listingClimateControlSubCategories,
        listingPumpsSubCategories,
        listingGardenLandscapeSubCategories,
        listingScaffoldingSubCategories,
      }
    },
  },
];

export const listingTypesFields = [
  {
    key: 'listingType',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { option: 'product-selling', label: 'For Sale' },
      { option: 'daily-booking', label: 'For Hire' },
    ],
    filterConfig: {
      indexForSearch: true,
      label: 'Listing Type',
      group: 'primary',
    },
    showConfig: {
      label: 'Listing Type',
    },
    saveConfig: {
      label: 'Listing Type',
    },
  },
];
