import React from 'react';
import classNames from 'classnames';
import { types as sdkTypes } from '../../util/sdkLoader';
import { formatMoney } from '../../util/currency';

import css from './PriceBox.module.css';

const { Money } = sdkTypes;

const PriceBox = ({ price, intl, rootClassName, className }) => {
  const classes = classNames(rootClassName || css.root, className);

  const money = price instanceof Money
    ? formatMoney(intl, price)
    : formatMoney(intl, new Money(price.amount, price.currency))

  return (
    <span className={classes}>{money}</span>
  )
}

export default PriceBox;
