import React from 'react';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { LINE_ITEM_GRAND_VAT_TOTAL, propTypes } from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';

const { Money } = sdkTypes;

import css from './OrderBreakdown.module.css';

const LineItemTotalVAT = props => {
  const { lineItems, intl } = props;

  const totalVATLineItem = lineItems.find(
    item => item.code === LINE_ITEM_GRAND_VAT_TOTAL && !item.reversal
  );

  let currency = '';

  const vatFromLineItems =
    lineItems &&
    lineItems?.reduce((acc, curr) => {
      if (curr?.code?.includes('vat')) {
        acc += curr?.lineTotal?.amount;
        currency = curr?.lineTotal?.currency;
      }
      return acc;
    }, 0);

  const totalVAT = totalVATLineItem?.unitPrice
    ? totalVATLineItem?.unitPrice
    : vatFromLineItems
    ? new Money(vatFromLineItems, currency)
    : new Money(0, 'EUR');

  return totalVATLineItem || totalVAT ? (
    <div className={css.lineItem}>
      <span className={css.itemLabel}>
        <FormattedMessage id="OrderBreakdown.totalVat" />
      </span>
      <span className={css.itemValue}>{formatMoney(intl, totalVAT)}</span>
    </div>
  ) : null;
};

LineItemTotalVAT.propTypes = {
  lineItems: propTypes.lineItems.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemTotalVAT;
