import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { types as sdkTypes, createImageVariantConfig } from '../../util/sdkLoader';
import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import { getSubscriptionPlan } from '../../util/api';
import { parse } from '../../util/urlHelpers';
import { convertUnitToSubUnit, unitDivisor } from '../../util/currency';
import {
  parseDateFromISO8601,
  getExclusiveEndDate,
  addTime,
  subtractTime,
  daysBetween,
  getStartOf,
} from '../../util/dates';

const { UUID } = sdkTypes;

// ================ Action types ================ //

export const SET_INITIAL_STATE = 'app/ProfilePage/SET_INITIAL_STATE';

export const SHOW_USER_REQUEST = 'app/ProfilePage/SHOW_USER_REQUEST';
export const SHOW_USER_SUCCESS = 'app/ProfilePage/SHOW_USER_SUCCESS';
export const SHOW_USER_ERROR = 'app/ProfilePage/SHOW_USER_ERROR';

export const QUERY_LISTINGS_REQUEST = 'app/ProfilePage/QUERY_LISTINGS_REQUEST';
export const QUERY_LISTINGS_SUCCESS = 'app/ProfilePage/QUERY_LISTINGS_SUCCESS';
export const QUERY_LISTINGS_ERROR = 'app/ProfilePage/QUERY_LISTINGS_ERROR';

export const QUERY_REVIEWS_REQUEST = 'app/ProfilePage/QUERY_REVIEWS_REQUEST';
export const QUERY_REVIEWS_SUCCESS = 'app/ProfilePage/QUERY_REVIEWS_SUCCESS';
export const QUERY_REVIEWS_ERROR = 'app/ProfilePage/QUERY_REVIEWS_ERROR';

export const QUERY_SUBSCRIPTION_PLAN_REQUEST = 'app/ProfilePage/QUERY_SUBSCRIPTION_PLAN_REQUEST';
export const QUERY_SUBSCRIPTION_PLAN_SUCCESS = 'app/ProfilePage/QUERY_SUBSCRIPTION_PLAN_SUCCESS';
export const QUERY_SUBSCRIPTION_PLAN_ERROR = 'app/ProfilePage/QUERY_SUBSCRIPTION_PLAN_ERROR';

// ================ Reducer ================ //

const initialState = {
  userId: null,
  userListingRefs: [],
  userShowError: null,
  queryListingsError: null,
  reviews: [],
  queryReviewsError: null,
  subscriptionPlan: null,
  subscriptionPlanError: null,
  pagination: null,
  queryParams: null,
};

export default function profilePageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_STATE:
      return { ...initialState };
    case SHOW_USER_REQUEST:
      return { ...state, userShowError: null, userId: payload.userId };
    case SHOW_USER_SUCCESS:
      return state;
    case SHOW_USER_ERROR:
      return { ...state, userShowError: payload };

    case QUERY_LISTINGS_REQUEST:
      return {
        ...state,

        // Empty listings only when user id changes
        userListingRefs: payload.userId === state.userId ? state.userListingRefs : [],
        queryParams: payload.queryParams,
        queryListingsError: null,
      };
    case QUERY_LISTINGS_SUCCESS:
      return {
        ...state,
        userListingRefs: payload.listingRefs,
        pagination: payload.meta,
      };
    case QUERY_LISTINGS_ERROR:
      return { ...state, userListingRefs: [], queryListingsError: payload };
    case QUERY_REVIEWS_REQUEST:
      return { ...state, queryReviewsError: null };
    case QUERY_REVIEWS_SUCCESS:
      return { ...state, reviews: payload };
    case QUERY_REVIEWS_ERROR:
      return { ...state, reviews: [], queryReviewsError: payload };

    case QUERY_SUBSCRIPTION_PLAN_REQUEST:
      return { ...state, subscriptionPlanError: null };
    case QUERY_SUBSCRIPTION_PLAN_SUCCESS:
      return { ...state, subscriptionPlan: payload };
    case QUERY_SUBSCRIPTION_PLAN_ERROR:
      return { ...state, subscriptionPlanError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const setInitialState = () => ({
  type: SET_INITIAL_STATE,
});

export const showUserRequest = userId => ({
  type: SHOW_USER_REQUEST,
  payload: { userId },
});

export const showUserSuccess = () => ({
  type: SHOW_USER_SUCCESS,
});

export const showUserError = e => ({
  type: SHOW_USER_ERROR,
  error: true,
  payload: e,
});

export const queryListingsRequest = (userId, queryParams) => ({
  type: QUERY_LISTINGS_REQUEST,
  payload: { userId, queryParams },
});

export const queryListingsSuccess = (listingRefs, meta) => ({
  type: QUERY_LISTINGS_SUCCESS,
  payload: { listingRefs, meta },
});

export const queryListingsError = e => ({
  type: QUERY_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const queryReviewsRequest = () => ({
  type: QUERY_REVIEWS_REQUEST,
});

export const queryReviewsSuccess = reviews => ({
  type: QUERY_REVIEWS_SUCCESS,
  payload: reviews,
});

export const queryReviewsError = e => ({
  type: QUERY_REVIEWS_ERROR,
  error: true,
  payload: e,
});

const querySubscriptionPlanRequest = () => ({
  type: QUERY_SUBSCRIPTION_PLAN_REQUEST,
});
const querySubscriptionPlanSuccess = reviews => ({
  type: QUERY_SUBSCRIPTION_PLAN_SUCCESS,
  payload: reviews,
});
const querySubscriptionPlanError = e => ({
  type: QUERY_SUBSCRIPTION_PLAN_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

const priceSearchParams = (priceParam, config) => {
  const inSubunits = value => convertUnitToSubUnit(value, unitDivisor(config.currency));
  const values = priceParam ? priceParam.split(',') : [];
  return priceParam && values.length === 2
    ? {
        price: [inSubunits(values[0]), inSubunits(values[1]) + 1].join(','),
      }
    : {};
};

const datesSearchParams = (datesParam, config) => {
  const searchTZ = 'Etc/UTC';
  const datesFilter = config.search.defaultFilters.find(f => f.key === 'dates');
  const values = datesParam ? datesParam.split(',') : [];
  const hasValues = datesFilter && datesParam && values.length === 2;
  const { dateRangeMode, availability } = datesFilter || {};
  const isNightlyMode = dateRangeMode === 'night';
  const isEntireRangeAvailable = availability === 'time-full';

  const getProlongedStart = date => subtractTime(date, 14, 'hours', searchTZ);
  const getProlongedEnd = date => addTime(date, 12, 'hours', searchTZ);

  const startDate = hasValues ? parseDateFromISO8601(values[0], searchTZ) : null;
  const endRaw = hasValues ? parseDateFromISO8601(values[1], searchTZ) : null;
  const endDate =
    hasValues && isNightlyMode
      ? endRaw
      : hasValues
      ? getExclusiveEndDate(endRaw, searchTZ)
      : null;

  const today = getStartOf(new Date(), 'day', searchTZ);
  const possibleStartDate = subtractTime(today, 14, 'hours', searchTZ);
  const hasValidDates =
    hasValues &&
    startDate.getTime() >= possibleStartDate.getTime() &&
    startDate.getTime() <= endDate.getTime();

  const dayCount = isEntireRangeAvailable ? daysBetween(startDate, endDate) : 1;
  const day = 1440;
  const hour = 60;

  const minDuration = isEntireRangeAvailable ? dayCount * day - hour : hour;
  return hasValidDates
    ? {
        start: getProlongedStart(startDate),
        end: getProlongedEnd(endDate),
        availability: 'time-partial',
        minDuration,
      }
    : {};
};

export const queryUserListings = (userId, config, queryParams) => (dispatch, getState, sdk) => {
  dispatch(queryListingsRequest(userId, queryParams));

  const listingTypes = config?.listing?.listingTypes ?? [];

  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const aspectRatio = aspectHeight / aspectWidth;

  const page = queryParams.page || 1;
  const perPage = 8;

  const priceMaybe = priceSearchParams(queryParams.price, config);
  const datesMaybe = datesSearchParams(queryParams.dates, config);

  const params = {
    ...queryParams,
    ...priceMaybe,
    ...datesMaybe,
    page,
    perPage,
    author_id: userId,
    include: ['author', 'images'],
    pub_listingType: listingTypes.map(l => l.listingType),
    'fields.image': [`variants.${variantPrefix}`, `variants.${variantPrefix}-2x`],
    ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
    'limit.images': 1,
  };

  return sdk.listings
    .query(params)
    .then(response => {
      // Pick only the id and type properties from the response listings
      const listingRefs = response.data.data.map(({ id, type }) => ({ id, type }));
      dispatch(addMarketplaceEntities(response));
      dispatch(queryListingsSuccess(listingRefs, response.data.meta));
      return response;
    })
    .catch(e => dispatch(queryListingsError(storableError(e))));
};

export const queryUserReviews = userId => (dispatch, getState, sdk) => {
  sdk.reviews
    .query({
      subject_id: userId,
      state: 'public',
      include: ['author', 'author.profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    })
    .then(response => {
      const reviews = denormalisedResponseEntities(response);
      dispatch(queryReviewsSuccess(reviews));
    })
    .catch(e => dispatch(queryReviewsError(e)));
};

export const showUser = userId => (dispatch, getState, sdk) => {
  dispatch(showUserRequest(userId));
  return sdk.users
    .show({
      id: userId,
      include: ['profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(showUserSuccess());
      return response;
    })
    .catch(e => dispatch(showUserError(storableError(e))));
};

export const getUserSubscriptionPlan = (userId) => async (dispatch) => {
  dispatch(querySubscriptionPlanRequest());

  try {
    const response = await getSubscriptionPlan({ userId: userId.uuid });

    const SubscriptionPlan =
      response?.data?.data?.attributes?.profile?.privateData?.Subscription_plan ?? "None";

    dispatch(querySubscriptionPlanSuccess(SubscriptionPlan));
  } catch( e) {
    dispatch(querySubscriptionPlanError(storableError(e)))
  }
};

export const loadData = (params, search, config) => (dispatch) => {
  const userId = new UUID(params.id);
  const queryParams = parse(search);

  // Clear state so that previously loaded data is not visible
  // in case this page load fails.
  dispatch(setInitialState());

  return Promise.all([
    dispatch(fetchCurrentUser()),
    dispatch(showUser(userId)),
    dispatch(queryUserListings(userId, config, queryParams)),
    dispatch(queryUserReviews(userId)),
    dispatch(getUserSubscriptionPlan(userId))
  ]);
};
