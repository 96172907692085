import React from 'react';
import { Field } from 'react-final-form';
import Select, { components } from 'react-select';
import classNames from 'classnames';

import { ValidationError } from '../../components';

import css from './FieldMultiSelect.module.css';

const DropdownIcon = () => {
  return (
    <svg width="10" height="17" viewBox="0 0 10 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M4.68579 16.3145C4.86 16.4879 5.14 16.4879 5.31421 16.3145L8.86967 12.7591C9.04344 12.5849 9.04344 12.304 8.86967 12.1307C8.6959 11.9564 8.41457 11.9564 8.24124 12.1307L5 15.3715L1.75876 12.1307C1.58498 11.9564 1.30366 11.9564 1.13033 12.1307C0.956557 12.304 0.956557 12.5849 1.13033 12.7591L4.68579 16.3145Z" fill="#4A4A4A" stroke="#4A4A4A"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M5.31421 1.12979C5.14 0.956461 4.86 0.956461 4.68579 1.12979L1.13033 4.68525C0.956556 4.85946 0.956556 5.14034 1.13033 5.31367C1.3041 5.48789 1.58543 5.48789 1.75876 5.31367L5 2.07287L8.24124 5.31367C8.41502 5.48789 8.69634 5.48789 8.86967 5.31367C9.04344 5.14034 9.04344 4.85946 8.86967 4.68525L5.31421 1.12979Z" fill="#4A4A4A" stroke="#4A4A4A"/>
    </svg>
  )
}

const DropdownIndicator = props => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <DropdownIcon />
      </components.DropdownIndicator>
    )
  );
};

const colourStyles = {
  control: (styles) => ({
    ...styles,
    borderRadius: 6,
    border: "1px solid #d8dce6",
    padding: "5px",
  }),
  multiValueLabel: (styles) => ({
    ...styles,
    backgroundColor: "#fff",
    border: "1px solid #ccc",
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    borderRight: "none"
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    backgroundColor: "#fff",
    border: "1px solid #ccc",
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
  }),
}

const FieldMultiSelectComponent = ({
  input,
  label,
  meta,
  rootClassName,
  className,
  id,
  ...rest
}) => {

  const selectProps = {
    id,
    isMulti: true,
    isClearable: false,
    components: { DropdownIndicator, IndicatorSeparator: () => null },
    styles: colourStyles,
    ...input,
    ...rest
  };

  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={classes}>
      {label ? <label htmlFor={id}>{label}</label> : null}
      <Select {...selectProps} />
      <ValidationError fieldMeta={meta} />
    </div>
  );
};

const FieldMultiSelect = props => {
  return <Field component={FieldMultiSelectComponent} {...props} />;
};

export default FieldMultiSelect;
